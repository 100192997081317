.Main{
  display: flex;
  flex-direction: column;
  height: 180px;
  gap: 13px;

  .SelectWrapper {
    height: 65px;
    position: relative;

    .SelectInfo {
      top: 11px;
      left: 16px;
      z-index: 1;
      font-size: 12px;
      line-height: 16px;
      color: #949494 !important;
    }

    span {
      display: none;
    }

    svg {
      fill: #5c5d61;
    }
  }
}



.Title {
  margin: 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  background: linear-gradient(
      90.43deg,
      rgba(255, 255, 255, 0.6) 0%,
      #ffffff 18.07%,
      rgba(255, 255, 255, 0.8) 83.79%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  @media (max-width: 876px) {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }
}

%circle {
  position: absolute;
  width: 184px;
  height: 184px;
  border-radius: 9999px;
  z-index: -1;
  opacity: 0.2;
  filter: blur(20px);
}

.PurpleCircle {
  @extend %circle;
  background: var(--color-purple-gradient);
  box-shadow: 0px 0px 80px 60px var(--color-purple);

  &[data-id="1"] {
    width: 80px;
    height: 80px;
    left: 70%;
    top: 90%;
  }

  &[data-id="2"] {
    width: 120px;
    height: 120px;
    left: 10%;
    top: 30%;
  }

  &[data-id="3"] {
    width: 120px;
    height: 120px;
    left: 50%;
    top: 30%;
  }

  &[data-id="4"] {
    left: 80%;
    top: 60%;
  }
}

.BlueCircle {
  @extend %circle;
  background: var(--color-blue);
  box-shadow: 0px 0px 80px 60px var(--color-blue);

  &[data-id="1"] {
    width: 40px;
    height: 40px;
    left: 80%;
    top: -40px;
  }

  &[data-id="2"] {
    left: 30%;
    top: 45%;
  }

  &[data-id="3"] {
    width: 100px;
    height: 100px;
    left: 10%;
    top: 85%;
  }
}

.WhiteCircle {
  @extend %circle;
  background: var(--color-secondary-lightest);
  box-shadow: 0px 0px 80px 60px var(--color-secondary-lightest);
  right: 50%;
  top: -200px;
  transform: translateX(100%);
}

.Wrapper {
  position: relative;
  margin-top: 21px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  border-radius: 8px;
  border: 1px solid var(--color-m2);
  background: var(--color-m1);
  width: inherit;
}

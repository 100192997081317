.Wrapper {
  border-bottom: 1px solid var(--color-divider);

  .Label {
    line-height: 36px;
    position: relative;
    font-weight: 400;
  }

  .Children {
    position: relative;
    padding-bottom: 16px;
  }

  @media (max-width: 670px) {
    .Label {
      line-height: 20px;
    }

    .Children {
      padding-bottom: 12px;
    }
  }
}

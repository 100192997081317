.Empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .Title{
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    //line-height: 10px; /* 125% */
    text-transform: uppercase;
    color: var(--color-secondary);
    margin-top: 30px;
  }
}

.Content {
  max-width: 340px;
  position: relative;
  background: var(--color-modal-bg);
  border-radius: 16px;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .Title {
    margin: 0;
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: var(--color-secondary-lightest);
  }

  .Text {
    margin: 0;
    margin-bottom: 22px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: var(--color-gray);
    opacity: 0.8;
  }

  .Footer {
    display: flex;
    align-items: center;
    justify-content: center;

    .CancelBtn {
      margin-right: 16px;
      font-size: 14px;
      opacity: 0.8;
    }

    .ConfirmBtn {
      height: 40px;
      font-weight: normal;
    }
  }
}

.Card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(204.74deg, #27303a 15.77%, #2d2c32 99.27%);
  border-radius: 16px;
  padding: 24px;
  height: 355px;

  .Upper {
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;

    .Img {
      width: 40px;
      height: 40px;
    }

    svg {
      fill: var(--color-m7);
      cursor: pointer;
    }
  }


  &[data-variant="form-field"] {
    border: 1px solid var(--color-m2);

    .Title {
      color: var(--color-d9-input);
    }
  }

  .Title {
    font-size: 20px;
    line-height: 22px;
    color: var(--color-secondary-lightest);
    margin-bottom: 24px;
    margin-top: 0;
  }

  .Desc {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: var(--color-m8);
    opacity: 0.7;
  }

  @media (max-width: 876px) {
    padding: 12px 14px;
    min-width: 222px;

    .Title {
      font-size: 14px;
      margin-bottom: 20px;
      height: 48px;
      overflow: hidden;
    }
  }
}

.InfoCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 392px;
  position: relative;
  height: 120px;
  border-radius: 16px;
  padding: 16px;
  padding-bottom: 6px;
  border: 1px solid var(--m-2-outline, #2c2c35);
  background: var(--m-1, #1e1e24);
  margin-bottom: 8px;
  cursor: pointer;

  @media (max-width: 500px) {
    width: 90%;
  }

  .BackIcon {
    width: 133px;
    position: absolute;
    right: 24px;
    top: 4px;
  }

  .Title {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    text-transform: uppercase;
    background: linear-gradient(161deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .Footer {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .ArrowButton {
      position: absolute;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      background-color: var(--m-2-outline, #2c2c35);
      width: 40px;
      height: 40px;
      cursor: pointer;

      &:hover {
        background-color: rgba(131, 131, 131, 0.48);
        transition: background-color var(--animation-transition);
      }

      .Icon {
        fill: white;
        width: 17px;
        height: 17px;
      }
    }

    .Count {
      display: flex;
      color: var(--mk, #fff);
      align-items: center;

      .Icon {
        fill: var(--color-secondary-lightest);
        opacity: 0.3;
        margin-right: 8px;
      }
    }
  }
}

@media (min-width: 876px) {
  .Mobile {
    display: none;
  }
}

@media (max-width: 876px) {
  .InfoCard {
    padding: 16px;
    height: 104px;

    .Title {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    .BackIcon {
      display: none;
    }

    .Footer {
      .ArrowButton {
        width: 24px;
        height: 24px;

        .Icon {
          fill: white;
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}

.Update {
  background: transparent;
  border-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-secondary-lightest);
  opacity: 0.7;

  .Icon {
    margin-right: 12px;
    fill: var(--color-blue-primary);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

.Root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  border: 1px solid var(--color-m2);
  border-radius: 16px;
  background: var(--color-m2);
  height: 35rem;
  //min-width: 280px;
  width: 310px;


  img {
    height: 54px;
    width: 100px;
  }

  .Upper {
    display: flex;
    justify-content: space-between;

    .Checkbox {
      display: flex;
      width: 100%;
      justify-content: flex-end;

    }
  }

  p {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: var(--color-m7);
  }

  h1 {
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    color: var(--color-secondary-lightest);
  }

  .Middle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .MiddleText {
      flex: 0.5;
    }

    .Icon {
      stroke: var(--color-burger-line);
    }
  }

  .Buttons {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;

    .StatisticsButton {
      width: 80%;
      align-items: center;
      display: flex;
      align-content: center;
      justify-content: center;
    }
  }

}

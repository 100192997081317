.Message {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 24px 86px 24px 42px;
  color: var(--color-secondary-darkest);
  font-family: 'Noto Sans';
  font-style: normal;
  border: 1px solid var(--color-message-border);
  box-shadow: 8px 16px 16px rgba(0, 0, 0, 0.04);

  .MessageTitleLine {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .MessageTitle {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
    }

    .MessageDate {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      transform: translateX(40px);
    }
  }

  .MessageBody {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 20px;
  }

  .MessageButtonWrapper {
    display: flex;
    justify-content: flex-end;

    .MessageButton {
      width: 180px;
    }
  }
}

.Wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 98px;
  position: relative;
  background: var(--background-input);
  border-radius: 12px;
  padding: 12px 14px;

  &.Error {
    pointer-events: none;
  }

  .Input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .Line {
    width: 100%;
    height: 1px;
    background-color: #40404a;
    margin: 10px 0 15px;
  }
}

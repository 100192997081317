.Button {
  border-style: none;
  line-height: 16px;
  cursor: pointer;
  color: var(--color-secondary-lightest);
  display: inline-flex;
  align-items: center;

  // state

  &:hover,
  &:focus {
    text-decoration: none;
    color: var(--color-secondary-lightest);
  }

  // size

  &[data-size="l"],
  &[data-size="m"] {
    font-size: 16px;
    font-weight: 500;
  }

  &[data-size="m"],
  &[data-size="s"] {
    .Icon {
      &.LeftIcon {
        margin-right: 8px;
      }

      &.RightIcon {
        margin-left: 8px;
      }
    }
  }

  &[data-size="l"] {
    padding: 16px 24px;

    &[data-variant="tetriary"],
    &[data-variant="tetriary=blue"] {
      padding: 14px 24px;

      &[data-with-central-icon="true"] {
        padding: 14px;
      }
    }

    &[data-with-central-icon="true"] {
      padding: 16px;
    }

    .Icon {
      &.LeftIcon {
        margin-right: 12px;
      }

      &.RightIcon {
        margin-left: 12px;
      }
    }
  }

  &[data-size="m"] {
    padding: 12px 20px;

    &[data-variant="tetriary"],
    &[data-variant="tetriary=blue"] {
      padding: 10px 20px;

      &[data-with-central-icon="true"] {
        padding: 10px;
      }
    }

    &[data-with-central-icon="true"] {
      padding: 12px;
    }
  }

  &[data-size="s"] {
    font-size: 14px;
    padding: 8px 16px;
    font-weight: 600;

    &[data-variant="tetriary"],
    &[data-variant="tetriary=blue"] {
      padding: 6px 16px;

      &[data-with-central-icon="true"] {
        padding: 6px;
      }
    }

    &[data-with-central-icon="true"] {
      padding: 8px;
    }
  }

  // shape

  &[data-shape="rounded"] {
    border-radius: 100px;
  }

  &[data-shape="square"] {
    border-radius: 12px;
  }

  // variant

  &[data-variant="primary"] {
    background: var(--color-blue-primary);
    transition: background var(--animation-transition), color var(--animation-transition),
      opacity var(--animation-transition);

    &:not(:disabled) {
      &:hover {
        background: var(--color-blue-primary-hover);
        transition: background var(--animation-transition);
      }
    }

    &:disabled {
      color: rgba(255, 255, 255, 0.56);
      opacity: 0.24;
      transition: opacity var(--animation-transition), color var(--animation-transition);
    }
  }

  &[data-variant="secondary"] {
    background: var(--color-m1);
    transition: background var(--animation-transition), color var(--animation-transition),
      opacity var(--animation-transition);

    &:not(:disabled) {
      &:hover {
        background: var(--color-m2);
        transition: background var(--animation-transition);
      }
    }

    &:disabled {
      color: var(--color-m9);
      opacity: 0.24;
      transition: opacity var(--animation-transition), color var(--animation-transition);
    }
  }

  &[data-variant="tetriary"] {
    background: transparent;
    border: 2px solid;
    border-color: rgba(255, 255, 255, 0.24);
    color: rgba(255, 255, 255, 0.8);
    transition: border-color var(--animation-transition), opacity var(--animation-transition);

    &:not(:disabled) {
      &:hover {
        border-color: rgba(255, 255, 255, 0.48);
        transition: border-color var(--animation-transition);
      }
    }

    &:disabled {
      opacity: 0.24;
      cursor: not-allowed;
      transition: opacity var(--animation-transition);
    }
  }

  &[data-variant="tetriary-blue"] {
    background: transparent;
    border: 2px solid;
    border-color: rgba(0, 102, 255, 0.24);
    color: rgba(255, 255, 255, 0.8);
    transition: border-color var(--animation-transition), opacity var(--animation-transition);

    &:not(:disabled) {
      &:hover {
        border-color: rgba(0, 41, 255, 0.48);
        transition: border-color var(--animation-transition);
      }
    }

    &:disabled {
      opacity: 0.24;
      border-color: rgba(0, 102, 255, 0.24);
      transition: opacity var(--animation-transition), border-color var(--animation-transition);
    }
  }

  &[data-variant="ghost"] {
    background: transparent;
    transition: opacity var(--animation-transition), background var(--animation-transition);

    &:not(:disabled) {
      &:hover {
        background: rgba(30, 30, 36, 0.8);
        transition: background var(--animation-transition);
      }
    }

    &:disabled {
      opacity: 0.24;
      transition: opacity var(--animation-transition);
    }
  }

  &[data-variant="white"] {
    background: #f0f0f0;
    color: var(--color-blue-primary);
    transition: opacity var(--animation-transition), background var(--animation-transition),
      color var(--animation-transition);

    &:not(:disabled) {
      &:hover {
        background: var(--color-secondary-lightest);
        transition: background var(--animation-transition);
      }
    }

    &:disabled {
      opacity: 0.24;
      background: rgba(255, 255, 255, 0.08);
      color: rgba(255, 255, 255, 0.56);
      transition: opacity var(--animation-transition), background var(---animation-transition),
        color var(--animation-transition);
    }
  }

  &[data-variant="magic"] {
    background: linear-gradient(90deg, #fa0fff 0%, #7408e0 100%);
    box-shadow: 0 18px 32px -14px rgba(176, 16, 211, 0.25), 0px 30px 45px rgba(176, 16, 211, 0.1);

    transition: background var(--animation-transition), color var(--animation-transition);

    &:not(:disabled) {
      &:hover {
        background: linear-gradient(90deg, #fa0fff -10.1%, #7408e0 140.4%);
        transition: background var(--animation-transition);
      }
    }

    &:disabled {
      background: rgba(116, 8, 224, 0.24);
      color: rgba(255, 255, 255, 0.09);
      transition: background var(--animation-transition), color var(--animation-transition);
    }
  }

  &[data-variant="text"] {
    border-style: none;
    background: transparent;
    padding: 0;
    font-weight: 500;
    color: var(--color-text-primary);
    text-align: left;
    display: flex;
    align-items: center;
  }

  &[data-variant="delete"] {
    border-style: none;
    background: transparent;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    color: var(--color-red);
    text-align: left;
  }

  &[data-variant="viper"] {
    display: flex;
    justify-content: space-between;
    background: #1e1e24;
    border: 1px solid #40404a;
    border-radius: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.8);
    padding: 21px 16px;
    transition: border-color var(--animation-transition), opacity var(--animation-transition);

    svg {
      fill: #40404a;
      width: 16px;
      height: 16px;
    }

    &:not(:disabled) {
      &:hover {
        border-color: rgba(255, 255, 255, 0.48);
        transition: border-color var(--animation-transition);
      }
    }

    &:disabled {
      opacity: 0.24;
      transition: opacity var(--animation-transition);
    }
  }

  .Icon {
    fill: var(--color-secondary-lightest);
  }
}

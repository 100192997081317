@import "../../../../styles/_mixins.scss";

.Preview {
  background: #272727;
  border-radius: 26px;
  height: 100%;
  overflow: auto;
  position: relative;
  @include vertical-scroll-bar;
}

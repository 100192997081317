.Wrapper {
  position: relative;

  &:hover,
  &:focus {
    .Tip {
      display: block;
      opacity: 1;
      transition: opacity var(--animation-transition);
    }
  }

  .Tip {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    white-space: nowrap;
    opacity: 0;
    padding: 6px 12px;
    border-radius: 16px;
    backdrop-filter: blur(8px);
    background: var(--color-tooltip-bg);
    box-shadow: var(--color-tooltip-shadow);
    color: var(--color-secondary-lightest);
    font-size: 10px;
    opacity: 0;
    transition: opacity var(--animation-transition);
  }
}

.Wrapper {
  display: flex;

  // alignment

  &[data-alignment="left"] {
    justify-content: flex-start;
  }

  &[data-alignment="center"] {
    justify-content: center;
  }

  &[data-alignment="right"] {
    justify-content: flex-end;
  }

  .Button {
    padding: 14px 0;
    font-size: 16px;
    line-height: 16px;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    // text alignment

    &[data-text-alignment="left"] {
      padding-left: 10px;
      padding-right: 50px;
    }

    &[data-text-alignment="center"] {
      padding-left: 30px;
      padding-right: 30px;
    }

    &[data-text-alignment="right"] {
      padding-left: 50px;
      padding-right: 10px;
    }

    // click handler

    &[data-has-click-handler="true"] {
      cursor: pointer;
    }

    &[data-has-click-handler="false"] {
      cursor: default;
    }

    // block style

    &[data-block-style="rounded"] {
      border-radius: 100px;
    }

    &[data-block-style="square"] {
      border-radius: 4px;
    }
  }
}

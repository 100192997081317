.Wrapper {
  display: none;
  background: var(--color-m0);

  .Trigger {
    height: 74px;
    padding: 18px 20px;
    align-items: center;
    justify-content: space-between;
    display: flex;

    .Content {
      display: flex;
      align-items: center;

      .Avatar {
        margin-right: 16px;
        border-radius: 50%;
      }

      .Info {
        .Name {
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: var(--color-secondary-lightest);
          margin-bottom: 2px;
        }

        .Time {
          color: var(--color-m8);
          opacity: 0.7;
        }
      }
    }

    .Icon {
      fill: var(--color-m8);
      transform: rotate(-180deg);
      transition: transform var(--animation-transition);

      &.IconOpen {
        transform: rotate(0deg);
        transition: transform var(--animation-transition);
      }
    }
  }

  .Stats {
    padding: 0 20px;
    padding-bottom: 20px;
  }

  @media (max-width: 704px) {
    display: block;
  }
}

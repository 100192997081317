.Wrapper {
  padding: 2px;
  background: linear-gradient(
    120deg,
    rgba(249, 45, 253, 0.14),
    rgba(223, 58, 252, 0.8495),
    rgba(115, 112, 249, 0.21),
    rgba(13, 163, 246, 0.84),
    rgba(13, 163, 246, 0.19)
  );
  border-radius: 16px;

  .Content {
    padding: 24px 32px 24px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 244px;
    background: var(--color-m0);
    border-radius: 16px;

    .Title {
      margin: 0;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: var(--color-text-primary);
    }

    .Text {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      opacity: 0.8;
      line-height: 19px;
      color: var(--color-text-primary);
    }

    .Btn {
      width: 188px;
      height: 44px;
      padding: 12px 24px;
    }
  }
}

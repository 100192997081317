$height: 42px;

%colored-panel {
  background: var(--color-m1);
  cursor: pointer;
  transition: background var(--animation-transition);
  color: var(--color-d9-input);

  &:hover {
    background: rgba(52, 53, 55, 0.5);
  }
}

.RoundedCorners {
  th,
  td {
    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
}

.BigText {
  font-family: var(--font-secondary);
  font-weight: 600;
  text-transform: uppercase;
  color: var(--color-d9-input);
}

.Table {
  border-collapse: separate;
  border-spacing: 0 4px;
  width: 100%;
  height: 100%;
  font-size: 14px;

  &[data-no-data="true"] {
    table-layout: fixed;
  }

  tr {
    height: $height;
    text-align: left;

    th {
      @extend %colored-panel;
      @extend .BigText;
      padding: 16px 12px;

      &:hover {
        .Circle {
          opacity: 1;
        }
      }
    }

    .TableFiltersButtons {
      width: 100%;
      padding: 16px 12px;
      position: absolute;
      inset: 0;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: none;
      background: transparent;
      cursor: pointer;
    }

    .Circle {
      width: 20px;
      height: 20px;
      border-radius: 9999px;
      background: rgba(112, 112, 124, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity var(--animation-transition);

      .Chevron {
        position: unset;
        transform: rotate(-90deg);
        fill: transparent;
        stroke: var(--color-secondary-lightest);
      }
    }
  }

  tbody {
    position: relative;
    tr {
      height: $height;
      @extend %colored-panel;
    }

    td {
      display: table-cell;
      padding: 16px 12px;
      min-width: 110px;
    }
  }
}

.isNotRead{
  background: rgba(52, 53, 55, 0.5);
}

.NoApplications {
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--color-secondary-lightest);
  opacity: 0.3;
}

.svgIcon{
  margin-right: 8px;
  vertical-align: middle;
}

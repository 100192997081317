.ModalWrapper{
  text-align: center;
  h2{
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    color: rgba(253, 68, 56, 1) !important;
    margin-left: 30px;
  }
  .Description{
    color: rgba(208, 208, 208, 1);
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  }
  @media (max-width: 670px) {
    h2{
      width: 100% !important;
      margin-left: 0;
    }
    .Description{
      width: 180px;
      margin-left: 15px;
    }
  }
}

.Section {
  .Title,
  .ActionsTitle {
    font-size: 16px;
    line-height: 22px;
    background: linear-gradient(
      90.43deg,
      rgba(255, 255, 255, 0.6) 0%,
      #ffffff 18.07%,
      rgba(255, 255, 255, 0.8) 83.79%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    opacity: 0.6;
    margin-bottom: 22px;
  }

  .Content {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 24px;

    .Actions {
      .ActionsTitle {
        display: none;
      }

      .Grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;
      }
    }

    .Aside {
      & > * + * {
        margin-top: 8px;
      }
    }
  }

  @media (max-width: 1360px) {
    .Content {
      display: flex;
      flex-direction: column;
      gap: unset;

      .Aside {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        padding-bottom: 10px;

        article {
          width: 284px;
        }

        & > * + * {
          margin-top: 0;
          margin-left: 16px;
        }
      }

      .Actions {
        .ActionsTitle {
          display: block;
          margin-top: 0;
          margin-bottom: 16px;
        }
      }
    }
  }

  @media (max-width: 1092px) {
    .Content {
      .Actions {
        .Grid {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }

  @media (max-width: 876px) {
    .Content {
      .Aside {
        width: calc(100vw - 40px);
        overflow-x: auto;
      }

      .Actions {
        .Grid {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }

  @media (max-width: 612px) {
    .Content {
      .Actions {
        .Grid {
          display: flex;
          flex-direction: column;
          gap: unset;

          & > * + * {
            margin-top: 16px;
          }
        }
      }
    }
  }
}

.Nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 68px;
  background: rgba(20, 20, 20, 0.9);
  backdrop-filter: blur(4px);
  padding: 8px 14px;
  display: none;

  .Wrapper {
    width: 294px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;

    .Btn,
    .MainBtn {
      cursor: pointer;
      padding: 0;
      border: 1px solid var(--color-m3);
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:disabled {
        opacity: 0.5;
      }
    }

    .Btn {
      width: 44px;
      height: 36px;
      background: transparent;
    }

    .MainBtn {
      width: 54px;
      height: 54px;
      background: var(--color-m2);
    }

    .Icon {
      fill: var(--color-secondary-lightest);
    }
  }

  @media (max-width: 670px) {
    display: block;
  }
}

.Root{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  background: transparent;
  border: 2px solid;
  border-color: rgba(255, 255, 255, 0.24);
  border-radius: 100px;
  color: rgba(255, 255, 255, 0.8);
  transition: border-color var(--animation-transition), opacity var(--animation-transition);
}

.Form {
  width: 276px;
  margin: 0 auto;

  .Fields {
    margin-bottom: 24px;

    & > * + * {
      margin-top: 8px;
    }

    .Checkbox {
      margin-top: 28px;

      .CheckboxText {
        max-width: unset;
        font-size: 10px;

        .Link {
          color: inherit;
          text-decoration: underline;
        }
      }
    }
  }
}

.Section {
  .Title,
  .ActionsTitle {
    font-size: 16px;
    line-height: 22px;
    background: linear-gradient(
        90.43deg,
        rgba(255, 255, 255, 0.6) 0%,
        #ffffff 18.07%,
        rgba(255, 255, 255, 0.8) 83.79%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    opacity: 0.6;
    margin-bottom: 22px;
    max-width: 55rem;
  }

  .IsTitleError{
    color: var(--color-red);
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 22px;
    max-width: 55rem;
  }

  .SearchField {
    display: none;
  }

  .Content {
    .Actions {
      .ActionsTitle {
        display: none;
      }

      .Grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 24px;
      }
    }

  }


  @media (max-width: 1475px) {
    .Content {
      .Actions {
        .Grid {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }

  @media (max-width: 1366px) {
    .SearchField {
      display: block;
      margin-bottom: 20px;

      &:focus {
        font-size: 16px;
      }
    }

    .Content {
      display: flex;
      flex-direction: column;
      gap: unset;


      .Actions {
        .ActionsTitle {
          display: block;
          margin-top: 0;
          margin-bottom: 16px;
        }
      }
    }
  }

  @media (max-width: 1092px) {
    .Content {
      .Actions {
        .Grid {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }


  @media (max-width: 612px) {
    .Content {
      .Actions {
        .Grid {
          display: flex;
          flex-direction: column;
          gap: unset;

          & > * + * {
            margin-top: 16px;
          }
        }
      }
    }
  }
}

.TextLink {
  color: var(--color-m7);
  text-decoration: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 12px 0;
  margin-left: 16px;
}

.FormFields {
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 12px;
  }
}

.Root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px dashed #DF3AFC;
  height: 100%;
  border-radius: 8px;
  min-height: 185px;
  padding: 20px;
  gap: 20px;
  position: relative;

  .Image{
    position: absolute;
    height: inherit;
    width: inherit;
    border-radius: inherit;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    cursor: pointer;
  }

  &.IsRow {
    justify-content: space-between;
    flex-direction: row;
    min-height: 100%;
    padding: 5px;
  }

  .TitleWrap {
    flex: 1;
    align-items: flex-end;
    display: flex;
  }

  .Title {
    font-size: 16px;
    font-weight: 600;
    color: var(--color-m6);
    text-align: center;
    margin: 0;

    @media (max-width: 386px) {
      font-size: 11px;
    }
  }


  .Info {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    width: 100%;


    .InfoPar {
      font-weight: 400;
      font-size: 10px;
      color: var(--color-m4);
      text-align: center;
      overflow-wrap: break-word;
      margin: 0;
      gap: 15px;

      @media (max-width: 386px) {
        font-size: 9px;
      }
    }

    .Email {
      font-weight: 400;
      font-size: 12px;
      background: linear-gradient(135deg, #E97059, #B10EDB);
      -webkit-background-clip: text;
      color: transparent;
      text-decoration: underline;
      text-align: center;
      overflow-wrap: break-word;
      word-break: break-all;
      cursor: pointer;
    }
  }
}

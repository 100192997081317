.Root {
  display: flex;
  align-items: center;
  gap: 20px;

  .Title {
    font-size: 24px;
    margin: 0;
  }

  .Btns{
    margin-top: 15px;
    display: flex;
    column-gap: 24px;
    justify-content: space-between;
  }
}

.Notification {
  min-height: 200px;
  padding: 24px 76px;
  position: relative;
  display: flex;
  flex-direction: column;
  column-gap: 8px;
  font-style: normal;

  .Circle {
    position: absolute;
    top: 30px;
    left: 40px;
    width: 12px;
    height: 12px;
    border-radius: 9999px;
    background: var(--color-m2);
    transition: background var(--animation-transition);

    &.Active {
      background: var(--color-blue);
    }
  }

  .Title {
    color: var(--color-secondary-lightest);
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin: 0;
  }

  .Data {
    color: var(--color-d9-input);
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
  }

  .Content {
    margin-top: 8px;
    color: var(--color-m7);
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin: 0;
    margin-top: 8px;
    text-align: left;
  }

  .Button {
    margin: 0;
    margin-top: 16px;
    width: 92px;
  }
}

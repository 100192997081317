@import "fonts.scss";
@import "vars.scss";
@import "_mixins.scss";

* {
  box-sizing: border-box;
  font-family: var(--font-primary);
}

body {
  background: var(--dark-background);
  @include vertical-scroll-bar;

  button,
  input {
    &:hover,
    &:focus,
    &:active {
      outline: none;
    }
  }
}

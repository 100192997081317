.Btn {
  padding: 0;
  border-style: none;
  background: transparent;
}

.Menu {
  width: 272px;
  left: 50%;
  transform: translateX(-50%);
}

.Logo {
  display: flex;
  align-items: center;

  .Title {
    margin: 0;
    margin-right: 12px;
    font-size: 24px;
    line-height: 29px;
  }

  .Label {
    background: rgba(33, 111, 224, 0.16);
    border: 0.5px solid #216fe0;
    border-radius: 5px;
    padding: 4px 8px;
    font-size: 10px;
    line-height: 14px;
    color: var(--color-drag-pad-inactive);
  }

  @media (max-width: 876px) {
    .Title {
      font-size: 16px;
      line-height: 20px;
      margin-right: 8px;
    }

    .Label {
      font-size: 8px;
      line-height: 14px;
    }
  }
}

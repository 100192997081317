.Entry {
  display: flex;
  align-items: center;
  column-gap: 16px;

  .Icon {
    flex-grow: 1;
    fill: var(--color-secondary-lightest);
    opacity: 0.3;
    align-self: flex-start;
  }

  .Long {
    flex-shrink: 10;
    display: flex;
    flex-direction: column;

    .Name {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: var(--color-m9);
      margin-bottom: 8px;
    }

    .Value {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: var(--color-m7);
      max-height: 50px;
      overflow-y: hidden;
      text-overflow: ellipsis;
    }
  }
}

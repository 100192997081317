.Toastify__toast-container {
  width: var(--toast-size-pc);
  padding: 0;
}

/** Classes for the displayed toast **/
.Toastify__toast {
  border-radius: 24px;
  padding: 0;
}

.Toastify__toast-body {
  margin: 0;

  & :last-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

/** handle the notificaiton color and the text color based on the theme **/

.Toastify__toast-theme--light {
  height: 48px !important;
  display: flex;
  align-items: center;
}

.Toastify__toast--success {
  background: var(--toast-background-success);
  box-shadow: var(--toast-shadow-success);
}

.Toastify__toast--error {
  background: var(--toast-background-error);
  box-shadow: var(--toast-shadow-error);
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
  display: none;
}

.Toastify__toast-icon {
  display: none;
}

@media screen and (max-width: 1000px) {
  .Toastify__toast-container {
    width: var(--toast-size-mob-big);
  }
}

@media screen and (max-width: 490px) {
  .Toastify__toast-container {
    width: var(--toast-size-mob-small);
    margin: 0px 0px 15px 30px;
  }
}

.Wrapper {
  background: var(--color-grey-cards-bg);
  border-radius: 24px;
  padding: 1px;
  height: 278px;

  &[data-selected="true"] {
    background: linear-gradient(
      -42.26deg,
      rgba(255, 255, 255, 0.58) 0%,
      rgba(255, 255, 255, 0) 119.66%,
      rgba(255, 255, 255, 0.54) 119.66%
    );
  }

  .Card {
    background: var(--color-grey-cards-bg);
    border-radius: 24px;
    padding: 44px 28px 32px;
    height: 100%;
    position: relative;

    .Checkbox {
      cursor: pointer;
      position: absolute;
      top: 16px;
      right: 24px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--color-element-bg);

      .Icon {
        fill: var(--color-secondary-lightest);
      }
    }

    .Header {
      display: flex;
      align-items: center;

      .Avatar {
        border-radius: 50%;
        margin-right: 24px;
      }

      .SubsWrapper {
        .Count {
          font-size: 16px;
          line-height: 22px;
          color: var(--color-secondary-lightest);
        }

        .Label {
          font-size: 14px;
          line-height: 19px;
          color: var(--color-m5);
        }
      }
    }

    .Content {
      margin-top: 20px;
      margin-bottom: 32px;

      .Type {
        font-size: 12px;
        line-height: 16px;
        color: var(--color-m6);
        margin-bottom: 4px;
      }

      .Name {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: var(--color-secondary-lightest);
      }
    }

    .Btn {
      height: 40px;
      padding: 0 24px;
    }
  }
}

.Blank {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 32px;
  border-radius: 0px 16px;
  margin-top: 0;
}

.Card {
  &[data-plan="premium"] {
    .Blank {
      background: linear-gradient(135.23deg, #9136fa 0%, #b10edb 99.95%);
      box-shadow: -20px 13px 37px rgba(164, 11, 235, 0.08), -10px 9px 24px rgba(164, 11, 235, 0.17);
    }
  }
  &[data-plan="standard"] {
    .Blank {
      background: linear-gradient(135.23deg, #1a77ff 0%, #4791dc 99.95%);
      box-shadow: -20px 13px 37px rgba(33, 111, 224, 0.08), -10px 9px 24px rgba(33, 111, 224, 0.17);
    }
  }
  &[data-plan="based"] {
    .Blank {
      background: linear-gradient(135.23deg, #e97059 0%, #b10edb 99.95%);
      box-shadow: -20px 13px 37px rgba(232, 15, 233, 0.08), -10px 9px 24px rgba(232, 15, 233, 0.17);
    }
  }
  &[data-plan-cycle="trial"] {
    .Blank {
      background: linear-gradient(238.16deg, #27303a 30.74%, #2d2c32 100%);
      box-shadow: none;
    }
  }

  .Title {
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;
    text-transform: uppercase;
    color: var(--color-secondary-lightest);
  }

  .Text {
    color: var(--color-m6);

    &[data-is-trial="true"] {
      color: var(--color-red);
    }
  }

  .Link {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .Info {
      display: flex;
      flex-direction: column;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      text-align: right;
      color: var(--color-m6);

      .TariffDateLabel {
        margin-bottom: 6px;

        &[data-is-trial="true"] {
          color: var(--color-red);
        }
      }

      .Date {
        color: var(--color-m8);

        .DateDivider {
          display: inline-block;
          margin: 0 10px;
        }
      }
    }
  }

  @media (max-width: 496px) {
    .Title {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 8px;
    }

    .Text {
      display: none;
    }

    .Link {
      .Info {
        align-items: flex-start;

        .TariffDateLabel {
          margin-bottom: 6px;
          font-size: 10px;
          line-height: 14px;
        }

        .Date {
          font-size: 12px;
          line-height: 16px;
          opacity: 0.7;
        }
      }
    }
  }
}

.Modal {
  width: 400px;
  padding: 46px 32px 42px;

  .Title {
    margin-top: 0;
    margin-bottom: 32px;
    font-weight: 700;
    font-size: 24px;
    color: var(--color-secondary-darkest);
    text-align: center;
  }

  .Form {
    display: flex;
    flex-direction: column;
    align-items: center;

    .FormFields {
      width: 100%;

      & > * + * {
        margin-top: 12px;
      }
    }

    .Button {
      margin-top: 40px;
      width: 254px;
    }
  }
}

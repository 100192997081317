.Entry {
  display: flex;
  align-items: center;
  column-gap: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--color-m9);

  .Icon {
    fill: var(--color-secondary-lightest);
    opacity: 0.3;
  }

  .Name {
    max-width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;

    &[data-max-width="true"] {
      max-width: unset;
    }

    .NameLabel {
      display: inline-block;
      margin-left: 6px;
      font-size: 12px;
      line-height: 19px;
      text-align: right;
      color: var(--color-m6);
    }
  }

  .Value {
    color: var(--color-m8);
  }

  span:last-child {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}

.Row {
  height: 5.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  border-radius: 4px;
  border: 1px solid var(--color-m2);
  background: var(--color-m1);
  margin-bottom: 4px;
  cursor: pointer;

  &.SelectedRow {
    background: linear-gradient(90deg, #2C2C35 0%, #363639 25.86%, #2C2C35 100%), #1E1E24;
  }

  .StatusTd {
    width: 20rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 750px) {
      width: 10rem;
    }
  }

  .LastTd {
    display: flex;
    justify-content: flex-end;
    width: 20rem;

    @media (max-width: 750px) {
      width: 15rem;
    }
  }

  .Par {
    margin: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    color: var(--color-text-primary);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .Status {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 8px;
    animation: colorChange 1s infinite;
    background: var(--color-a5-lime);
    box-shadow: 0 4px 4px 0 rgba(30, 215, 49, 0.25);

    &.DangerStatus {
      background: var(--color-a6-coral-err);
      box-shadow: 0 4px 4px 0 rgba(253, 68, 56, 0.25);
    }
  }

  @keyframes colorChange {
    0% {
      opacity: 0.5; /* Start with red */
    }
    50% {
      opacity: 0.7; /* Change to blue halfway through the animation */
    }
    100% {
      opacity: 1; /* End with green */
    }
  }

  .Btn {
    border-color: var(--color-red);
    color: var(--color-red);

    @media (max-width: 750px) {
      font-size: 15px;
    }


    &:hover {
      border-color: var(--color-red);
      color: var(--color-red);
      opacity: .9;
    }
  }

  .CheckboxWrap {
    display: flex;
    align-items: center;
    width: 20rem;

    @media (max-width: 750px) {
      width: 15rem;
    }

    .Checkbox {
      margin-right: 5px;
    }


  }
}

@keyframes dropdown-animation {
  0% {
    top: calc(74px - 20px);
    opacity: 0;
  }

  100% {
    opacity: 1;
    top: calc(74px - 8px); // .DropdownBtn height - some space
  }
}

%flex-row {
  display: flex;
  align-items: center;
}

%flex-column {
  display: flex;
  flex-direction: column;
}

.Popover {
  position: relative;

  .PopoverButton {
    background: transparent;
    border: none;
    cursor: pointer;
    width: 100%;
    padding: 0;
    margin: 0;
  }
}

.DateModal {
  position: absolute;
  top: -350px;
  left: 85%;
  right: -85%;
  @extend %flex-column;
  border-radius: 8px;
  overflow: hidden;
  max-width: 300px;
  background: linear-gradient(204.74deg, rgba(39, 48, 58, 0.9) 15.77%, rgba(45, 44, 50, 0.9) 99.27%);
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.16);
  backdrop-filter: blur(8px);
  z-index: 100;
  color: var(--color-secondary-lightest);

  @media (max-width: 876px) {
    left: 0;
    right: 0;
  }

  .Space {
    height: 48px;
  }

  .Header {
    padding: 0 12px;
    @extend %flex-row;
    background: var( --color-persian);
    height: 40px;

    & > span {
      width: calc(100% / 7);
      text-align: center;
      vertical-align: middle;
      font-family: "Noto Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
    }
  }

  .Body {
    @extend %flex-column;
    column-gap: 16px;
    padding: 0 12px;

    .Days {
      @extend %flex-row;
      flex-wrap: wrap;

      .Day {
        width: calc(100% / 7);
        text-align: center;
        height: 50px;
        border-radius: 8px;
        vertical-align: middle;
        line-height: 50px;
        cursor: pointer;
        color: var(--color-secondary-lightest);

        position: relative;

        &.DaySelected {
          background: rgba(255, 255, 255, 0.19);
          color: var(--color-m6);
          &.DayCurrent::after {
            background-color: var(--color-secondary-lightest);
            color: var(--color-m6);

          }
        }

        &.DayCurrent {
          color: var(--color-secondary-lightest);
        }

        &.DayBefore {
          cursor: not-allowed;
          pointer-events: none;
          color: var(--color-m6);
        }

        &.DayCurrent::after {
          position: absolute;
          bottom: 6px;
          left: 18px;
          content: "";
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 6px;
          background-color: var(--color-secondary-lightest);
        }

        &.OtherDay {
          //color: var(--color-secondary-lightest);
          color: var(--color-m6);

          cursor: auto;
        }
      }
    }

    .MonthLine {
      @extend %flex-row;
      margin-top: 14px;
      justify-content: space-between;

      .LeftArrow,
      .RightArrow {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        fill: var(--color-secondary-lightest);
      }

      .LeftArrow {
        transform: rotate(-90deg);
      }
      .RightArrow {
        transform: rotate(90deg);
      }
    }

    .TimeLine {
      @extend %flex-row;
      justify-content: flex-end;
      margin-bottom: 20px;

      .TimeLabel {
        font-family: "Noto Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: var(--color-text-gray);
        margin-right: 10px;
      }

      .TimeInput {
        max-width: 40px;
        margin: 0 8px;
        text-align: center;
        color: var(--color-secondary-lightest);
        border: 2px solid var(--color-m4);
        background: transparent;
        border-radius: 4.51429px;
        padding: 4px;

        &:focus,
        &:active {
          border: 2px solid var( --color-persian);
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &[type="number"] {
          -moz-appearance: textfield;
        }
      }
    }

    .Button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 26px;
      width: 100%;
      cursor: pointer;
    }
  }
}

.Section {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;

  @media (max-width: 1312px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1026px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 624px) {
    display: flex;
    flex-direction: column;
    grid-gap: unset;

    & > * + * {
      margin-top: 16px;
    }
  }

  .EmptyCard {
    height: 480px;
  }
}

.Empty {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}

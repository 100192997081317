.Wrapper {
  div {
    font-weight: 400;
  }

  .Label {
    font-size: 10px;
    line-height: 14px;
    color: var(--color-m5);
    margin-top: 2px;
  }
}

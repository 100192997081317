.AvatarWrapper {
  position: relative;
  border-radius: 9999px;
  overflow: hidden;
  display: flex;
  flex: 1;
}

.Avatar {
  flex: 1;
  object-fit: cover;
  z-index: 1;
}

.ImageFallback {
  flex: 1;
  fill: var(--color-secondary-lightest);
}

.Modal {
  font-family: var(--font-primary);

  .Title {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: var(--color-text-primary);
    margin: 0;
    margin-bottom: 32px;
    text-transform: uppercase;
  }

  .Question {
    font-weight: 400;
    font-size: 14px;
    color: var(--color-text-light);
    margin: 0;
    margin-bottom: 14px;
  }

  .Btns {
    margin-top: 15px;
    display: flex;
    column-gap: 24px;
    justify-content: space-between;
  }
}

.Card {
  display: flex;
  max-width: 600px;
  min-height: 100vh;
  overflow: hidden;

  @media (min-width: 600px) {
    min-height: auto;
    margin: 5rem auto;
    border-radius: 26px;
    box-shadow: -2px 9px 19px -7px rgba(0, 0, 0, 0.2);
  }
}

.Page {
  padding: 40px 56px;
  display: flex;
  flex-direction: column;
  min-height: 90vh;

  @media (max-width: 876px) {
    .CardWrapper {
      display: none;
    }
  }

  @media (max-width: 500px) {
    padding: 16px;
  }
}

.Message {
  margin-bottom: 8px;
}

.Date {
  color: var(--color-m5);
}

.MAddButton {
  border-radius: 16px !important;
  display: flex !important;
  justify-content: center !important;
  padding: 16px !important;

  svg {
    width: 24px !important;
    height: 24px !important;
    margin-right: 16px !important;
  }

  @media (min-width: 876px) {
    min-height: 122px;
  }
}

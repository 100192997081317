.Nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;

  .NavLeftInner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-right: 9rem;

    .GoBack {
      display: flex;
      align-items: center;
      column-gap: 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: var(--color-d6);
      fill: transparent;
      stroke: var(--color-d6);
      stroke-width: 2px;
      cursor: pointer;
      margin-right: 16px;
    }

    .FolderNameContainer {
      display: flex;
      flex-direction: column;

      .Title {
        font-family: var(--font-h4);
        margin: 0;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        text-transform: uppercase;
        white-space: nowrap;
      }

      .BackFolders {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        opacity: 0.6;
        background: linear-gradient(
          140deg,
          rgba(255, 255, 255, 0.6) 0%,
          #fff 21.57%,
          rgba(255, 255, 255, 0.8) 100%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .Controls {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 18rem;

    .RegBtn {
      justify-content: center;
      height: 35px;
      width: 10rem;
    }

    & > * + * {
      margin-left: 24px;
    }

    .IconBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 49px;
    }

    .MainBtn {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      white-space: nowrap;
    }
  }

  @media (max-width: 876px) {
    flex-direction: column;

    .NavLeftInner {
      justify-content: flex-start;
    }

    .Controls {
      justify-content: space-between;
      width: 100%;
    }
  }
}

.Group {
  display: flex;
  position: absolute;
  z-index: 5;
  bottom: 8px;

  &[data-position="left"] {
    left: 8px;
  }

  &[data-position="right"] {
    right: 8px;
  }

  & > * + * {
    margin-left: 4px;
  }

  @media (max-width: 670px) {
    display: none;
  }
}

.Menu {
  display: flex;
  align-items: center;
  margin-left: 24px;

  .IconDown {
    margin-left: 8px;
    stroke: var(--color-secondary-lightest);
  }

  @media (max-width: 876px) {
    margin-left: 0;
  }
}

.btn{
  max-width: 100%;
  &[data-type='mobile']{
    max-width: 100% !important;
    border: 1px solid rgba(44, 44, 53, 1);
    padding: 16px;
    border-radius: 12px;
    grid-template-areas: "b b a" "b b c" "b b c";
    text-align: left;
    .IconDown{
      margin-left: 8px;
      stroke: rgba(92, 93, 97, 1);
    }
    .Label{
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: rgba(148, 148, 148, 1);
      text-align: left;
    }
  }
}

.Root {
  height: 51px;
  background: #1D1D22;

  .Inner {
    & > * {
      color: var(--color-d9-input);
      padding: 16px;
      font-weight: 400;
      font-size: 14px;
    }
  }
}

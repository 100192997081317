.GoBack {
  display: flex;
  align-items: center;
  column-gap: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: var(--color-d6);
  fill: transparent;
  stroke: var(--color-d6);
  stroke-width: 2px;
  cursor: pointer;
  .backWord{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
  }
}

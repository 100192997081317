.Modal {
  width: 464px;
  height: 179px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .Title {
    font-size: 16px;
    font-weight: 700;
    margin: 16px 0 12px 0;
  }

  .Text {
    text-align: center;
    margin: 0;
    width: 431px;
    font-size: 14px;
    font-weight: 400;
  }

  .BtnWrapper {
    margin: 24px 0 32px 0;

    .Cancel {
      width: 116px;
      height: 35px;
      margin-right: 7px;
    }

    .Confirm {
      width: 116px;
      height: 35px;
    }
  }
}

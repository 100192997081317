.Input {
  height: 24px;
  border-style: none;
  padding: 0;
  background: transparent;
  max-width: 24px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }
}

.Percent {
  display: block;
  margin-left: 2px !important;
}

.Card {
  padding: 20px 16px 16px;
  background: linear-gradient(
    204.74deg,
    rgba(39, 48, 58, 0.16) 15.77%,
    rgba(45, 44, 50, 0.16) 99.27%
  );
  border: 1px solid rgba(255, 255, 255, 0.06);
  border-radius: 16px;

  .Header,
  .Footer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .Header {
    margin-bottom: 12px;

    .Avatar {
      border-radius: 50%;
    }

    .Scenarios {
      font-size: 12px;
      line-height: 16px;
      color: var(--color-m6);
      display: flex;
      align-items: center;

      .Icon {
        margin-left: 4px;
        fill: var(--color-m4);
      }
    }
  }

  .Footer {
    .Info {
      .Username {
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 19px;
        color: var(--color-secondary-lightest);
      }

      .Time {
        font-size: 12px;
        line-height: 16px;
        color: var(--color-m6);
      }
    }

    .Menu {
      .Context {
        top: -8px !important;
        right: -20px !important;

        .Delete {
          color: var(--color-a6-coral-err);
        }
      }

      svg {
        fill: var(--color-gray-darker);
      }
    }
  }
}

@mixin label-active() {
  color: var(--color-secondary-darker);
  font-size: 12px;
  top: 12px;
  transition: font-size var(--animation-label-font), top var(--animation-transition),
    color var(--animation-transition);
}

.Wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  .InputWrapper {
    min-height: 64px;
    background: var(--background-input);
    border-radius: 12px;
    padding: 0 16px;
    position: relative;
    display: flex;
    align-items: flex-end;
    border: 1px solid transparent;
    transition: border-color var(--animation-transition);

    // variant

    &[data-variant="light"] {
      border: 1px solid var(--border-input);
      background: var(--color-gray-darker);
    }

    &[data-variant="dark"] {
      border: 1px solid var(--color-m2);
      background-color: var(--color-m0);
    }

    // state

    &:hover {
      border: 1px solid var(--color-m4);
      transition: border-color var(--animation-transition);

      .Input {
        color: var(--color-m7);
        transition: color var(--animation-transition);
      }

      .Label {
        color: var(--color-m5) !important;
        transition: color var(--animation-transition);
      }
    }

    &[data-active="true"] {
      border: 1px solid var(--color-blue-primary);
      transition: border-color var(--animation-transition);

      .Input {
        color: var(--color-gray);
      }

      .Label {
        color: var(--color-secondary-darker) !important;
      }
    }

    &[data-invalid="true"] {
      border: 1px solid var(--color-error);
      transition: border-color var(--animation-transition);

      .Label {
        color: var(--color-error) !important;
      }
    }

    &[data-disabled="true"] {
      border: 1px solid var(--color-m2);
      background: var(--color-m1);

      .Label {
        color: var(--color-m4) !important;
      }

      .Input {
        color: var(--color-secondary-darker);
      }
    }

    .Input:-webkit-autofill {
      @include label-active();
    }

    .Label {
      position: absolute;
      left: 16px;
      top: 20px;
      font-size: 16px;
      font-family: var(--font-secondary);
      color: var(--color-gray);
      font-weight: 400;
      transition: font-size var(--animation-transition), top var(--animation-transition),
        color var(--animation-transition);

      &[data-title="true"] {
        font-size: 24px;
        font-weight: 700;
        top: 16px;
      }
    }

    .Input {
      min-height: 22px;
      font-size: 16px;
      margin-top: 30px;
      font-weight: 400;
      color: var(--color-gray);
      width: 100%;
      margin-bottom: 11px;
      border-style: none;
      background: transparent;
      padding: 0;
      transition: color var(--animation-transition);

      &:-webkit-autofill {
        -webkit-text-fill-color: var(--color-text-primary);
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-size-adjust: 14px;

        &:hover,
        &:focus {
          -webkit-text-fill-color: var(--color-text-primary);
          transition: background-color 5000s ease-in-out 0s;
          -webkit-text-size-adjust: 14px;
        }
      }

      &[data-title="true"] {
        font-size: 24px;
        font-weight: 700;
        height: 34px;

        &:focus,
        &:not(:placeholder-shown) {
          & + .Label {
            display: none;
          }
        }
      }

      &[data-big="true"] {
        font-size: 16px;
      }

      &[data-bold="true"] {
        font-weight: 700;

        & + .Label {
          font-weight: 700;
        }
      }

      &:focus,
      &:not(:placeholder-shown) {
        outline: none;

        & + .Label {
          @include label-active();
        }
      }

      &[type="date"]::-webkit-inner-spin-button,
      &[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
      }
    }

    .SideRender {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 22px;
      right: 0;
      column-gap: 12px;
      right: 16px;

      .IconWrapper {
        height: 18px;
        width: 18px;

        .Icon {
          right: 18px;
          fill: var(--color-burger-line);
          stroke: var(--color-burger-line);

          &.IconWithClickHandler {
            cursor: pointer;
          }
        }
      }
    }
  }

  .Example {
    margin-top: 8px;
    margin-left: 16px;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-m8);
  }

  .SideContent {
    position: absolute;
    top: 16px;
    right: -60px;
  }

  @media (max-width: 456px) {
    &[data-has-side-render="true"] {
      padding-right: 40px;
    }

    .SideContent {
      right: -22px;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

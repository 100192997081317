.Wrapper {
  display: flex;
  min-height: 100vh;

  .Content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    width: 50%;
    padding: 24px 0px;
    min-height: 100vh;


  }

  .Banner {
    width: 50%;
    height: 100vh;
    position: relative;

    background: var(--background-magic2);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;


    .RoundDown {
      position: absolute;
      top: 0;
      left: -26px;
    }


    .RoundUp {
      position: absolute;
      bottom: 0;
      left: -76px;
    }

    .RoundLeft {
      position: absolute;
      top: 70px;
      right: 0;
    }


    .Front {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      height: inherit;
      width: 100%;
      border: none;

    }
  }
}

@media (max-width: 1650px) {
  .Wrapper {
    .Banner {
      width: 50%;

      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
    }
  }
}

@media (max-width: 800px) {
  .Wrapper {
    min-height: auto;

    .Banner {
      display: none;
    }

    .Content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

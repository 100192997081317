.Header {
  display: grid;
  grid-template-columns: 0.9fr 1fr 100px;
  align-items: center;
  justify-content: space-between;
  padding: 12px 64px;
  background: var(--color-header-bg-my-pages);

  @media (max-width: 876px) {
    display: none;
  }
}

.Root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--color-m1);
  border-radius: 16px;
  max-height: 355px;

  .Tabs {
    display: flex;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    .Tab {
      display: flex;
      width: 50%;
      justify-content: center;
      background: var(--color-m1);
      border-radius: inherit;

      &.IsTabActive {
        background: #282f39;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;

        & > .Inner > .Span {
          color: var(--color-secondary);
        }

        & > .Inner > .Icon {
          fill: var(--color-secondary);
          color: var(--color-secondary);
        }
      }

      .Inner {
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 51px;
        padding: 16px 10px;
        cursor: pointer;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        width: 100%;

        .Span {
          color: var(--color-m5);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 19px;
          margin-left: 8px;
        }

        .Icon {
          margin-top: 15px;
          fill: var(--color-m5);
          color: var(--color-m5);
        }
      }
    }
  }

  .InnerCardContainer{
    background: #282f39;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;

    &.IsRightActive {
      border-top-left-radius: 16px;
      border-top-right-radius: 0;
    }
  }
}

.Root {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  border-radius: 10rem;
  border: 2px solid rgba(255, 255, 255, 0.24);
  background: rgba(255, 255, 255, 0.00);
  height: 5rem;
  width: 100%;
  cursor: pointer;


  .Par {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    color: var(--color-d9-input);
    margin: 0;
  }

  .Input {
    display: none;
  }
}

.Wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 46px;
  opacity: 0.8;
  padding: 0 8px;
  background: var(--color-block-control-bg);
  border-radius: 8px;
  border: 1px solid var(--color-block-control-bg);

  &[data-dragging="true"] {
    border-color: var(--color-block-control-border);
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.8);

    .Info {
      .DragPad {
        .Line {
          background: var(--color-secondary-lightest);
        }
      }
    }

    .Controls {
      display: flex;

      .Icon {
        svg {
          stroke: var(--color-secondary-lightest) !important;
          opacity: 0.15;
        }
      }
    }
  }

  .Info {
    display: flex;
    align-items: center;

    .DragPad {
      height: 10px;
      width: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: grab;

      .Line {
        width: 100%;
        height: 1px;
        background: var(--color-drag-pad-inactive);
        opacity: 0.4;
      }
    }

    .Title {
      font-size: 12px;
      color: var(--color-drag-pad-inactive);
      margin-left: 10px;
    }

    .Subtitle {
      font-size: 12px;
      color: var(--color-drag-pad-inactive);
      margin-left: 24px;
      opacity: 0.6;
    }
  }

  .Options {
    display: none;
  }

  .Controls {
    display: flex;

    & > * + * {
      margin-left: 8px;
    }

    .Icon {
      &.Trash {
        svg {
          stroke: var(--color-a6-coral-err);
        }
      }
    }
  }

  @media (max-width: 670px) {
    .Controls {
      display: none;
    }

    .Options {
      display: flex;

      svg {
        fill: var(--color-m4);
        stroke: var(--color-m4);
      }
    }
  }
}

%font-base {
  font-family: "Noto Sans";
  font-style: normal;
}

.Card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 24px 24px 24px 20px;
  height: inherit;
  width: 100%;
  color: var(--color-secondary-lightest);
  border-radius: 16px;

  .InnerCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .InnerTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &.Small {
    min-height: 524px;

    .Text {
      margin-bottom: 0;
    }
  }

  &.Base {
    background: linear-gradient(135.23deg, #e97059 0%, #b10edb 99.95%);
    box-shadow: 0 50px 40px -40px rgba(169, 25, 228, 0.51);

    .Btn {
      color: var(--color-blue-primary);
    }
  }

  &.Standard {
    background: linear-gradient(135.23deg, #1a77ff 0%, #4791dc 99.95%);
    box-shadow: 0 50px 40px -40px rgba(51, 161, 231, 0.5);

    .Btn {
      color: #e80fe9;
    }
  }

  &.Premium {
    background: linear-gradient(135.23deg, #9136fa 0%, #b10edb 99.95%);
    box-shadow: 0 50px 40px -40px rgba(169, 25, 228, 0.51);

    .Btn {
      color: #a40beb;
    }
  }

  .Top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px; //42px

    .Title {
      font-size: 24px;
      font-weight: 700;
      font-family: "Montserrat", sans-serif;
      color: var(--color-text-primary);
      margin: 0;
      text-transform: uppercase;
      width: 25rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .NumBack {
    position: relative;

    .Wrapper {
      display: flex;

      .Top {
        font-size: 42px;
        font-weight: 700;
        font-family: "Montserrat", sans-serif;
        color: var(--color-text-primary);
        margin: 0;
      }

      span {
        font-size: 14px;
        font-weight: 400;
        font-family: "Montserrat", sans-serif;
        color: var(--color-text-primary);
        margin-top: 20px;
        margin-left: 9px;
      }
    }

    .Back {
      position: absolute;
      top: -80%;
      left: -4%;
      font-size: 96px;
      font-weight: 700;
      color: var(--color-text-primary);
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.16) 20.51%,
        rgba(255, 255, 255, 0) 79.49%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }

  .Periods {
    padding: 0;
    margin: 8px 0 16px;
    z-index: 10;
    font-size: 14px;
    font-weight: 400;

    li {
      display: inline-block;
      cursor: pointer;
      color: var(--color-text-primary);
      opacity: 0.5;
      transition: opacity var(--animation-transition);

      &[data-is-active="true"] {
        opacity: 1 !important;
      }

      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  .Video {
    height: 170px;
    border-radius: 16px;
    background: #d9d9d9;
    margin-bottom: 28px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: inherit;
    }
  }

  .Text {
    font-size: 14px;
    font-weight: 400;
    color: #f2f2f2;
    margin: 0;
    margin-bottom: 15px;
  }

  .Lines {
    display: flex;
    flex-direction: column;
    column-gap: 13px;
  }

  .Footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 26px;

    .SelectedPlan {
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      cursor: pointer;
      padding: 12px 20px;

      &:hover {
        opacity: 0.6;
        text-decoration: underline;
      }
    }

    .Btn {
      font-weight: 600;
    }
  }

  @media (max-width: 876px) {
    height: unset;

    &.Small {
      .Footer {
        flex-direction: column;
        gap: 10px;

        .Btn {
          margin-bottom: 12px;
        }
      }
    }

    .Top {
      margin-bottom: 24px;

      .Title {
        font-size: 20px;
      }
    }

    .NumBack {
      .Wrapper {
        .Top {
          font-size: 28px;
        }
      }

      .Back {
        font-size: 64px;
        top: -100%;
        left: -6%;
      }
    }

    .Periods {
      li {
        font-size: 12px;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }

    .Video {
      min-height: 104px;
      margin-bottom: 16px;
    }
  }
}

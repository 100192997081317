.Nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .Right {
    display: flex;
    align-items: center;

    & > * + * {
      margin-left: 32px;
    }
  }
}

.Menu {
  position: relative;

  .Btn {
    width: 206px;
    padding: 12px 24px;
    background: var(--color-blue-primary);
    border-radius: 8px 8px 0px 0px;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: var(--color-secondary-lightest);
    border-style: none;

    .Icon {
      margin-left: 12px;
      fill: var(--color-secondary-lightest);
      stroke: var(--color-secondary-lightest);
      transform: rotate(180deg);

      &.IconUp {
        transform: rotate(0);
      }
    }
  }

  .Context {
    position: absolute;
    z-index: 10;
    left: 0;
    top: 40px;
    width: 100%;
    padding: 20px 24px;
    background: rgba(30, 30, 36, 0.89);
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.16);
    backdrop-filter: blur(8px);
    border-radius: 0px 0px 8px 8px;
    margin: 0;

    & > * + * {
      margin-top: 16px;
    }

    .Item {
      list-style: none;
      font-size: 14px;
      line-height: 18px;
      color: var(--color-secondary-lightest);
      cursor: pointer;

      &:hover,
      &:focus {
        color: var(--color-m8);
      }
    }
  }

  @media (max-width: 552px) {
    .Btn {
      width: 132px;
      padding: 12px 10px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .Icon {
        margin: 0;
      }
    }
  }
}

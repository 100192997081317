.Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  max-width: 9.1rem;
  width: fit-content;
  border-radius: 8px;
  background: var(--color-m1);
  gap: 4px;

  .Par {
    margin: 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: var(--color-text-primary);
    white-space: nowrap;
  }

  .IconClose {
    cursor: pointer;
  }
}

.Btn {
  padding: 0;
  border: 1px solid var(--color-block-control-border);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-block-control-bg);
  cursor: pointer;

  &[data-has-text="true"] {
    padding: 0 8px;
  }

  &:disabled {
    opacity: 0.3;
    cursor: default;
  }

  .Icon {
    fill: var(--color-m9);
    stroke: var(--color-m9);
  }

  .Text {
    color: var(--color-m7);
    font-size: 10px;
    line-height: 14px;
    margin-left: 8px;
    display: block;
    padding-right: 10px;
  }
}

.Burger {
  height: 12px;
  width: 18px;
  display: flex;
  background: transparent;
  border-style: none;
  border: 2px solid var(--color-burger-line);
  border-left-style: none;
  border-right-style: none;
  cursor: pointer;
}

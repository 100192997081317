.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .Title {
    color: var(--color-secondary-lightest);
    font-family: var(--font-h4);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  .Sub {
    font-family: var(--font-primary);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    color: var(--color-m7);
    margin-bottom: 32px;

  }

  .ButtonContainer {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 16px;
  }
}

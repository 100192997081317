.Text {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--color-m9);
}

.Time {
  margin-left: 8px;
  color: var(--color-m7);
}

.Entry {
  display: flex;
  align-items: center;
  column-gap: 10px;

  .Error {
    color: var(--color-red);
  }

  .Check {
    fill: transparent;
    stroke: var(--color-transaction-input);
  }
}

@media (max-width: 776px) {
  .Table {
    display: none;
  }
}

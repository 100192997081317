.Title {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  color: var(--color-secondary-lightest);
  margin-top: 0;
  margin-bottom: 32px;
}

.Text {
  font-size: 14px;
  line-height: 19px;
  color: var(--color-m9);
  margin-top: 0;
  margin-bottom: 16px;
}

.InfoText{
  font-size: 12px;
  line-height: 16px;
  color: var(--color-m3);
  margin-top: 0;
  margin-bottom: 16px;
}

.FormFields {
  margin-bottom: 32px;

  & > * + * {
    margin-top: 16px;
  }
}

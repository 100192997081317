.CardMedium {
  flex: 1;
  min-height: 160px;
  border-radius: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  position: relative;
  overflow: hidden;
  display: flex;

  &[data-back="persian"] {
    background: var(--background-persian);
    box-shadow: 0 50px 40px -40px rgba(25, 119, 255, 0.5);
  }

  &[data-back="magic"] {
    background: var(--background-magic);
    box-shadow: 0 50px 40px -40px rgba(204, 0, 184, 0.5);
  }

  &[data-back="magenta"] {
    background: var(--background-magenta);
    box-shadow: 0 50px 40px -40px rgba(253, 71, 65, 0.48);
  }

  .Num {
    margin-top: 37px;
  }

  .Line {
    font-size: 24px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    color: var(--color-text-primary);
    margin: 68px 0 0 24px;
  }

  .Link {
    position: absolute;
    right: 20px;
    bottom: 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: var(--color-secondary-lightest);
    z-index: 1;

    .LinkText {
      margin-right: 36px;
    }

    .LinkIcon {
      fill: var(--color-secondary-lightest);
    }
  }

  .BackGround {
    height: 125%;
    width: 125%;
    transform: translate(-10%, -10%);
    position: absolute;
    opacity: 0.2;
  }

  @media (max-width: 436px) {
    .Link {
      top: 50%;
      transform: translateY(-50%) scale(1.2);

      .LinkText {
        display: none;
      }
    }

    .Line {
      margin: 62px 0 0 24px;
    }
  }
}

.Cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 26px;

  @media (max-width: 1074px) {
    display: flex;
    gap: unset;
    flex-direction: column;
    padding-right: 20px;

    & > * + * {
      margin-top: 30px;
    }
  }
}

.InputWrapper {
  position: relative;
  cursor: pointer;

  .Input {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.Preview {
  width: 24px;
  height: 24px;
  position: relative;
  border-radius: 4px;

  .Img {
    border-radius: inherit;
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
    border: 1px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(1px);
    border-radius: inherit;
  }

  .CrossLine {
    content: "";
    position: absolute;
    width: 10px;
    height: 1px;
    background: #fff;
    top: 50%;
    left: 50%;

    &[data-left] {
      transform: translate(-50%, -50%) rotate(50deg);
    }

    &[data-right] {
      transform: translate(-50%, -50%) rotate(-50deg);
    }
  }
}

.Modal {
  color: var(--color-secondary-lightest);

  .Title {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    margin: 0;
    margin-bottom: 32px;
  }

  .Text {
    margin: 0;
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-secondary-light);
  }
}

.Root {
  flex: .9;
  height: 100%;
  width: 100%;

  .Title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: var(--color-m7);
    margin: 0;
  }

}

.Nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
  height: 4.6rem;

  .NavLeftContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;


    .NavLeftInner {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-right: 9rem;

      .GoBack {
        display: flex;
        align-items: center;
        column-gap: 16px;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: var(--color-d6);
        fill: transparent;
        stroke: var(--color-d6);
        stroke-width: 2px;
        cursor: pointer;
        margin-right: 16px;
      }

      .FolderNameContainer {
        display: flex;
        flex-direction: column;

        .Title {
          font-family: var(--font-h4);
          margin: 0;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px;
          text-transform: uppercase;
          white-space: nowrap;
        }

        .BackFolders{
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          opacity: 0.6;
          background: linear-gradient(140deg, rgba(255, 255, 255, 0.60) 0%, #FFF 21.57%, rgba(255, 255, 255, 0.80) 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }

  @media (max-width: 1024px){
    flex-direction: column;

    .NavLeftContainer{
      flex-direction: column;
      align-items: flex-start;

      .NavLeftInner{
        margin-bottom: 16px;
      }
    }

  }
}

@import "../../styles/_mixins.scss";

.Menu {
  position: relative;

  .Btn {
    width: 100%;
    max-width: 250px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0;
    border-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    color: var(--color-secondary-lightest);
    cursor: pointer;
  }

  .Context {
    position: absolute;
    z-index: 10;
    right: 0;
    top: 0;
    width: 180px;
    max-height: 250px;
    overflow-y: auto;
    padding: 8px 20px 20px 20px;
    background: linear-gradient(
      204.74deg,
      rgba(39, 48, 58, 0.9) 15.77%,
      rgba(45, 44, 50, 0.9) 99.27%
    );
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.16);
    backdrop-filter: blur(8px);
    border-radius: 8px;
    margin: 0;
    @include vertical-scroll-bar(
      linear-gradient(204.74deg, rgba(39, 48, 58, 0.9) 15.77%, rgba(45, 44, 50, 0.9) 99.27%)
    );

    & > * + * {
      margin-top: 16px;
    }

    .Icon {
      display: flex;
      margin-bottom: -6px;

      &[data-position="right"] {
        justify-content: flex-end;
      }

      svg {
        fill: var(--color-secondary-lightest);
      }
    }

    li {
      list-style: none;
      font-size: 14px;
      line-height: 18px;
      color: #fff;
      cursor: pointer;
      word-break: break-all;

      &[data-selected="true"] {
        cursor: default;
        color: var(--color-m8);
      }

      &:hover,
      &:focus {
        color: var(--color-m8);
      }
    }
  }
}

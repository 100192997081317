.StatGeneral {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 32px;
  max-width: 1260px;
  position: relative;

  .PeriodSelectorWrapper{
    display: block;
  }

  .Graph {
    height: 450px;
    margin-top: 32px;
  }

  .StatNotification{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--color-secondary-lightest);
    opacity: 0.3;
  }
  @media (max-width: 877px) {
    .PeriodSelectorWrapper {
      display: none;
    }
  }
}

.Stats {
  border: 1px solid rgba(91, 91, 101, 0.3);
  border-left-style: none;
  border-right-style: none;
  padding: 16px 0;

  & > * + * {
    margin-top: 16px;
  }
}

.Title {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--color-secondary-lightest);
  margin-top: 0;
  margin-bottom: 32px;
  white-space: nowrap;

  &.IsEdit {
    font-size: 15px;
  }
}

.Text {
  font-size: 14px;
  line-height: 1.357;
  color: var(--color-m9);
  margin-top: 0;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 400;
}

.FormFields {
  & > * + * {
    margin-bottom: 12px;

    //&:last-child{
    //  margin: 0;
    //}
  }

  .CheckPublishTranslation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 22rem;
    margin-bottom: 16px;

    .CheckText {
      margin: 0;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      color: var(--color-text-primary);
    }
  }

  .Interval {
    & > * {
      margin-bottom: 8px;
    }
  }

  .Times {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
  }
}

.InfoText{
  font-size: 12px;
  line-height: 1.5;
  color: var(--color-m3);
  margin-top: 0;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 400;
}

.Content {
  height: 410px;
  width: 330px;
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--color-m0);
  border: 3px solid var(--color-m2);
  backdrop-filter: blur(40px);
  border-radius: 24px;

  .Title {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
    color: var(--color-secondary-lightest);
    margin-bottom: 36px;
    padding: 0 20px;
  }

  .Img {
    object-fit: contain;
  }

  .Text {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: var(--color-secondary-lightest);
    margin: 32px 0;
    max-width: 80%;
  }
}

.TextButton {
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
    opacity: .5;
  }
}

@import "../../../../styles/_mixins.scss";

$card-height: 470px;
$panel-height: 160px;

.Card {
  height: $card-height;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 16px;

  &:hover {
    .PanelTop {
      top: 0;

      .Button {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .Copy {
    position: absolute;
    z-index: 0;
    left: 8px;
    top: 8px;
    background: rgba(2, 2, 2, 0.8);
    border-radius: 100px;
    padding: 8px 16px;
    display: flex;
    align-items: center;

    .CopyIndicator {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 8px;
      background: #1ed731;
    }

    .CopyText {
      margin: 0;
      color: var(--color-secondary-lightest);
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
    }
  }

  .Image {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
    filter: blur(3px);
    padding: 4px;
  }

  .Panel {
    position: absolute;
    left: 0;
    width: 100%;
  }

  .PanelTop {
    top: $card-height - 160px - 16;
    bottom: 0;
    border-radius: 16px;
    background: rgba(15, 15, 19, 0.8);
    backdrop-filter: blur(16px);
    transition: top var(--animation-transition);
    display: flex;
    align-items: center;
    justify-content: center;

    .Button {
      opacity: 0;
      visibility: hidden;
      transition-property: visibility;
      transition-duration: 0s;
      transition-delay: var(--animation-transition);
    }
  }

  .PanelBottom {
    top: $card-height - 150px;
    bottom: 0;
    padding: 24px;
    padding-top: 0;
    border-radius: 0 0 16px 16px;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-secondary-lightest);

    .PanelInner {
      align-self: flex-end;
      height: $panel-height;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .Header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .Title {
        word-break: break-all;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        margin: 0 16px 16px 0;
        height: 44px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .Menu {
        position: relative;
      }

      .Dots {
        transform: rotate(90deg);
      }

      .MenuItems {
        min-width: 200px;
        position: absolute;
        display: flex;
        flex-direction: column;
        top: -16px;
        right: -8px;
        background: linear-gradient(
          204.74deg,
          rgba(39, 48, 58, 0.9) 15.77%,
          rgba(45, 44, 50, 0.9) 99.27%
        );
        box-shadow: 0 8px 8px rgba(0, 0, 0, 0.16);
        backdrop-filter: blur(8px);
        border-radius: 8px;
        padding: 32px 24px;
        z-index: 10;

        @include gap-fallback(16px);

        & * {
          cursor: pointer;
          opacity: 0.8;
          color: var(--color-secondary-lightest);
          transition: opacity var(--animation-transition);
          text-decoration: none;

          &:hover {
            opacity: 1;
          }
        }

        .Delete {
          color: var(--color-red);
        }
      }
    }

    .Subtitle {
      margin: 0;
      font-size: 10px;
      line-height: 14px;
      color: var(--color-m8);
      margin-bottom: 7px;
    }

    .Link {
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      span {
        display: -webkit-box;
        max-width: 20rem;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }

      &:hover,
      &:active {
        .Icon {
          opacity: 1;
        }
      }

      .Icon {
        fill: transparent;
        stroke: var(--color-m8);
        opacity: 0.6;
        transition: opacity var(--animation-transition);
      }
    }
  }
}

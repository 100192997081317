.Wrapper {
  height: 38px;
  display: flex;
  flex-direction: column;
  width: 100%;

  .Label {
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.55);
    margin-bottom: 4px;

    &[data-required="true"] {
      &::after {
        content: "*";
        padding-left: 8px;
      }
    }
  }

  .Input {
    border-style: none;
    background: transparent;
    border-bottom: 1px solid #a8a5a5;
  }
}

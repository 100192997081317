.CardSmall {
  flex: 1;
  min-width: 206px;
  background: var(--gradient-grey-card);
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 15px;
  backdrop-filter: blur(20px);
  position: relative;
  overflow: hidden;
  transition: background 0.7s;

  font-family: "Noto Sans";

  &:hover {
    background: var(--color-blue-primary-hover);

    .Content {
      .IconWrapper {
        background: #fafafa;
        fill: var(--color-blue);
      }
    }
  }

  .Content {
    padding: 24px 20px 24px 24px;
    z-index: 10;

    .IconWrapper {
      width: 48px;
      height: 48px;
      margin-bottom: 24px;
      border-radius: 8px;
      background: #0168fe;
      display: flex;
      align-items: center;
      justify-content: center;
      fill: var(--color-text-primary);
      transition: background 0.7s, fill 0.7s;
    }

    .Title {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 16px;
      color: var(--color-text-primary);
    }

    .Text {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 36px;
      color: var(--color-text-primary);
      opacity: 0.8;
    }
  }

  .BackIcon {
    z-index: -1;
    position: absolute;
    top: 110px;
    right: -1%;
  }

  @media (max-width: 876px) {
    .Content {
      .Text {
        font-size: 12px;
        color: var(--color-m8);
      }
    }
  }
}

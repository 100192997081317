.Profile {
  margin-bottom: 40px;
}

.History {
  display: flex;
  align-items: center;
  margin-top: 24px;
  color: var(--color-text-gray);
  cursor: pointer;
  text-decoration: none;
  opacity: 0.8;
  transition: opacity var(--animation-transition);

  &:visited,
  &:hover,
  &:active {
    opacity: 1;
    color: var(--color-text-gray);
    text-decoration: none;
  }

  .Text {
    margin: 0 0 0 10px;
    font-weight: 600;
    font-size: 14px;
  }

  .Icon {
    fill: transparent;
    stroke: var(--color-text-gray);
  }
}

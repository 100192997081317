.Container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  border: 2px solid rgba(255, 255, 255, 0.24);
  height: 5rem;
  position: relative;
  gap: 8px;

  .Icon {
    margin-top: 3px;
    fill: var(--color-d9-input);
    cursor: pointer;
  }

  .Par {
    margin: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    color: var(--color-d9-input);
  }

  .Input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

.Root{
  display: flex;
  flex-direction: column;
}

.Link {
  font-weight: 500;
  color: var(--color-text-primary);
  text-decoration: none;
}

.CheckBox {
  margin-top: 16px !important;
  margin-left: 16px;

  .CheckBoxText {
    font-size: 12px !important;
    max-width: unset !important;
  }
}

@media (max-width: 425px) {
  .RepeatFinPassSuffix {
    display: none;
  }
}

.Btn {
  color: rgba(255, 255, 255, 0.4) !important;

  .Selected {
    .Label {
      margin-left: 8px;
      color: rgba(255, 255, 255, 0.8);
    }
  }

  .Icon {
    margin-left: 8px;
    stroke: var(--color-secondary-lightest);
    opacity: 0.3;
  }
}

.Context {
  right: -20px !important;
  top: -10px !important;
}

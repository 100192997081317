.Root {

  h1{
    margin-bottom: 10rem;
  }

  .Buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 30px;

    & > * {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

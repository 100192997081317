.Period {
  display: flex;
  align-items: center;

  @media (max-width: 875px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .Text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
    margin: 0;
    margin-right: 16px;

    &[data-big-margin="true"] {
      margin-right: 66px;
    }
  }


}

.Btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.24);
  border-radius: 100px;

  @media (max-width: 875px) {
    max-width: 172px;
  }

}

@mixin circle($size) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: $size;
  height: $size;
  border-radius: 50%;
}

.Wrapper {
  display: flex;
  align-items: center;
  position: relative;

  .Input {
    position: absolute;
    z-index: 2;
    inset: 0;
    width: 100%;
    height: 100%;
    opacity: 0;

    & + .Indicator {
      position: absolute;
      left: 0;
      height: 20px;
      width: 20px;
      background: linear-gradient(180deg, #9c9c9c 0%, #5d5d5d 100%);
      box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.1);
      border-radius: 50%;

      &::before {
        @include circle(18px);
        content: "";
        background: linear-gradient(180deg, #e8e8e8 0%, #f7f7f7 100%);
        box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.1);
      }
    }

    &:checked + .Indicator {
      &::before {
        background: linear-gradient(180deg, #686868 0%, #2d2d2d 100%);
      }

      &::after {
        @include circle(10px);
        content: "";
        background: linear-gradient(180deg, #ebebeb 0%, #ffffff 100%);
        box-shadow: -0.4px -0.4px 0.8px rgba(0, 0, 0, 0.2);
      }

      .IndicatorInner {
        @include circle(8px);
        z-index: 2;
        background: linear-gradient(180deg, #ffffff 0%, #f7f7f7 100%);
        box-shadow: -0.4px -0.4px 0.8px rgba(0, 0, 0, 0.2);
        border-radius: 50%;
      }
    }
  }

  .Label {
    margin-left: 34px;
    font-size: 16px;
    line-height: 22px;
    color: #0e0606;
  }
}

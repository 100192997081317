@import "../../../../styles/mixins";

.Root {
  display: flex;
  align-items: center;
  justify-content: center;


  @media (max-width: 876px) {
    width: 100%;
    margin-bottom: 16px;
  }
}


.CardsContainer {
  margin-top: 24px;
  height: 31rem;
  overflow-y: scroll;
  @include scroll-bar;

  &::-webkit-scrollbar {
    width: 5px;
  }


  .CardsInnerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .Icon {
      cursor: pointer;
    }
  }
}

// Noto Sans

@font-face {
  font-family: "Noto Sans";
  src: url("../assets/fonts/Noto_Sans/NotoSans-Regular.woff2") format("woff2"),
    url("../assets/fonts/Noto_Sans/NotoSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../assets/fonts/Noto_Sans/NotoSans-SemiBold.woff2") format("woff2"),
    url("../assets/fonts/Noto_Sans/NotoSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../assets/fonts/Noto_Sans/NotoSans-Bold.woff2") format("woff2"),
    url("../assets/fonts/Noto_Sans/NotoSans-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

// Open Sans

@font-face {
  font-family: "Open Sans";
  src: url("../assets/fonts/Open_Sans/OpenSans-Regular.woff2") format("woff2"),
    url("../assets/fonts/Open_Sans/OpenSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../assets/fonts/Open_Sans/OpenSans-SemiBold.woff2") format("woff2"),
    url("../assets/fonts/Open_Sans/OpenSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../assets/fonts/Open_Sans/OpenSans-Bold.woff2") format("woff2"),
    url("../assets/fonts/Open_Sans/OpenSans-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf") format("ttf"),
    url("../assets/fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf") format("ttf");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

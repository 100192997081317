.Circle {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  fill: transparent;

  &[data-type="DEPOSIT_PAYEER"] {
    transform: rotate(180deg);
    background: var(--color-transaction-input-bg);
    stroke: var(--color-transaction-input);
    box-shadow: 0 20px 40px 5px var(--color-transaction-input-bg);
  }

  &[data-type="DEPOSIT_FREE_KASSA"] {
    transform: rotate(180deg);
    background: var(--color-transaction-input-bg);
    stroke: var(--color-transaction-input);
    box-shadow: 0 20px 40px 5px var(--color-transaction-input-bg);
  }

  &[data-type="DEPOSIT_SOFTPAY"] {
    transform: rotate(180deg);
    background: var(--color-transaction-input-bg);
    stroke: var(--color-transaction-input);
    box-shadow: 0 20px 40px 5px var(--color-transaction-input-bg);
  }

  &[data-type="WITHDRAWAL_PAYEER"] {
    background: var(--color-transaction-output-bg);
    stroke: var(--color-transaction-output);
    box-shadow: 0 20px 40px 5px var(--color-transaction-output-bg);
  }

  &[data-type="WITHDRAWAL_FREE_KASSA"] {
    background: var(--color-transaction-output-bg);
    stroke: var(--color-transaction-output);
    box-shadow: 0 20px 40px 5px var(--color-transaction-output-bg);
  }

  &[data-type="PLAN_PURCHASE"] {
    background: var(--color-transaction-purchase-bg);
    stroke: var(--color-transaction-purchase);
    box-shadow: 0 20px 40px 5px var(--color-transaction-purchase-bg);
  }

  &[data-type="ADMIN_BALANCE_MODIFICATION"] {
    background: var(--color-transaction-purchase-bg);
    stroke: var(--color-transaction-purchase);
    box-shadow: 0 20px 40px 5px var(--color-transaction-purchase-bg);
  }
}

.Card {
  height: 336px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 24px;
  position: relative;

  &.CardVideo {
    &::before {
      content: "";
      position: absolute;
      z-index: 0;
      height: 336px;
      bottom: 0;
      left: 0;
      width: 100%;
      border-radius: 16px;
      background: black;
    }
  }




  &::before {
    content: "";
    position: absolute;
    z-index: 0;
    height: 294px;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 16px;
    background: linear-gradient(to top, #000000 26.29%, rgba(0, 0, 0, 0) 78.88%);
  }

  .MyVideo {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .Menu {
    align-self: flex-end;
    z-index: 2;

    .Context {
      top: -8px !important;
      right: -20px !important;

      .Delete {
        color: var(--color-a6-coral-err);
      }
    }

    svg {
      fill: white;
    }
  }

  .Content {
    position: relative;
    z-index: 1;

    .Planned {
      .Label {
        font-size: 10px;
        line-height: 14px;
        color: var(--color-m8);
        opacity: 0.7;
        margin-bottom: 6px;
      }
    }

    .Text {
      margin-top: 16px;
      margin-bottom: 0;
      height: 67px;
      overflow-y: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      font-size: 16px;
      line-height: 22px;
      color: var(--color-gray);
    }
  }
}

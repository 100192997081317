.Root {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: var(--color-m1);
  height: 48px;
  width: 100%;
  padding: 0 14px;
  justify-content: flex-start;
  margin-bottom: 4px;
  border: 1px solid var(--color-m2);
  gap: 4rem;

  &.IsSelectedRow {
    border-radius: 4px;
    border: 1px solid var(--M2-outline, #2C2C35);
    background: linear-gradient(90deg, #2C2C35 0%, #363639 25.86%, #2C2C35 100%), #1E1E24;
  }

  @media (max-width: 875px) {
    flex-direction: column;
    height: fit-content;
    padding: 16px;
    border-radius: 16px;
    align-items: flex-start;

  }

  .Col {
    flex: 1;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    @media (max-width: 875px) {
      justify-content: space-between;
      width: 100%;
    }


    .CheckboxWrap {
      display: flex;
      align-items: center;
      gap: 16px;

      @media (max-width: 875px) {
        justify-content: space-between;
        width: 100%;
      }
    }

    &:first-child {
      @media (max-width: 875px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &:last-child {
      gap: 6.2rem;
      order: 1;
      justify-content: flex-end;

      @media (max-width: 875px) {
        justify-content: space-between;

      }

    }


    .Count {
      display: flex;
      align-items: center;


      .CountNumber {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        color: var(--color-m7);
      }
    }

    .Buttons {
      display: flex;
      align-items: center;
      gap: 16px;


    }

    &:first-child {
      width: 100%;
      max-width: 45rem;
    }

    .Title {
      margin: 0;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      color: var(--color-text-primary);
      white-space: nowrap;
      max-width: 30rem;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (max-width: 875px) {
        max-width: 29rem;

      }
    }

    .SubTitle {
      margin: 0;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      color: var(--color-m7);
      max-width: 30rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media (max-width: 875px) {
        justify-content: space-between;
        padding-top: 5px;
        max-width: 20rem;

      }

    }

    .Btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 5rem;
    }


    .DeleteBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 5rem;

      svg {
        fill: var(--color-error);
      }
    }
  }
}

.Layout {
  margin: 0 auto;
  position: relative;
  display: flex;
  min-height: 100vh;

  .Navigation {
    position: sticky;
    top: 0;
    height: 100%;
    z-index: 10;
  }

  .Header,
  .MobileNav {
    display: none;
  }

  .Main {
    flex: 1;
    position: relative;
    overflow: hidden;
  }

  @media (max-width: 876px) {
    flex-direction: column;

    .Navigation {
      display: none;
    }

    .Header,
    .MobileNav {
      display: block;
    }

    .Header {
      position: sticky;
      top: 0;
      width: 100%;
      z-index: 10;
    }
  }
}

.Title {
  font-size: 24px;
  font-weight: 700;
  color: var(--color-text-primary);
  font-family: "Montserrat", sans-serif;
  margin: 0 0 32px 0;
  line-height: 30px;
  text-transform: uppercase;
}

.linkText{
  overflow-wrap: anywhere;
  color: var(--light-secondary-darker);
}

.errorMsg{
  font-size: 12px;
  color: var(--color-error);
}

.Text {
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 14px;
  color: var(--color-m9);
}

.LinksWrapper {
  margin: 16px 0 17px;

  &.InPath {
    flex-direction: row;
  }

  .Link {
    width: 171px;
    height: 78px !important;
    border-radius: 12px;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .NotActive {
    color: var(--light-secondary-darker);
  }

  .Host {
    font-size: 12px;
    font-weight: 400;
    color: var(--color-text-primary);
    margin: 0 0 24px 0;
  }
}

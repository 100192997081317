@mixin label-active() {
  font-size: 12px !important;
  top: 22px !important;
  opacity: 0.6 !important;
  transition: font-size var(--animation-label-font), top var(--animation-transition),
    opacity var(--animation-transition);
}

.Wrapper {
  padding: 16px 8px;
  position: relative;
  display: flex;
  align-items: flex-end;

  &:hover,
  &:focus {
    .Label {
      @include label-active();
    }

    .Input {
      margin-top: 22px;
      transition: margin-top var(--animation-transition);
    }
  }

  .Input {
    font-size: 16px;
    line-height: 16px;
    min-height: 16px;
    width: 100%;
    color: #d6d6d6;
    padding: 0;
    background: transparent;
    border-style: none;
    margin-top: 0;
    transition: margin-top var(--animation-transition);

    &:focus,
    &:not(:placeholder-shown) {
      outline: none;
      margin-top: 22px;
      transition: margin-top var(--animation-transition);

      & + .Label {
        @include label-active();
      }
    }
  }

  .Label {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: #a3a3a4;
    opacity: 0.8;
    font-weight: 400;
    transition: font-size var(--animation-transition), top var(--animation-transition),
      opacity var(--animation-transition);
  }
}

.Page {
  margin-bottom: 32px;
  padding: 42px 56px 110px;

  .GoBack {
    padding-top: 20px;
    position: initial;
  }

  .Title {
    display: none;
  }

  @media (max-width: 876px) {
    padding: 40px 20px;

    .GoBack {
      display: none;
    }

    .Title {
      display: block;
      margin-bottom: 24px;
    }
  }
}

.Root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 4rem;

  .CreateButtonComponent {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 875px) {
      margin-top: 30px;
    }
  }
}




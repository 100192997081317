.Wrapper {
  width: 100%;
  background: #eaeaea;
  border-radius: 26px;
  padding: 40px 118px;
  margin: 40px 0;
  position: relative;

  .List {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0;
  }
}

.Link {
  text-decoration: none;
  outline: none;
  color: var(--color-secondary-darker);
  cursor: pointer;
  margin-bottom: 16px;
  transition: color var(--animation-transition);
  align-self: flex-start;

  &:focus,
  &:hover {
    color: var(--color-secondary-light);
    text-decoration: none;
  }
}

.ModalForm {
  width: 233px;
}

.AddBotForm {
  display: flex;
  width: 280px !important;
  padding: 24px !important;
  flex-direction: column;
  align-items: center;

  .AddBotInput {
    margin-bottom: 8px;
    border-radius: 8px;
  }

  h2 {
    font-size: 20px !important;
    text-transform: uppercase;
    margin-bottom: 12px !important;
    text-align: center;
  }

  h2 + p {
    color: var(--m-8, #d0d0da) !important;
  }

  .SelectWrapper {
    height: 65px;
    position: relative;

    .SelectInfo {
      position: absolute;
      top: 11px;
      left: 16px;
      z-index: 1;
      font-size: 12px;
      line-height: 16px;
      color: #949494 !important;
    }

    span {
      display: none;
    }

    svg {
      fill: #5c5d61;
    }
  }
}

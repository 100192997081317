.Root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 4rem;


  @media (max-width: 1180px) {
    height: auto;
  }

  .Inner{
    width: 100%;

    .Table{
      width: 100%;
      margin-bottom: 20px;
    }

    .THead {

      &::after {
        content: 'Черновики';
        color: var(--color-m7);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
      }
    }


    .TBody {
      &::before {
        content: ' ';
        display: block;
        height: 15px;
      }
    }


  }
}

.MobileCreateMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}


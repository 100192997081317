.Wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  padding-top: 24px;

  .Logo {
    filter: grayscale(1);
  }

  .HelpCircle {
    fill: var(--color-d4);
  }
}

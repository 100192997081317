.Card {
  .Balance {
    display: flex;

    .Title {
      font-weight: 800;
      font-size: 32px;
      line-height: 39px;
      text-transform: uppercase;
      color: var(--color-secondary-lightest);
    }

    .Currency {
      color: var(--color-m5);
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      margin-left: 6px;
    }
  }

  .Text {
    color: var(--color-m6);
  }

  .Link {
    flex: 1;
    display: flex;
    align-items: flex-end;
  }

  @media (max-width: 496px) {
    .Balance {
      margin-bottom: 8px;

      .Title {
        font-size: 20px;
        line-height: 24px;
      }

      .Currency {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}

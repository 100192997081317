.Header {
  padding: 32px 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .Part {
    display: flex;
    align-items: center;

    & > * + * {
      margin-left: 24px;
    }

    .PublishBtn {
      height: 32px;
    }
  }

  @media (max-width: 784px) {
    display: none;
  }
}

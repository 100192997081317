.Container {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .Thumb {
    width: 94px;
    height: 94px;
  }

  .Title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color: var(--color-secondary-lightest);
    text-align: center;
    margin-bottom: 37px;
  }


  .Paragraph {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    color: var(--color-gray);
    text-align: center;
    margin: 32px 0;
  }


}

.Wrapper {
  display: flex;

  &[data-centered="true"] {
    align-items: center;
  }

  .Container {
    display: block;
    position: relative;
    height: 20px;
    width: 20px;
    margin: 0;
    cursor: pointer;
    font-size: 22px;
    user-select: none;

    .Input {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;

      &:checked {
        & ~ .CheckMark {
          background-color: var(--color-blue-primary);

          &::after {
            opacity: 1;
            transition: opacity var(--animation-transition);
          }

          &[data-type="point"] {
            background-color: var(--color-blue-primary);
            border-color: var(--color-blue-primary);
          }
        }
      }

      @media (min-width: 1074px) {
        &:not(:checked):hover {
          & ~ .CheckMark {

            &::after {
              opacity: 0.5;
            }

            &[data-type="small-circle-mobile"] {
              &::after {
                opacity: 0;
              }
            }
          }
        }

      }

    }

    .CheckMark {
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      border-radius: 4px;
      background-color: var(--color-m0);
      border: 1px solid var(--color-m6);

      &:hover {
        opacity: 0.9;
      }


      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 25%;
        width: 6px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg) translate(-50%, -50%);
        opacity: 0;
        transition: opacity var(--animation-transition);
      }

      &[data-type="circle"] {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: rgba(217, 217, 217, 0.1);
        border-style: none;

        &::after {
          left: 30%;
          height: 12px;
          width: 6px;
          border-radius: 2px;
        }
      }

      &[data-type="point"] {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: rgba(217, 217, 217, 0.1);

        &:checked,
        &:hover {
          background-color: #1e60c0;
        }

        &::after {
          position: absolute;
          width: 10px;
          height: 10px;
          box-shadow: -0.4px -0.4px 0.8px rgba(0, 0, 0, 0.2);
          border-radius: 50%;
          background-color: #40404a;
          top: 63%;
          left: 25%;
          border: none;
        }
      }

      &[data-type="blue-circle"] {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: rgba(33, 111, 224, 1);
        border: none;

        &:checked {
          background: rgba(33, 111, 224, 1);
        }

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 25%;
          width: 6px;
          height: 10px;
          transform: rotate(45deg) translate(-50%, -50%);
          opacity: 0;
          transition: opacity var(--animation-transition);
        }
      }

      &[data-type="gradient"] {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: linear-gradient(279.72deg, #6584FF 0%, #DB74FF 100%);
        border-style: none;

        &:checked {
          background: linear-gradient(279.72deg, #6584FF 0%, #DB74FF 100%);
        }

        &::after {
          left: 30%;
          height: 12px;
          width: 6px;
          border-radius: 2px;
        }
      }

      &[data-type="small-gradient"] {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: linear-gradient(279.72deg, #6584FF 0%, #DB74FF 100%);
        border-style: none;

        &:checked {
          background: linear-gradient(279.72deg, #6584FF 0%, #DB74FF 100%);
        }

        &::after {
          left: 30%;
          height: 10px;
          width: 6px;
          border-radius: 2px;
        }
      }

      &[data-type="small-circle"] {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: rgba(217, 217, 217, 0.1);
        border-style: none;

        &::after {
          left: 30%;
          height: 10px;
          width: 6px;
        }
      }

      &[data-type="mobile-blue-point"] {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: rgba(33, 111, 224, 1);
        border: none;

        &::after {
          display: none;
        }
      }

      &[data-type="small-circle-mobile"] {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: rgba(217, 217, 217, 0.1);
        border-style: none;

        &::after {
          left: 30%;
          height: 10px;
          width: 6px;
        }
      }
    }
  }

  .Text {
    margin: 0 0 0 26px;
    font-family: var(--font-primary);
    font-size: 16px;
    font-weight: 400;
    color: var(--color-m8);
    word-wrap: break-word;
    max-width: 187px;
  }
}

.Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  .Text {
    color: var(--color-secondary-darker);
  }

  .Btn {
    background: transparent;
    border-style: none;
    color: var(--color-blue-primary);
    cursor: pointer;
    font-weight: 600;
  }
}

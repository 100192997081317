.Main {
  max-width: 184px;

  &.None {
    background: var(--color-m2);
  }

  .Root {
    display: flex;
    flex-direction: column;
    max-width: inherit;
    border-radius: 16px;
    background: var(--color-m2);
    width: 18.4rem;
    height: 13.7rem;
    padding: 16px;
    cursor: pointer;

    &:hover {
      opacity: .5;
    }


    &.Parsing {
      background: var(--color-m1);
    }

    .FolderState {
      display: flex;
      align-items: center;

      .FolderIcon {
        margin-right: 10px;
      }

      .ParsingState {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin: 0;
        color: var(--color-text-primary);
      }
    }

    .FolderInfo {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .FolderName {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        color: var(--color-text-primary);
        margin: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 152px;
      }

      .FolderGroupPar {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin: 0;
        color: var(--color-m6);
      }
    }
  }
}


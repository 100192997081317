.Nav {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  background: #d9d9d9;

  & > * + * {
    margin-left: 32px;
  }

  .Tab {
    background: transparent;
    padding: 0;
    border-style: none;
    font-size: 16px;
    cursor: pointer;
    color: var(--color-secondary-darkest);
    transition: color var(--animation-transition);

    &:hover,
    &:focus,
    &[data-active="true"] {
      color: var(--color-secondary-darker);
      transition: color var(--animation-transition);
    }
  }
}

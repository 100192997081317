.Wrapper {
  width: 392px;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid var(--m-2-outline, #2c2c35);
  background: var(--m-1, #1e1e24);
  color: var(--color-secondary-lightest);
  margin-bottom: 8px;

  @media (min-width: 876px) {
    display: none;
  }

  @media (max-width: 500px) {
    width: 90%;
  }

  .Flex {
    display: flex;
    justify-content: space-between;

    .AvatarWrapper {
      display: flex !important;
      align-items: center;

      .Image {
        border-radius: 50%;
        width: 40px;
        height: 40px;
      }

      h3 {
        padding-left: 8px;
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        margin: auto;
      }
    }

    .Settings {
      padding: 7px 16px !important;
    }
  }

  .InputsWrapper {
    margin-bottom: 32px;

    :first-child div {
      margin-bottom: 8px;
    }

    :last-child div {
      background-color: var(--color-m0);

      &[data-disabled="true"] {
        label {
          color: var(--color-secondary-darker) !important;
        }

        input {
          color: var(--color-gray) !important;
        }
      }
    }
  }
}

.Root {
  display: flex;
  flex-direction: row;
  gap: 20px;

  .ErrorPar{
    color: red;
  }

  .Buttons{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
    width: 100%;

    & > * {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .StringButton{
      color: var(--color-transaction-output);
    }
  }
}

.Root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background: linear-gradient(90deg, #2C2C35 0%, #363639 25.86%, #2C2C35 100%), #1E1E24;
  height: 48px;
  padding: 0 14px;
  margin-bottom: 10px;

  .InnerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .Checkbox {
      display: flex;
      align-items: center;

      .Title {
        color: var(--color-m7);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        margin-left: 10px;
      }
    }

    .Btn {
      color: var(--color-error);
    }
  }


}

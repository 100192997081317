
.Wrapper {
  display: flex;
  align-items: center;
  gap: 10px;

  h1{
    font-size: 17px;
    color: var(--color-secondary-lightest);
    font-weight: bold;
  }

  .Root {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 876px) {
    margin-bottom: 50px;
    justify-content: center;
  }
}

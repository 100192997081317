.Doc {
  width: 100%;
  min-height: 100vh;
  background: var(--dark-background);

  .Preview {
    max-width: 428px;
    margin: 0 auto;
    height: 100%;
  }
}

.Page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 52px 64px;

  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    padding: 18px 32px;
  }

  .Content {
    width: 100%;
    max-width: 500px;

    &[data-short="true"] {
      max-width: 360px;
    }

    .Title {
      margin: 0 0 20px 0;

      &[data-no-description="true"] {
        margin-bottom: 32px;
      }
    }

    .Text {
      margin: 0 0 20px 0;
      font-size: 14px;
      line-height: 19px;
      color: var(--color-m9);
    }

    .Form {
      .FormFields {
        & > * + * {
          margin-top: 8px;
        }
      }

      .ButtonWrapper {
        display: flex;
        justify-content: center;
        margin-top: 56px;

        .Button {
          display: flex;
          justify-content: center;
          width: 184px;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    min-height: unset;
    padding: 40px 20px 90px;

    .Header {
      padding: 24px 0;
    }
  }

  @media (max-width: 876px) {
    padding-top: 0;

    .Header {
      height: unset;
      max-width: 500px;

      &[data-short="true"] {
        max-width: 360px;
      }

      .GoBack {
        display: none;
      }
    }

    .Content {
      .Form {
        .FormFields {
          article {
            width: 100%;
            max-width: unset;

            h3 {
              height: unset;
            }
          }
        }

        .ButtonWrapper {
          display: block;
          margin-top: 32px;

          .Button {
            width: 100%;
          }
        }
      }
    }
  }
}

.Accordion {
  border-bottom: 1px solid var(--color-m2);

  .Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 16px;
    padding-bottom: 12px;

    &[data-open="true"] {
      .Trigger {
        .Icon {
          transform: rotate(0);
          transition: transform var(--animation-transition);
        }
      }
    }

    .Wrapper {
      display: flex;

      .Info {
        .FullName {
          font-size: 14px;
          line-height: 19px;
          color: var(--color-m9);
          margin-bottom: 4px;
        }

        .Created {
          div {
            line-height: 16px;
            color: #70707c;
          }
        }
      }
    }

    .Trigger {
      display: flex;
      align-items: center;
      margin-left: 18px;

      .Label {
        margin-right: 12px;
        font-size: 12px;
        line-height: 16px;
        color: var(--color-m4);
      }

      .Icon {
        fill: var(--color-m4);
        transform: rotate(-180deg);
        transition: transform var(--animation-transition);
      }
    }
  }

  .Content {
    padding-bottom: 16px;

    & > * + * {
      margin-top: 16px;
    }

    .Field {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .ValueWrapper{
        display: flex;
        align-items: center;
        column-gap: 8px;
        .Value {
          font-size: 14px;
          line-height: 19px;
          color: var(--color-m9);
          margin-bottom: 0 !important;
        }
      }

      .CopyIcon {
        stroke: var(--color-m4);
      }
    }
  }
}

.svgIcon{
  width: 24px;
  height: 24px;
}

.Section {
  max-width: 744px;
  margin-bottom: 64px;

  .Title {
    margin-bottom: 24px;
  }

  .Grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
  }

  @media (max-width: 600px) {
    max-width: unset;
    margin-bottom: 40px;

    .Grid {
      display: flex;
      flex-direction: column;
      gap: unset;

      & > * + * {
        margin-top: 16px;
      }
    }
  }
}

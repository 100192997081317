.Information {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  .Left {
    .Name {
      color: var(--color-m9);
      font-size: 12px;
      font-weight: 400;
      margin: 0;
    }
  }

  .Right {
    display: flex;
    align-items: center;
    justify-content: center;

    .Size {
      font-size: 10px;
      font-weight: 400;
      color: #8a8a98;
      margin: 0;
    }

    .Icon {
      z-index: 100;
      margin-left: 5px;
    }
  }

  &.MaxLimit {
    .Name,
    .Size {
      color: #fd4438;
    }
  }
}

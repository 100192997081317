.Section {
  .List {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 391px;
    gap: 24px;

    @media (max-width: 5000px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 1920px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 1314px) {
      display: flex;
      flex-direction: column;
      gap: unset;

      & > * + * {
        margin-top: 24px;
      }
    }

    @media (max-width: 876px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 430px;
      gap: 24px;

      & > * + * {
        margin-top: 0;
      }
    }

    @media (max-width: 716px) {
      display: flex;
      flex-direction: column;
      gap: unset;

      & > * + * {
        margin-top: 24px;
      }
    }
  }
}

.Card {
  padding: 16px;
  padding-right: 32px;
  background: rgba(63, 63, 63, 0.5);
  backdrop-filter: blur(23px);
  border-radius: 16px;
  display: flex;
  position: relative;

  .Indicator {
    margin-top: 14px;
    margin-right: 16px;
  }

  .Content {
    .Name {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: var(--color-drag-pad-inactive);
      margin-bottom: 8px;
    }

    .Time {
      margin-bottom: 24px;
      display: inline-block;
    }
  }

  .Menu {
    position: absolute;
    top: 20px;
    right: 8px;

    .Context {
      top: -8px !important;
      right: -20px !important;

      .Delete {
        color: var(--color-a6-coral-err);
      }
    }

    svg {
      fill: var(--color-gray-darker);
    }
  }
}

.List {
  padding-left: 0;

  & > * + * {
    margin-top: 20px;
  }

  .Item {
    list-style: none;

    .Option {
      display: flex;
      align-items: center;
      position: relative;

      &.Active,
      &:hover,
      &:focus {
        text-decoration: none;

        .Icon {
          fill: var(--color-secondary-lightest);
          transition: fill var(--animation-transition);
        }

        .Name {
          color: var(--color-secondary-lightest);
          transition: color var(--animation-transition);
        }
      }

      &.Active {
        &::before {
          content: "";
          position: absolute;
          top: -50%;
          left: -20px;
          width: 4px;
          height: 40px;
          background: #0168fe;
          box-shadow: 6px 0px 20px #0168fe;
          border-radius: 4px 0 0 4px;
        }
      }

      .Icon {
        fill: var(--color-m4);
        margin-right: 16px;
        transition: fill var(--animation-transition);
      }

      .Name {
        color: var(--color-m4);
        font-size: 16px;
        line-height: 20px;
        transition: color var(--animation-transition);
      }
    }
  }
}

%bottom-border {
  border-bottom: 1px solid var(--color-secondary-lightest-opace);
}

.PartnerCardWrapper {
  border-radius: 16px;
  padding: 3px;
  display: flex;
  // overflow: hidden;

  &.Selected {
    background: linear-gradient(
      260deg,
      rgba(255, 255, 255, 0.58) 0%,
      rgba(255, 255, 255, 0) 100%,
      rgba(255, 255, 255, 0.54) 100%
    );
  }

  .PartnerCard {
    width: 100%;
    position: relative;
    border-radius: 16px;
    background: linear-gradient(204.74deg, #27303a 15.77%, #2d2c32 99.27%);

    .Checkbox {
      position: absolute;
      right: 24px;
      top: 16px;
      border-radius: 50%;
    }

    .Content {
      padding: 24px;

      .Top {
        @extend %bottom-border;
        display: flex;
        align-items: center;
        column-gap: 16px;
        height: 128px;
        margin-bottom: 16px;

        .Vertical {
          .NameBlock {
            display: flex;
            column-gap: 8px;
            svg {
              cursor: pointer;
            }
            .FullName {
              font-size: 16px;
              font-weight: 600;
              color: var(--color-text-primary);
            }
          }

          .Btn {
            min-width: 101px;
            height: 32px;
            border-radius: 100px;
            font-size: 14px;
            font-weight: 600;
            color: rgba(255, 255, 255, 0.8);
            border: 1px solid rgba(255, 255, 255, 0.24);
            display: flex;
            align-items: center !important;
          }
        }
      }

      .Middle {
        @extend %bottom-border;
        display: flex;
        flex-direction: column;

        .MiddleTitle {
          font-size: 12px;
          font-weight: 400;
          color: var(--color-text-primary);
          opacity: 0.7;
          margin-bottom: 8px;
        }

        .Partners {
          display: flex;
          column-gap: 8px;
          margin-bottom: 16px;

          .Block {
            flex: 1;
            padding: 4px 8px;
            display: flex;
            flex-direction: column;
            height: 63px;
            border-radius: 9px;
            overflow: hidden;
            background-color: rgba(0, 0, 0, 0.2);

            .Text {
              max-width: 100%;
              flex: 1;
              font-size: 12px;
              line-height: 16px;
              font-weight: 400;
              color: var(--color-secondary-lightest);
              opacity: 0.7;
              margin: 8px 0 4px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .Number {
              font-size: 20px;
              font-weight: 700;
              color: var(--color-text-primary);
              margin: 0;
            }
          }
        }
      }

      .Bottom {
        margin-top: 16px;

        .Tariff {
          width: 100%;
          height: 45px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 8px;
          padding-left: 16px;
          padding-right: 8px;

          .Name {
            font-size: 14px;
            font-weight: 400;
            color: var(--color-secondary-light);
            margin: 0;
          }

          .Info {
            display: flex;
            align-items: center;

            .Type {
              text-transform: uppercase;
              font-size: 16px;
              font-weight: 700;
              color: var(--color-secondary-light);
              margin-right: 16px;
            }
          }
        }

        .PlanEnd {
          text-align: center;
          font-weight: 400;
          font-size: 12px;
          color: #dbdbdb;
          opacity: 0.7;
          margin: 0;
        }
      }

      .NoTariff {
        margin-top: 16px;
        background: var(--color-m3);
        border: 1px solid var(--color-m4);
        border-radius: 8px;
        width: 100%;
        height: 45px;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: var(--color-secondary-light);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  @media (max-width: 876px) {
    padding: 1px;

    .PartnerCard {
      .Checkbox {
        transform: scale(0.75);
      }

      .Content {
        .Top {
          flex-direction: column;
          height: unset;

          .Avatar {
            margin-top: -8px;
            margin-bottom: 8px;
            transform: scale(0.8);
          }

          .Vertical {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 16px;

            .FullName {
              max-width: unset;
              margin-bottom: 16px;
              text-align: center;
              margin-right: 0;
            }
          }
        }

        .Middle {
          .MiddleTitle {
            margin-bottom: 16px;
            opacity: 0.4;
          }

          .Partners {
            flex-direction: column;
            column-gap: unset;

            & > * + * {
              margin-top: 16px;
            }

            .Block {
              flex-direction: row;
              background: transparent;
              padding: 0;

              .Text {
                margin: 0;
                opacity: 0.7;
              }

              .Number {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

.InfoModal {
  max-width: 420px !important;
  .ModalContent {
    p {
      margin: 0;
    }
    .InfoTitle {
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      color: var(--color-text-primary);
    }
    .InfoNameBlock {
      margin: 16px 0;
      .InfoBlockTitle{
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: rgba(148, 148, 148, 1);
      }
      .InfoName {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: rgba(208, 208, 208, 1);
      }
    }
    .LoginInfoBlock {
      .InfoBlockTitle{
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: rgba(148, 148, 148, 1);
      }
      .LoginInfo {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: rgba(208, 208, 208, 1);
      }
    }
    .TelegramBlock {
      margin-top: 16px;
      padding: 8px 16px;
      border-radius: 8px;
      background: rgba(10, 10, 10, 1);
      width: 368px;
      display: flex;
      align-items: center;
      column-gap: 24px;
      .TelegramInfoBlock {
        .TelegramInfo {
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          color: rgba(208, 208, 208, 1);
          text-decoration: none;
        }
        .TelegramInfoTitle {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }
      }
      .CopyIcon {
        margin-left: auto;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 876px) {
  .InfoModal {
    max-width: 380px !important;
    .ModalContent {
      .TelegramBlock {
        width: 340px;
      }
    }
  }
}

@media (max-width: 540px) {
  .InfoModal {
    max-width: 300px !important;
    .ModalContent {
      .InfoTitle {
        font-size: 16px;
        line-height: 20px;
      }
      .InfoNameBlock {
        margin: 14px 0;
        .InfoName {
          font-size: 16px;
          line-height: 22px;
        }
      }
      .LoginInfoBlock {
        .LoginInfo {
          font-size: 16px;
          line-height: 22px;
        }
      }
      .TelegramBlock {
        margin-top: 14px;
        padding: 6px 12px;
        width: 258px;
        column-gap: 20px;
        .TelegramInfoBlock {
          .TelegramInfo {
            font-size: 14px;
            line-height: 18px;
          }
          .TelegramInfoTitle {
            font-size: 10px;
            line-height: 14px;
          }
        }
      }
    }
  }
}

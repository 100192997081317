.MobileMenu {
  display: none;
}

.MenuBtn {
  opacity: 0.6;
}

.IconDown {
  margin-left: 10px;
  stroke: var(--color-secondary-lightest);
}

.MenuContext {
  top: -12px !important;
  right: -24px !important;
}

@media (max-width: 380px) {
  .DesktopMenu {
    display: none;
  }

  .MobileMenu {
    display: block;

    &[data-has-input="true"] {
      padding-bottom: 14px;
    }

    .MenuBtn {
      opacity: 1;
    }
  }
}

.DataMobile {
  display: none;
}

@media (max-width: 1062px) {
  .DataDesktop {
    display: none;
  }

  .DataMobile {
    display: block;
  }
}

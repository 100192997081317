.Root {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 24px;

  .Grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;

    @media (max-width: 1092px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 1092px) {
      grid-template-columns: repeat(1, 1fr);
    }

  }

  @media (max-width: 1360px) {
    display: flex;
    flex-direction: column;
    gap: unset;
  }
}

.Card {
  padding: 36px 7px 32px;
  border: 3px solid #2c2c35;
  backdrop-filter: blur(40px);
  background: var(--dark-background);
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  .Title {
    font-weight: 700;
    font-size: 24px;
  }

  .Text {
    text-align: center;
    color: var(--color-text-primary);

    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
    width: 315px;
  }

  .Btn {
    width: 112px;
    height: 48px;
    border-radius: 100px;
    background-color: var(--color-blue-primary) !important;
    font-size: 16px;
    font-weight: 500;
  }

  .Alternatives {
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    & > * + * {
      margin-top: 56px;
    }
  }
}

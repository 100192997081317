.IconActive {
  fill: var(--color-blue-active) !important;
  opacity: 0.5;
}

.ImagesCount {
  position: absolute;
  top: -40%;
  left: -50%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--color-secondary-lightest);
  color: var(--color-blue-primary);
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Header {
  display: flex;
  justify-content: space-between;

  @media (max-width: 876px) {
    width: 100%;
  }

  .Title {
    margin: 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-transform: uppercase;
    background: linear-gradient(
      90.43deg,
      rgba(255, 255, 255, 0.6) 0%,
      #ffffff 18.07%,
      rgba(255, 255, 255, 0.8) 83.79%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    @media (max-width: 876px) {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
    }
  }

  .Wrapper{
    margin-right: 10px;
  }

  .HeaderLeftPart {
    display: flex;

    .Subtitle {
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: var(--color-secondary-lightest);
      background-clip: text;
      opacity: 0.6;
    }
    .Link {
      margin-right: 24px;
      .Left {
        transform: rotate(180deg);
        fill: #535455;
        cursor: pointer;
      }
    }
  }
  @media (max-width: 876px) {
    .Element {
      display: none;
    }
  }
}

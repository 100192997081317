.Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .Photo {
    background: #979797;
    object-fit: cover;

    // circle
    &[data-shape="0"] {
      width: 86px;
      height: 86px;
      border-radius: 50%;
    }

    // square
    &[data-shape="1"] {
      width: 86px;
      height: 86px;
      border-radius: 8px;
    }

    // rectangle
    &[data-shape="2"] {
      width: 320px;
      height: 180px;
      border-radius: 8px;
    }
  }
}

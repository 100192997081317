.Section {
  flex: 1;
  padding: 32px;

  .Wrapper {
    width: 554px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    margin-top: 58px;

    .Btn {
      width: 266px;
    }
  }
}

.Menu {
  position: absolute;
  left: calc(100% + 8px);
  bottom: 0;
  padding: 12px 16px !important;

  .DeleteIcon {
    fill: var(--color-error);
  }
}

.Wrapper {
  .Label {
    line-height: 36px;
    font-weight: 400;
  }

  .Components {
    .Group {
      display: flex;
      flex-direction: column;
      padding-left: 0;

      & > * + * {
        margin-top: 8px;
      }
    }
  }

  @media (max-width: 670px) {
    .Label {
      line-height: 20px;
    }
  }
}

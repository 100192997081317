.Root {
  display: flex;
  align-items: center;
  gap: 10px;

  .Delete {
    color: var(--color-red);
  }

  @media (max-width: 886px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 45px;

    & > * {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

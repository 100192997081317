.Form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;

  &[data-has-back="true"] {
    margin-top: 52px;
  }

  & > * + * {
    margin-top: 24px;
  }
}

.NoAccText {
  margin: 0;
  background: linear-gradient(
    90.43deg,
    rgba(255, 255, 255, 0.6) 0%,
    #ffffff 18.07%,
    rgba(255, 255, 255, 0.8) 83.79%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.Menu {
  position: relative;

  &.Open {
    .Btn {
      background: var(--color-m1);

      .Trigger {
        .Icon {
          fill: var(--color-secondary-lightest);
          transform: rotate(0deg);
        }
      }
    }
  }

  .Btn {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: transparent;
    border-style: none;
    width: 240px;

    .Name {
      font-size: 12px;
      line-height: 16px;
      text-align: right;
      color: var(--color-drag-pad-inactive);
      margin-right: 16px;
    }

    .Trigger {
      display: flex;
      align-items: center;

      .Avatar {
        margin-right: 8px;
        border-radius: 50%;
      }

      .Icon {
        fill: var(--color-burger-line);
        transform: rotate(180deg);
      }
    }
  }

  .Items {
    position: absolute;
    top: 100%;
    right: 0;
    width: 240px;
    padding: 24px;
    background: linear-gradient(
      204.74deg,
      rgba(39, 48, 58, 0.9) 15.77%,
      rgba(45, 44, 50, 0.9) 99.27%
    );
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.16);
    backdrop-filter: blur(8px);
    border-radius: 0px 0px 8px 8px;

    & > * + * {
      margin-top: 16px;
    }

    .Item {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      line-height: 19px;
      color: var(--color-m7);
      list-style: none;
      cursor: pointer;

      &[data-selected="true"] {
        color: var(--color-m9);
      }

      .Span{
        color: var(--color-a6);
        cursor: pointer;
        background: transparent;
        border: none;
      }
    }
  }

  @media (max-width: 876px) {
    margin-right: -16px;

    .Btn {
      height: 56px;
      width: unset;
      padding: 10px 1px;

      .Name {
        display: none;
      }
    }
  }
}

@media (max-width: 560px) {
  .NoAccText {
    display: none;
  }
}

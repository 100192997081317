.Page {
  padding: 40px 56px 30px 56px;

  .SelectedPartners {
    margin-right: 384px;
  }

  .Main {
    display: flex;

    .Content {
      flex: 1;
      margin-right: 24px;
    }

    .Aside {
      width: 360px;
    }
  }

  @media (max-width: 1080px) {
    padding: 20px 20px 80px;
  }

  @media (max-width: 876px) {
    .Main {
      .Content {
        margin-right: 0;
      }

      .Aside {
        display: none !important;
      }
    }
  }
}

.Pagination {
  display: flex;
}

.Wrapper {
  position: relative;
  z-index: 2;

  .Element {
    width: 30px;
    height: 30px;
    border-radius: 8px;
    background: #eaeaea;
  }

  .Picker {
    position: absolute;
    top: 40px;
    left: 0;
  }
}

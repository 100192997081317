.InputText {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: var(--image-input-hint);
  margin-bottom: 5px;

  &.Error {
    color: var(--color-a6-coral-err);
  }
}

.Selected {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  .Left {
    display: flex;
    align-items: center;

    .SendMsg {
      width: 117px;
      height: 40px;
      font-size: 16px;
      font-weight: 500;
    }

    .Cancel {
      font-size: 14px;
      font-weight: 600;
    }

    & > * + * {
      margin-left: 24px;
    }
  }

  .Right {
    flex: 1;
    display: flex;
    justify-content: center;

    .Count {
      font-size: 14px;
      font-weight: 400;
      color: var(--color-text-light);
    }
  }

  .Filters {
    display: flex;
    align-items: center;
    column-gap: 24px;

    .Button {
      display: flex;
      column-gap: 8px;
      align-items: center;
      color: var(--color-d9-input);
      background: transparent;
      border: none;
      cursor: pointer;
    }

    .Chevron {
      fill: transparent;
      stroke-width: 4px;
      stroke: var(--color-d9-input);
      transform: rotate(-90deg);
    }
  }

  @media (max-width: 876px) {
    .Left {
      .SendMsg {
        width: 88px;
        height: 32px;
        font-size: 12px;
      }

      .Cancel {
        font-size: 12px;
      }
    }

    .Right {
      display: none;
    }
  }
}

.Main {
  flex: 1;
  padding: 56px;
  margin-top: 5px;
  //margin-bottom: 25px;

  @media (max-width: 880px) {
    margin-bottom: 25px;
  }

  @media (max-width: 500px) {
    padding: 16px;
  }

  .Head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
  }
}

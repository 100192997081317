.Title {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  color: var(--color-secondary-lightest);
  margin: 0;
  margin-bottom: 32px;
}

.Text {
  font-size: 14px;
  line-height: 19px;
  color: var(--color-m9);
  margin: 0;
  margin-bottom: 16px;
}

.Label {
  margin-top: 16px;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-secondary-darker);
}

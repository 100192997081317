.Settings {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 32px 20px;
  max-width: 336px;
  margin: 0 auto;

  & > * + * {
    margin-top: 32px;
  }
}

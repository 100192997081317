.Applications {
  padding-bottom: 95px;

  .Header {
    display: flex;
    align-items: center;
    margin: 40px 0 38px;
    position: relative;
    gap: 10px;

    .Title {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      text-transform: uppercase;
      background: linear-gradient(
        90.43deg,
        rgba(255, 255, 255, 0.6) 0%,
        #ffffff 18.07%,
        rgba(255, 255, 255, 0.8) 83.79%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      margin: 0 16px 0 0;
    }

    .FilterContainer {
      padding: 16px;
    }

    .Selector {
      display: flex;
    }
    .DateSelector {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-end;
    }
    .readButton {
      color: rgba(33, 111, 224, 1);
      cursor: pointer;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
    }

    .desktopFilters {
      display: flex;
      column-gap: 16px;
      width: 100%;
      .dateSelectorWrapper {
        display: flex;
        column-gap: 24px;
        margin-left: auto;
      }
    }

    .mobileFilterWrapper {
      display: none;
    }
  }

  @media (max-width: 876px) {
    .Header {
      justify-content: space-between;

      .desktopFilters {
        display: none;
      }

      .mobileFilterWrapper {
        display: block;
      }
    }
  }

  @media (max-width: 612px) {
    .Header {
      .DateSelector {
        justify-content: center;
        margin-top: 24px;
      }

      .Selectors {
        flex-direction: column;
        row-gap: 10px;
      }
    }
  }
}

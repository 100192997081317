.Wrapper {
  position: relative;

  .Phone {
    background: var(--color-phone-bg);
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;

    &[data-mode="edit"] {
      border-radius: 30px;
      border: 4px solid var(--color-phone-border);
      box-shadow: 16px 16px 40px var(--color-phone-box-shadow);
      padding: 4px;
    }

    &[data-mode="preview"] {
      border-radius: 42px;
      border: 8px solid var(--color-phone-border-preview);
      box-shadow: 0 20px 40px var(--color-phone-box-shadow-preview);
    }
  }

  .Bang,
  .Power,
  .Mute,
  .Volume {
    position: absolute;
    z-index: 5;
    background: var(--color-phone-border-preview);
  }

  &[data-orientation="vertical"] {
    .Phone {
      max-width: 368px;
      min-width: 320px;
      width: 100%;

      &[data-mode="edit"] {
        min-height: 732px;
        height: 100%;
      }

      &[data-mode="preview"] {
        height: 732px;
      }
    }

    .Bang {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 202px;
      height: 32px;
      border-radius: 0 0 24px 24px;
    }

    .Power,
    .Mute,
    .Volume {
      width: 2.8px;
    }

    .Power,
    .Mute {
      right: -2.8px;
      border-radius: 0 6px 6px 0;
    }

    .Power {
      height: 60px;
      top: 240px;
    }

    .Mute {
      height: 42px;
      top: 152px;
    }

    .Volume {
      height: 90px;
      left: -2.8px;
      top: 138px;
    }
  }

  &[data-orientation="horizontal"] {
    .Phone {
      height: 368px;

      &[data-mode="edit"] {
        min-width: 732px;
      }

      &[data-mode="preview"] {
        width: 732px;
      }
    }

    .Bang {
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 32px;
      height: 202px;
      border-radius: 0 24px 24px 0;
    }

    .Power,
    .Mute,
    .Volume {
      height: 2.8px;
    }

    .Power,
    .Mute {
      top: -2.8px;
      border-radius: 0 6px 6px 0;
    }

    .Power {
      width: 60px;
      left: 240px;
    }

    .Mute {
      width: 42px;
      left: 152px;
    }

    .Volume {
      width: 90px;
      bottom: -2.8px;
      left: 138px;
    }
  }

  @media (max-width: 1048px) {
    grid-area: phone;
  }

  @media (max-width: 784px) {
    .Power,
    .Mute,
    .Volume,
    .Bang {
      display: none;
    }
  }
}

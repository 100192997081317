.Background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  padding: 20px;
  overflow-y: auto;
  display: grid;
  place-items: center;

  .Card {
    width: 719px;
    position: relative;
    background-color: var(--card-ext-background);
    border-radius: 16px;
    min-height: 500px;

    .Close {
      position: absolute;
      cursor: pointer;
      top: 26px;
      right: 32px;
    }
  }

  @media (max-width: 750px) {
    .Card {
      width: 288px;
      height: 100%;

      .Close {
        top: 14px;
        right: 14px;
      }
    }
  }
}

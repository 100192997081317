.Container {
  min-width: 350px;

  .Title {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    color: var(--color-m9);
    white-space: nowrap;
    text-transform: uppercase;
    margin: 0;
  }

  .FormFields {
    margin-top: 24px;

    & > * {
      margin-bottom: 12px;
    }
  }

  .Interval {
    & > * {
      margin-bottom: 8px;
    }
  }

  .Times {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
  }
}

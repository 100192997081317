.Nav {
  display: none !important;
}

@media (max-width: 876px) {
  .Header {
    display: none;
  }

  .Nav {
    display: flex !important;
  }
}

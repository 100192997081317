.Modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .Title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375;
    color: var(--color-secondary-lightest);
    margin-bottom: 12px;
  }

  .Paragraph {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    color: var(--color-gray);
  }


  .Loading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @keyframes rotateAnimation {
      0% {
        transform: rotate(360deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }

    .Icon {
      margin: 22px 0;
      animation: rotateAnimation 1s linear infinite;
      transform-origin: 50% 50%;
    }

    .loadingPar {
      color: var(--color-gray);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

  }
}

.Root {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 2px solid var(--color-m2);
  background: var(--color-m1);
  height: 3.5rem;
  padding: 8px 8px;

  .Title {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    color: var(--color-m6);
    margin-right: 5px;
    opacity: 1;
  }

}

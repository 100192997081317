.Wrapper {
  width: 100%;

  .Content {
    background: var(--bg-color, #d9d9d9);
    border-radius: 8px;
    min-height: 82px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 6px;

    & > * + * {
      margin-top: 4px;
    }
  }
}

.Wrapper {
  .Choices {
    margin-top: 4px;
    padding: 0 14px;
    display: flex;
    flex-wrap: wrap;

    & > * + * {
      margin-left: 4px;
      margin-top: 4px;
    }
  }
}

.Wrapper {
  width: 24px;
  height: 24px;

  .Btn {
    background: transparent;
    border-style: none;
    padding: 0;
    width: 24px;
    height: 24px;

    .Icon {
      fill: var(--color-secondary-darkest);
    }
  }

  .Menu {
    width: 162px;
  }
}

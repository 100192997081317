.Container {
  display: flex;

  .Par {
    margin: 0;
    margin-right: 24px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    cursor: not-allowed;

    &:first-child{
      color: var(--color-secondary-lightest);
      cursor: pointer;
    }

    @media (max-width: 875px) {
      font-size: 14px;
      line-height: 19px;
      margin: 0;
      margin-right: 20px;
    }
  }
}

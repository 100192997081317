.Root {
  padding: 12px;
  border-radius: 6px;
  background: var(--g-4-grey-card, linear-gradient(238deg, #27303A 30.74%, #2D2C32 100%));
  margin-bottom: 8px;

  .MainText {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    color: var(--color-m9);
    height: 80px;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    overflow: hidden;
  }

  .UnderWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .InfoPar {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      color: var(--color-m7);
    }
  }
}

.Nav {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 30px;
  align-items: center;
  gap: 10px;

  .SearchField {
    display: flex;
    width: 100%;
    max-width: 60rem;
    justify-content: center;
    align-items: center;

    & > input {
      width: 100%;
    }
  }

  .Title {
    margin-bottom: 0;
  }

  .Controls {
    display: flex;
    align-items: center;

    & > * + * {
      margin-left: 24px;
    }


    .BtnOutline {
      height: 48px;
    }
  }

  @media (max-width: 1360px) {
    .SearchField {
      display: none;
    }
  }

  @media (max-width: 876px) {
    .Controls {
      display: none;
    }
  }
}

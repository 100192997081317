.Btn {
  width: 100%;
  background: transparent;
  padding: 0;
  border-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 19px;
  color: var(--color-m9);
  font-weight: 400;

  .Icon {
    margin-right: 18px;
    stroke: var(--color-m7);
  }
}

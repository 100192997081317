.Wallet {
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  color: var(--color-text-primary);
  opacity: 0.8;
  transition: opacity var(--animation-transition);
  margin-right: 43px;

  &:visited,
  &:hover,
  &:active {
    opacity: 1;
    color: var(--color-text-primary);
    text-decoration: none;
  }

  .Currency {
    margin-left: 4px;
    font-size: 16px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    color: #5b5b65;
  }

  // @media (max-width: 909px) {
  //   margin-right: 20px;
  // }

  @media (max-width: 876px) {
    font-size: 14px;
    margin-right: 0;
    line-height: 20px;

    .Currency {
      font-size: 10px;
    }
  }
}

.Wrapper {
  position: relative;

  .SideIcon {
    position: absolute;
    right: -40px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.SelectInput {
  margin-bottom: 8px;
}

.Input {
  margin-bottom: 8px;
  width: 100%;
  max-width: 29rem;
}

.Inputs {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;

  .SmallerInput {
    max-width: 140px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.Buttons {
  display: flex;
  justify-content: space-between;
  gap: 8px;


  .Btn {
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 16px 24px;
    margin-top: 16px;
  }
}

.Root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 16px;
  width: 100%;
  max-width: 306px;
  padding: 3px;
  margin-bottom: 40px;
  cursor: pointer;


  .InnerRoot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background: #1E1E24;
    width: 100%;
    height: 100%;
    border-radius: inherit;

    h1 {
      font-weight: 600;
      font-size: 16px;
      color: var(--color-m9);
      font-family: 'Montserrat', sans-serif;
    }
  }
}

.Btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.Modal {
  width: 90vw;
  max-width: 700px !important;
  height: 650px !important;

  @media (max-width: 1024px) {
    height: 300px !important;
  }

  .InnerModal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

.FormClassName{
  height: 100%;

}

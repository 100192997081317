.Wrapper {
  margin: 16px 0;

  & > * + * {
    margin-top: 8px;
  }

  .Frame {
    width: 100%;
    height: 180px;
    border-radius: 8px;
    margin-top: 16px;
  }
}

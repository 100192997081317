.Page {
  margin-top: 120px;

  .Content {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;

    .Header {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 392px;
      margin: 0 auto;

      .Title {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        text-transform: uppercase;
        color: var(--color-secondary-lightest);
        margin: 0;
        margin-bottom: 24px;
      }

      .Text {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: var(--color-m8);
        opacity: 0.8;
        margin: 0;
        margin-bottom: 24px;
      }
    }

    .Grid {
      margin-top: 40px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;

      .EmptyCard {
        height: 133px;
        background: linear-gradient(
          204.74deg,
          rgba(39, 48, 58, 0.16) 15.77%,
          rgba(45, 44, 50, 0.16) 99.27%
        );
        border: 1px solid rgba(255, 255, 255, 0.06);
        backdrop-filter: blur(40px);
        border-radius: 16px;
        display: grid;
        place-items: center;
      }
    }
  }

  @media (max-width: 876px) {
    margin-top: 52px;
  }

  @media (max-width: 436px) {
    margin-top: 32px;
    padding-bottom: 100px;

    .Content {
      .Header {
        align-items: flex-start;

        .Title,
        .Text {
          text-align: left;
        }

        .Title {
          font-size: 20px;
          line-height: 30px;
        }
      }

      .Grid {
        display: flex;
        flex-direction: column;
        gap: unset;

        & > * + * {
          margin-top: 12px;
        }
      }
    }
  }
}

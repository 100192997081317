.Root {
  flex: 1;
  width: 100%;

  .Title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: var(--color-m7);
    margin: 0;
  }

  .Wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 21px;
  }
}

.Root {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  justify-content: space-between;
  margin-top: 21px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
}

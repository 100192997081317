.Wrapper {
  width: 200px;

  .Icon {
    padding-top: 8px;
    margin-bottom: -16px;
    fill: #cfcfcf;
    align-self: flex-start;
  }

  .Values {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .Card {
      display: flex;
      flex-direction: column;
      align-items: center;

      .Value {
        color: var(--color-d9-input);
        font-size: 14px;
        margin-bottom: 4px;
      }

      .Legend {
        color: var(--color-d9-input);
        opacity: 0.5;
        font-size: 10px;
      }
    }
  }
  .Input {
    width: 100%;
    margin: 10px 0;
    background: transparent;
    border: 1px solid var(--color-d9-input);
    padding: 4px;
    border-radius: 8px;
    color: var(--color-d9-input);
  }

  .BtnWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 8px;
  }
}

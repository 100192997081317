.PaginationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 288px;
  height: 32px;
  margin-top: 40px;

  .Dots {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-text-primary);
    border-radius: 50%;
    font-size: 13px;

    &:hover {
      background-color: transparent;
      cursor: default;
    }
  }

  .PaginationItem {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 1px;
    color: var(--color-text-primary);
    border-radius: 50%;
    font-size: 13px;

    &:hover {
      background-color: rgba(148, 148, 148, 0.5);
      cursor: pointer;
    }

    .Arrow {
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        border-radius: 1px;
        position: relative;
        content: "";
        width: 6px;
        height: 6px;
        border-right: 2.5px solid var(--color-text-primary);
        border-top: 2.5px solid var(--color-text-primary);
      }
    }
  }
}

.Disabled {
  pointer-events: none;
  opacity: 0.5;

  &:hover {
    background-color: transparent;
    cursor: default;
  }
}

.ArrowLeft {
  transform: rotate(-135deg);
}

.ArrowRight {
  transform: rotate(45deg);
}

.Selected {
  background-color: rgba(148, 148, 148, 0.5);
}

.Wrapper {
  background: transparent;
  width: 576px;
  padding: 0;
  border-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;

    .IconWrapper {
      background: var(--background-persian);

      .Icon {
        fill: var(--color-text-primary);
      }
    }

    .Name {
      color: var(--color-secondary-dark);
    }
  }

  .IconWrapper {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background: #2c2c35;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;

    .Icon {
      fill: var(--color-gray-second);
    }
  }

  .Name {
    font-size: 12px;
    font-weight: 400;
    color: var(--color-gray-second);
  }
}

.Root {

  &.None {
    background: var(--color-m2);
  }

  .InnerContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    background: var(--color-m1);
    border-radius: 16px;
    width: 288px;
    height: 100%;
    min-height: 178px;


    .Par {
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      color: var(--color-m5);
    }

    .DateTime {
      display: flex;
      justify-content: space-between;
      align-items: center;


    }

    .Title {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      color: var(--color-m8);
      margin: 0;
    }

    .Divider {
      border-top: 1px solid var(--color-m3);
      margin: 8px 0;
    }

    .Status {
      .StatusProgress {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        color: var(--color-m9);
      }

      .StatusState {
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        color: var(--color-m9);

        &.StatusDone {
          color: var(--color-success);
        }

        &.StatusError {
          color: var(--color-error);
        }
      }


    }

    .Buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;

      .RegBtn {
        width: 100%;
        justify-content: center;
        height: 35px;
      }

      .SetBtn {
        width: 100%;
        max-width: 5rem;
        justify-content: center;
        height: 35px;
      }

    }
  }
}

.Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .Img {
    margin-bottom: 24px;
  }

  .Text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: var(--color-m7);
    margin: 0;
  }
}

.Wrapper {
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  background: linear-gradient(
    240deg,
    rgba(249, 45, 253, 0.14),
    rgba(223, 58, 252, 0.8495),
    rgba(115, 112, 249, 0.21),
    rgba(13, 163, 246, 0.84),
    rgba(13, 163, 246, 0.19)
  );
}

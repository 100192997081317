.Avatar {
  margin-bottom: 12px;
  flex: auto;
}

.InvitorBlock {
  display: flex;
  flex-direction: column;
  align-items: center;

  .InvitorName {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #ffffff;
  }
}

.Icon {
  position: absolute;
  right: 19px;
  top: 32px;
  transform: translateY(-50%);
  fill: rgba(255, 255, 255, 1);
  z-index: 1;
  cursor: pointer;
}

.Search {
  position: relative;
}

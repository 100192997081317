.Wrapper {
  width: 100%;

  .Title {
    font-weight: 400;
    font-size: 14px;

    margin-bottom: 16px;
    color: var(--color-m9);
  }

  .Content {
    background-color: var(--background-input);
    border: 1px solid #2c2c35;
    border-radius: 12px;
    padding: 16px 38px 16px 19px;

    .Options {
      & > * + * {
        margin-top: 16px;
      }
    }
  }
}

.Wrapper {
  display: flex;
  align-items: center;
  column-gap: 6px;
  padding: 8px 16px;
  background: var(--color-m1);
  border-radius: 16px;
  color: var(--color-m7);

  &.Dimmed {
    color: var(--color-m5);
    fill: var(--color-m5);
  }

  &.Clickable {
    color: var(--color-m5);
    fill: var(--color-m5);
    cursor: pointer;
    transition: color var(--animation-transition), fill var(--animation-transition);

    &:hover,
    &:focus {
      color: var(--color-m7);
      fill: var(--color-m7);
    }
  }
}

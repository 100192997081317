.Layout {
  position: relative;

  .Content {
    padding: 32px 56px;
    max-width: 808px;
    margin-right: 344px;
  }

  .NavMobile {
    display: none;
  }

  @media (max-width: 876px) {
    .Content {
      padding: 0 20px 80px;
    }
  }

  @media (max-width: 704px) {
    .Content {
      margin-right: 0;
    }

    .NavMobile {
      display: flex;
    }
  }
}

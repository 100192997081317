.Wrapper {
  margin-bottom: 28px;

  .Row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;

    & > * + * {
      margin-left: 10px;
    }

    .Controls {
      display: flex;

      & > * + * {
        margin-left: 8px;
      }
    }

    .DeleteBtn {
      color: var(--color-error-lighter);
      font-size: 12px;
    }
  }
}

.MobileTitle {
  display: none;
}

@media (max-width: 670px) {
  .Wrapper {
    display: none;
  }

  .MobileTitle {
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-d7);
    margin-bottom: 28px;
  }
}

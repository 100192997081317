.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 42px;
  font-family: "Noto Sans";
  font-style: normal;
  color: var(--color-secondary-darkest);

  .Text {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  }

  .TextMenu {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
}

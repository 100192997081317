.Button {
  background: transparent;
  border: none;
  cursor: pointer;
  color: var(--color-m7);
  transition: color var(--animation-transition);

  &:focus,
  &:hover {
    color: var(--color-m9);
  }
}

.Page {
  .MobileHeader {
    align-items: center;
    margin: 20px 0;
    display: none;

    .SearchField {
      width: 100%;

      input {
        width: 100%;
      }
    }

    .AddBtn {
      margin-left: 26px;

      .AddBtnIcon {
        fill: var(--color-secondary-lightest);
      }
    }
  }

  .Section {
    &.Scenarios {
      .Header {
        margin-top: 0;
      }
    }

    .Header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 32px 0;
    }

    .Grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
    }

    .List {
      display: flex;
      flex-direction: column;

      & > * + * {
        margin-top: 12px;
      }
    }
  }

  @media (max-width: 1154px) {
    .Section {
      .Grid {
        display: flex;
        flex-direction: column;
        gap: unset;

        & > * + * {
          margin-top: 12px;
        }
      }
    }
  }

  @media (max-width: 876px) {
    .Section {
      &.Scenarios {
        .Header {
          margin-top: 24px;
        }
      }

      .Header {
        margin: 24px 0;
      }
    }
  }

  @media (max-width: 704px) {
    .MobileHeader {
      display: flex;
    }

    .Section {
      .Header {
        display: none;
      }

      &.Mailing {
        display: none;
      }
    }
  }

  @media (max-width: 434px) {
    .MobileHeader {
      .AddBtn {
        height: 32px !important;
        min-width: 32px !important;

        .AddBtnIcon {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

.Root {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--color-m2);
  background: var(--color-m1);
  width: 100%;
  margin-bottom: 5px;

  .CardUpper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 15px;

    .Title {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      color: var(--color-m7);
      margin: 0;
    }

    .Buttons {
      display: flex;
      gap: 8px;

      & > * {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 31px;
        width: 5rem;
      }
    }
  }

  .CardText{
    margin: 0;
    overflow: hidden;
    color: var(--color-m9);
    text-overflow: ellipsis;
    white-space: pre-wrap;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    max-width: 50rem;
    height: 60px;
  }


}

.Wrapper {
  .Colors {
    display: flex;
    margin-top: 16px;

    & > * + * {
      margin-left: 8px;
    }

    .Color {
      min-width: 40px;
      min-height: 40px;
      border-radius: 50%;
      cursor: pointer;

      &[data-picker="true"] {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #1f9c86;
        border: 1px solid rgba(255, 255, 255, 0.2);

        svg {
          stroke: var(--color-secondary-lightest);
        }
      }
    }
  }

  @media (max-width: 1264px) {
    .Colors {
      display: grid;
      grid-template-columns: repeat(4, 40px);
      gap: 8px;

      & > * + * {
        margin-left: 0;
      }
    }
  }

  @media (max-width: 1048px) {
    .Colors {
      display: flex;
      gap: unset;

      & > * + * {
        margin-left: 8px;
      }
    }
  }

  @media (max-width: 734px) {
    .Colors {
      display: grid;
      grid-template-columns: repeat(4, 40px);
      gap: 8px;

      & > * + * {
        margin-left: 0;
      }
    }
  }

  @media (max-width: 670px) {
    .Colors {
      display: flex;
      gap: unset;

      .PickerPanel {
        right: unset;
        left: 48px;
        top: -250px;
      }

      & > * + * {
        margin-left: 8px;
      }
    }
  }

  @media (max-width: 368px) {
    .Colors {
      display: grid;
      grid-template-columns: repeat(4, 40px);
      gap: 8px;

      & > * + * {
        margin-left: 0;
      }
    }
  }
}

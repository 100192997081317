@import "../../../../styles/_mixins.scss";

.Block {
  padding: 30px;
  position: relative;
  background: var(--color-m0);
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 16px;
  overflow: hidden;
  color: var(--color-secondary-lightest);

  .Badge {
    position: absolute;
    top: 0;
    right: 0;
    border-bottom-left-radius: 30px;
    background: var(--color-m2);
    padding: 12px 24px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-secondary-lightest);
  }

  .Data {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .TextName {
      font-weight: 400;
      font-size: 16px;
      margin-left: 16px;
    }
  }

  .Social {
    border-top: 1px solid rgba(148, 148, 148, 0.1);
    margin-top: 16px;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include gap-fallback(16px);

    .Element {
      display: flex;
      align-items: center;
      border-radius: 8px;
      background: rgba(0, 0, 0, 0.2);
      padding: 10px 16px;
      position: relative;

      .Icon {
        width: 24px;
        height: 24px;

        &.Insta {
          transform: scale(2);
        }

        &.Vk {
          filter: drop-shadow(0px 3px 20px rgba(30, 136, 229, 0.6));
        }

        &.Tg {
          filter: drop-shadow(0px 3px 20px rgba(3, 155, 229, 0.502));
        }

        &.Phone {
          filter: drop-shadow(0px 0px 20px rgba(32, 205, 122, 0.5));
        }
      }

      .Info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 16px;
        width: 70%;
        overflow: hidden;

        .Value {
          font-size: 16px;
          margin-bottom: 4px;
          line-height: 22px;
        }

        .Name {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: var(--color-secondary-lightest);
          opacity: 0.5;
        }
      }

      .Copy {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 16px;

        .CopyIcon {
          cursor: pointer;
          stroke: var(--color-burger-line);
        }
      }
    }
  }
}

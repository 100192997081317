.Wrapper {
  height: 104px;
  background: var(--color-element-bg);
  border: 2px solid var(--color-divider);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &[data-dragging="true"] {
    opacity: 0.5;
  }

  &:hover,
  &:focus {
    background: var(--color-element-bg-active);
    border-color: var(--color-element-border-active);

    .Name {
      color: var(--color-secondary-lightest);
    }
  }

  .Icon {
    &[data-style-stroke="true"] {
      svg {
        stroke: var(--color-element-icon);
      }
    }

    svg {
      fill: var(--color-element-icon);
    }
  }

  .Name {
    margin: 0;
    margin-top: 16px;
    font-size: 12px;
    color: var(--color-d9-input);
  }
}

.Nav {
  position: absolute;
  z-index: 1000;
  background: var(--color-secondary-lightest);
  box-shadow: 0px 9px 27px rgba(0, 0, 0, 0.07), 0px 3.75998px 11.28px rgba(0, 0, 0, 0.0503198),
    0px 2.01027px 6.0308px rgba(0, 0, 0, 0.0417275), 0px 1.12694px 3.38082px rgba(0, 0, 0, 0.035),
    0px 0.598509px 1.79553px rgba(0, 0, 0, 0.0282725),
    0px 0.249053px 0.747159px rgba(0, 0, 0, 0.0196802);
  border-radius: 8px;
  padding: 16px 24px;
  display: flex;

  &[data-with-bridge="true"] {
    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      left: -10px;
      top: -10px;
      width: calc(100% + 20px);
      height: calc(100% + 20px);
    }
  }
}

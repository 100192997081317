$padding: 40px;

@mixin flex-align($gap) {
  display: flex;
  align-items: center;
  column-gap: $gap;
}

%clear-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.DrawerWrapper {
  inset: 0;
  position: fixed;
  display: flex;
  z-index: 100;
}

.Drawer {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 590px;
  display: flex;
  flex-direction: column;
  background: rgba(20, 20, 20, 0.8);
  backdrop-filter: blur(40px);

  @media (max-width: 876px) {
    width: 100%;
  }

  .Title {
    padding: 48px $padding;
    padding-bottom: 26px;
    margin: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .Cross {
      top: 10px;
      left: 10px;
      position: absolute;
    }
    .CrossIcon {
      fill: var(--color-m7);
    }

    .TitleText {
      color: var(--color-secondary-light);
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
    }

    .TitleButton {
      @extend %clear-button;
      display: flex;
      align-items: center;
      column-gap: 8px;
      color: var(--color-blue);
      fill: var(--color-blue);
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .Tabs {
    $width: 150px;
    @include flex-align(32px);
    position: relative;
    border-bottom: 1px solid var(--color-m2);
    color: var(--color-d7);
    padding: 0 $padding;

    .Tab {
      @include flex-align(8px);
      @extend %clear-button;
      width: $width;
      position: relative;
      padding-bottom: 8px;
      color: var(--color-m2);
      transition: color var(--animation-transition);

      &.Active {
        color: var(--color-secondary-light);
      }

      .Number {
        padding: 4px 8px;
        border-radius: 10px;
        color: var(--color-secondary-light);
        background: var(--color-red);

        &.Notification {
          background: var(--color-blue);
        }
      }
    }

    .Slider {
      position: absolute;
      height: 4px;
      width: $width * 1.1;
      bottom: 0px;
      background: var(--color-secondary-light);
      transition: left var(--animation-transition);

      &[data-tab="0"] {
        left: 15px;
      }
      &[data-tab="1"] {
        left: $width + 25 * 2;
      }
    }
  }

  .TabsPanels {
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    & > * {
      border-bottom: 1px solid var(--color-m2);
    }
  }
}

.Transition {
  transition: transform 0.5s;
}
.TransitionEFrom {
  transform: translateX(100%);
}
.TransitionETo {
  transform: translateX(0);
}
.TransitionLFrom {
  transform: translateX(0);
}
.TransitionLFTo {
  transform: translateX(100%);
}

.Block {
  height: 24px;
  width: 50px;
  padding: 0;

  &[data-active="false"] {
    background: rgba(217, 217, 217, 0.4);
    opacity: 0.5;
    border: 1px solid var(--color-element-icon);
  }

  &[data-active="true"] {
    background: rgba(33, 111, 224, 0.1);
    border: 1px solid var(--color-blue-primary);
    opacity: 1;
  }

  &[data-block-style="rounded"] {
    border-radius: 20px;
  }

  &[data-block-style="square"] {
    border-radius: 4px;
  }
}

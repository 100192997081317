.Page {
  padding: 52px 56px;

  .Nav {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 26px;

    .NavLeft {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      .TitleContainer {
        margin-top: 10px;
      }
    }

    .NavRight {
      display: flex;
    }
  }

  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 26px;

    .TitleAndStorage {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 10px;

      .Title {
        margin: 0;

        .GoBack {
          display: none;

          .GoBackText {
            display: none;
          }
        }
      }
    }

    .SubTitle {
      margin: 0;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      color: var(--color-m7);
      border: 2px solid var(--color-m2);
      background: var(--color-m1);
      height: 3.5rem;
      padding: 8px 8px;
      border-radius: 8px;

      @media (max-width: 741px) {
        width: 100%;
        max-width: 250px;
      }
    }

    .HeaderRight {
      display: flex;
      align-items: center;
      margin-left: 20px;

      .AddBtn {
        margin-left: 26px;
        display: none;
      }
    }
  }

  .Grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
  }

  @media (max-width: 1310px) {
    .Grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (max-width: 1008px) {
    .Grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 876px) {
    padding: 24px 20px;

    .Nav {
      margin-top: 25px;

      .NavRight {
        display: none;
      }
    }

    .Header {
      .Title {
        display: flex;
        align-items: center;

        .GoBack {
          display: block;
          margin-right: 16px;
        }
      }

      .HeaderRight {
        .AddBtn {
          display: flex;

          .AddBtnIcon {
            fill: var(--color-secondary-lightest);
          }
        }
      }
    }

    .Grid {
      .EmptyCard {
        display: none;
      }
    }
  }

  @media (max-width: 742px) {
    .Header {
      width: 100%;
      margin-bottom: 20px;
      flex-direction: column;
      align-items: center;

      .HeaderRight {
        width: 100%;
        margin: 0;

        .AddBtn {
          width: 100%;
          margin: 16px 0;
          align-items: center;
          justify-content: center;
        }
      }

      &[data-dont-change-direction-on-mobile="false"] {
        flex-direction: column;
        align-items: flex-start;

        .Title {
          margin-bottom: 20px;
        }

        .HeaderRight {
          width: 100%;
          margin-left: 0;

          .SearchField {
            width: 100%;

            input {
              width: 100%;
            }
          }
        }
      }
    }
  }

  @media (max-width: 570px) {
    .TitleAndStorage {
      flex-direction: column;
    }
    .Grid {
      display: flex;
      flex-direction: column;
      gap: unset;

      & > * + * {
        margin-top: 16px;
      }
    }
  }

  @media (max-width: 434px) {
    .Header {
      .AddBtn {
        height: 32px !important;
        min-width: 32px !important;

        .AddBtnIcon {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

.Component {
  padding: 4px 24px;
  border: 1px solid transparent;
  position: relative;
  overflow-wrap: break-word;
  list-style: none;

  &[data-image-style="FILL"] {
    padding: 4px 0;
    border: none;
  }

  &[data-is-preview="false"] {
    &:hover {
      border-color: var(--color-component-border-active);

      .DragPad {
        display: flex;
      }
    }
  }

  .DragPad {
    display: none;
    position: absolute;
    left: 8px;
    top: -10px;
    width: 40px;
    height: 20px;
    background: var(--color-component-border-active);
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    cursor: grab;

    & > * + * {
      margin-left: 2px;
    }

    .Dot {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: var(--color-secondary-lightest);
    }
  }
}

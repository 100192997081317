.Wrapper {
  display: none;

  .Nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 26px;
    padding: 0 20px;
  }

  @media (max-width: 776px) {
    display: block;
  }
}

.Wrapper {
  width: 100%;
  position: relative;
}

.Fade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.32);
}

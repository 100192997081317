.TableFilters {
  padding: 0;
  position: relative;

  .TableFilterOptionsWrapper {
    position: absolute;
    top: 0;
    right: -60px;
    margin: 0;
    padding: 0;
    border-radius: 8px;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.16);
    color: var(--color-d9-input);
    backdrop-filter: blur(8px);
    background: var(--color-table-filters-bg);
    z-index: 10;

    .Option {
      cursor: pointer;
    }

    .Active {
      color: var(--color-secondary-lightest);
    }
  }

  .TableFilterOptions {
    text-align: left;
    min-width: 140px;
    padding: 34px 24px 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-transform: none;

    & > * + * {
      margin-top: 16px;
    }

    .InnerButton {
      background: transparent;
      padding: 0;
      border-style: none;

      .Chevron {
        position: absolute;
        top: 16px;
        left: 20px;
        fill: transparent;
        stroke: var(--color-secondary-lightest);
        transform: rotate(-90deg);
      }
    }
  }
}

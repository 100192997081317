.Wrapper {
  position: relative;
  display: flex;

  &:hover,
  &:focus {
    .Tip {
      display: block;
      opacity: 1;
      transition: opacity var(--animation-transition);
    }
  }


  .Tip {
    position: absolute;
    display: none;
    opacity: 1;
    max-width: 680px;
    width: 100vw;
    top: 30px;
    padding: 6px 12px;
    border-radius: 16px;
    backdrop-filter: blur(8px);
    background: var(--color-m1);
    box-shadow: var(--color-tooltip-shadow);
    color: var(--color-secondary-lightest);
    font-size: 10px;
    z-index: 1;


    @media (max-width: 1200px) {
      max-width: 300px;
    }

    @media (max-width: 601px) {
      max-width: 280px;
      right: 0;
    }
  }
}

.Section {
  min-height: 120px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  column-gap: 16px;
  justify-content: flex-start;
  gap: 2px;
  overflow: hidden;
  padding: 24px 24px 16px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  background: var(--color-grey-cards-bg);
  color: var(--color-secondary-lighter);
  backdrop-filter: blur(40px);
  cursor: pointer;
  height: 150px;


  &:hover {
    opacity: .7;
  }

  &.Avatar {
    .Data {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }

    .Bottom {
      margin-bottom: 24px;
    }
  }

  .Text {
    margin: 0;
    font-size: 14px;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 12rem;

    @media (max-width: 375px) {
      max-width: 8rem;
    }
  }

  .SubHeader {
    margin-top: 12px;
  }

  .Data {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    padding-bottom: 8px;

    & > * + * {
      margin-top: 16px;
    }
  }

  .Footer {
    padding-top: 24px;
    padding-bottom: 8px;
  }

  .Bottom {
    padding-top: 24px;
  }

  .AvatarSection {
    background: rgba(63, 63, 63, 0.5);
    display: flex;
    align-items: center;
    column-gap: 16px;
    padding: 16px 24px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    & + .Flex {
      margin-top: 16px;
    }
  }

  .AvatarWrapper {
    display: flex !important;
    align-items: center;

    h3 {
      padding-left: 8px;
      color: var(--m-7, #a9a9b7);
      font-size: 12px;
    }
  }

  .SubFooter {
    margin-top: 24px;
    margin-bottom: 8px;
  }

  .Error {
    color: var(--color-error);
    font-size: 12px;
    font-weight: 400;
  }
}

.Flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

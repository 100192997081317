.Wrapper {
  position: relative;

  .Block {
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    padding: 24px 0;
    color: white;

    .Components {
      padding-left: 0;
      height: 100%;
    }
  }
}

.Nav {
  right: 50px;
  bottom: -30px;

  & > * + * {
    margin-left: 40px;
  }

  .Options {
    display: flex;

    & > * + * {
      margin-left: 20px;
    }
  }

  .ColorPicker {
    position: absolute;
    top: 70px;
    // height: 100%;
    width: 100%;
  }
}

.Tabs {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-d7);
  height: 40px;
  margin-bottom: 32px;

  .Tab {
    padding: 0;
    text-align: left;
    white-space: nowrap;
    background: transparent;
    border-style: none;
    padding-right: 16px;
    color: var(--color-d7);
    position: relative;
    flex: 1;

    &::before {
      content: "";
      position: absolute;
      bottom: -23px;
      left: 0;
      height: 3px;
      background: var(--color-m8);
      width: 0;
      transition: width var(--animation-transition);
    }

    &[data-active="true"] {
      color: var(--color-m8);

      &::before {
        width: 70%;
        transition: width var(--animation-transition);
      }
    }
  }

  @media (max-width: 375px) {
    margin-bottom: 20px;
    height: 38px;

    .Tab {
      font-size: 12px;
      padding-right: 0;
      flex: 0;

      &[data-active="true"] {
        &::before {
          width: 100%;
        }
      }
    }
  }
}

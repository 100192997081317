.Wrapper {
  display: flex;
  align-items: center;

  .Date {
    line-height: 19px;
    color: var(--color-secondary-lightest);
  }

  .Divider {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: var(--color-gray-darker);
    opacity: 0.5;
    margin: 0 8px;

    &[data-no-dot="true"] {
      width: 0;
      height: 0;
      margin: 0 4px;
    }
  }
}

.Children {
  padding-bottom: 0 !important;
}

.MobileMenu {
  display: none;
}

.MenuBtn {
  opacity: 0.6;
}

.IconDown {
  margin-left: 10px;
  stroke: var(--color-secondary-lightest);
}

.MenuContext {
  right: -24px !important;
  top: -12px !important;
}

@media (max-width: 380px) {
  .Children {
    padding-bottom: 12px !important;
  }

  .DesktopMenu {
    display: none;
  }

  .MobileMenu {
    display: block;

    .MenuBtn {
      opacity: 1;
    }
  }
}

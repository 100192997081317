.Page {
  padding: 52px 56px;

  .Header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;
  }

  .Title {
    margin-bottom: 32px;

    .GoBack {
      display: none;

      .GoBackText {
        display: none;
      }
    }
  }

  .SearchMobile {
    display: none;
  }

  .Content {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 24px;

    .Grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;
    }

    .Aside {
      display: flex;
      flex-direction: column;

      & > * + * {
        margin-top: 8px;
      }

      .Stacks,
      .Cards {
        & > * + * {
          margin-top: 8px;
        }
      }
    }
  }

  .NavWithTabsMobile {
    display: none;
  }

  @media (max-width: 1360px) {
    .Content {
      display: flex;
      flex-direction: column-reverse;
      gap: unset;

      .Aside {
        margin-bottom: 20px;

        & > * + * {
          margin-top: 0;
        }

        .Cards {
          margin-bottom: 20px;
        }

        .Cards,
        .Stacks {
          display: flex;
          overflow-x: auto;

          & > * + * {
            margin-top: 0;
            margin-left: 8px;
          }
        }
      }
    }
  }

  @media (max-width: 986px) {
    .Content {
      .Grid {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  @media (max-width: 876px) {
    padding: 24px 20px 80px;

    .Header {
      display: none;
    }

    .Title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .GoBack {
        display: block;
        margin-right: 16px;
      }
    }

    .SearchMobile {
      display: block;
      margin-bottom: 20px;
    }

    .Content {
      .Grid {
        grid-template-columns: repeat(3, 1fr);
      }

      .Aside {
        margin-bottom: 10px;

        .Cards {
          margin-bottom: 0;
          padding-bottom: 10px;
        }

        .Stacks {
          display: none;
        }
      }
    }

    .NavWithTabsMobile {
      display: flex;
    }
  }

  @media (max-width: 810px) {
    .Content {
      .Grid {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  @media (max-width: 552px) {
    .Content {
      .Grid {
        display: flex;
        flex-direction: column;
        gap: unset;

        & > * + * {
          margin-top: 16px;
        }
      }
    }
  }
}

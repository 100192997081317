.Title {
  font-size: 12px;
  color: var(--color-d7);
  margin-bottom: 14px;
}
.form{
  & > * + * {
    margin-top: 24px;
  }

  @media (max-width: 670px) {
    & > * + * {
      margin-top: 16px;
    }
  }
};


$closed: 96px;
$opened: 200px;
$star: 35px;
$icon: 24px;

.Nav {
  display: flex;
  flex-direction: column;
  width: $closed;
  min-height: 100vh;
  background: var(--dark-navigation-background);
  transition: width var(--animation-transition);

  .NavOpener {
    background-color: transparent;
    padding: 24px;
    border: none;
    fill: #5c5d61;
  }

  .Greeting {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0 60px;

    .AvaWrapper {
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;

      .AvatarButton {
        margin: 0;
        background: transparent;
        border: none;
        cursor: pointer;

        .ContextBtn {
          position: absolute;
          top: 50%;
          right: -60%;
          transform: translate(-50%, -50%);
          fill: #d9d9d9;
          z-index: 10;
          cursor: pointer;
          opacity: 0;
        }
      }

      .Avatar {
        width: 48px;
        height: 48px;
        margin-bottom: 12px;
      }

      .Context {
        position: absolute;
        top: 20%;
        left: 100%;
        width: 123px;
        height: 113px;
        border-radius: 8px;
        background: linear-gradient(
          204.74deg,
          rgba(39, 48, 58, 0.9) 15.77%,
          rgba(45, 44, 50, 0.9) 99.27%
        );
        box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.16);
        backdrop-filter: blur(8px);

        ul {
          padding: 35px 0px 24px 16px;
          margin: 0;

          li {
            list-style: none;
            padding: 0;
            margin: 0;
            font-size: 14px;
            font-weight: 400;
            color: #fd4438;
            cursor: pointer;

            &:not(:last-child) {
              margin-bottom: 16px;
              color: var(--color-text-primary);

              a {
                color: var(--color-text-primary);
                text-decoration: none;
              }
            }

            .NavLink {
              text-decoration: none;
            }
          }
        }
      }
    }

    .GreetingText {
      visibility: hidden;
      margin-top: 8px;
      text-align: center;

      .Name {
        font-size: 14px;
        font-weight: 400;
        color: var(--color-text-primary);
        margin: 0;
        margin-bottom: 5px;
      }

      .Email {
        font-size: 10px;
        font-weight: 400;
        color: var(--color-text-primary);
        opacity: 0.2;
        margin: 0;
      }
    }
  }

  .Navigation {
    flex: 1;
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: 18px;
    }

    .Wrapper {
      display: flex;
      position: relative;
      align-items: center;
      margin-left: 21px;
      fill: var(--color-m4);
      color: var(--color-m4);
      text-decoration: none;
      cursor: pointer;

      .Text {
        display: none;
      }

      .Banner {
        display: none;
      }
    }

    .BottomIcns {
      margin-top: 78px;

      & > * + * {
        margin-top: 16px;
      }
    }

    .Adds{
      margin: 20px;
    }

    .Footer {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-bottom: 20px;
      fill: var(--color-m4);

      .Logo {
        width: $star;
        height: $star;
        display: flex;
        gap: 14px;
        margin-top: 20px;
        margin-bottom: 40px;
        padding-left: ($closed - $star) * 0.5;
        transition: padding var(--animation-transition);
      }

      .Text {
        opacity: 0;
        transition: opacity var(--animation-transition);
      }

      .HelpCircle {
        margin-left: ($closed - $icon) * 0.5;
        transition: margin var(--animation-transition);
      }
    }
  }

  //==============================================//
  &[data-active="true"] {
    width: $opened;

    .GreetingText {
      visibility: visible;
    }

    .Greeting {
      .AvaWrapper {
        .ContextBtn {
          opacity: 0.2;
          transition: opacity var(--animation-transition) var(--animation-transition);
        }
      }
    }

    .Context {
      position: absolute;
      top: 20%;
      left: 90%;
      width: 123px;
      height: 113px;
      border-radius: 8px;
      background: linear-gradient(
        204.74deg,
        rgba(39, 48, 58, 0.9) 15.77%,
        rgba(45, 44, 50, 0.9) 99.27%
      );
      box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.16);
      backdrop-filter: blur(8px);
      z-index: 1;
    }

    .Footer {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .Logo {
        padding-left: 21px;
      }

      .Text {
        opacity: 1;
      }

      .HelpCircle {
        margin-left: 21px;
      }
    }
  }
}

@import "../../../../styles/_mixins.scss";

.Wrapper {
  display: flex;
  margin: 32px 0;

  @media (max-width: 990px) {
    flex-direction: column-reverse;
  }

  .Bots {
    display: grid;
    grid-template-columns: repeat(4, 300px) !important;
    gap: 24px;

    @media (max-width: 1520px) {
      grid-template-columns: repeat(3, 300px) !important;
      padding-bottom: 24px;
    }

    @media (max-width: 1200px) {
      grid-template-columns: repeat(2, 300px) !important;
    }

    @media (max-width: 990px) {
      grid-template-columns: repeat(1, 1fr) !important;
      grid-auto-columns: 100%;
    }
  }

  .RightSection {
    margin-left: 32px;
    min-width: 320px;
    max-width: 320px;

    @media (max-width: 990px) {
      max-width: 320px * 2;
      margin-left: 0;
      margin-bottom: 32px;
      width: 100%;
    }
  }
}

.Telegram {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;

  .NonEmpty {
    top: 60%;
    left: 70%;
    width: 400px;
    height: 300px;
  }
}

.Card {
  padding: 30px 28px 32px;
  background: linear-gradient(204.74deg, #27303a 15.77%, #2d2c32 99.27%);
  border-radius: 16px;
  max-height: 355px;

  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    .ToggleText {
      font-size: 14px;
      color: var(--color-m7);
    }
  }

  .Name {
    font-size: 20px;
    line-height: 27px;
    height: 54px;
    color: var(--color-secondary-lightest);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Text {
    font-size: 12px;
    line-height: 16px;
    color: var(--color-m8);
    opacity: 0.7;
    height: 50px;
    overflow-y: hidden;
    text-overflow: ellipsis;
    margin: 16px 0;
  }

  .VideoLink {
    margin-bottom: 24px;
  }

  @media (max-width: 876px) {
    padding: 20px 24px 24px;

    .Header {
      justify-content: flex-start;

      .ToggleText {
        font-size: 12px;
        margin-left: 16px;
      }
    }

    .Name {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

@keyframes wrapper-overflow {
  0% {
    overflow: hidden;
  }
  100% {
    overflow: visible;
  }
}

.Accordion {
  width: 100%;

  .ContentWrapper {
    overflow: hidden;

    .Content {
      margin-top: -110%;
      transition: margin-top var(--animation-transition);
    }
  }

  &[data-open="true"] {
    .ContentWrapper {
      animation: wrapper-overflow 0s ease-in-out var(--animation-transition) forwards;

      .Content {
        transition: margin-top var(--animation-transition);
      }
    }
  }
}

.Modal {
  font-family: var(--font-primary);

  .ModalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .Title {
      color: var(--mk, #fff);
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      text-transform: uppercase;
    }

    .Question {
      color: var(--m-8, #d0d0da);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      margin-bottom: 20px;
    }

    .Info {
      color: var(--m-8, #d0d0da);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
    }

    .BtnWrapper {
      display: flex;
      margin-top: 24px;
      width: 100%;
      justify-content: space-around;

      .Confirm {
        width: 136px;
        padding: 16px 24px;
        text-align: center;
        background: var(--a-6-coral-err, #fd4438);
        display: initial;
      }
    }
  }
}

.Menu {
  position: relative;
}

.Dots {
  transform: rotate(90deg);
  color: rgba(91, 91, 101, 1);
}

.CloseIcon{
  position: absolute;
  top: 17px;
  right: 24px;
}

.MenuItems {
  min-width: 200px;
  position: absolute;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  top: 16px;
  right: 8px;
  background: linear-gradient(
      204.74deg,
      rgba(39, 48, 58, 0.9) 15.77%,
      rgba(45, 44, 50, 0.9) 99.27%
  );
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.16);
  backdrop-filter: blur(8px);
  border-radius: 8px;
  padding: 40px 24px;
  z-index: 10;

  & * {
    opacity: 0.8;
    color: var(--color-secondary-lightest);
    transition: opacity var(--animation-transition);
    text-decoration: none;
  }
}

.TableHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background: var(--color-m0);
  height: 4.8rem;
  padding: 0 10px;
  margin-bottom: 8px;



  .TableHeadTr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .Par {
    margin: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    color: var(--color-d6);
    margin-right: 5px;
  }

  .Section {
    display: flex;
    align-items: center;
    width: 20rem;

    .Checkbox {
      margin-right: 5px;
    }
  }

  .LastTh {
    display: flex;
    justify-content: flex-end;
    width: 22rem;

    @media (max-width: 750px) {
      width: 11rem;
    }
  }

  .Btn {
    color: var(--color-red);

    @media (max-width: 876px) {
      font-size: 15px;
      text-align: center;
    }
  }
}

.Trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  padding-bottom: 16px;
  background: transparent;
  border-style: none;
  width: 100%;
  font-size: 16px;
  color: var(--color-d9-input);
  border-bottom: 1px solid var(--color-divider);

  &[data-no-divider="true"] {
    border-style: none;
    padding-bottom: 0;
  }

  .Icon {
    stroke: var(--color-secondary-lightest);
    opacity: 0.3;
    transform: rotate(0);
    transition: transform var(--animation-transition);

    &.IconOpen {
      transform: rotate(180deg);
      transition: transform var(--animation-transition);
    }
  }

  @media (max-width: 380px) {
    font-size: 14px;

    .Icon {
      width: 16px;
    }
  }
}

.Wrapper {
  position: relative;
  background-color: inherit;
  opacity: 0.7;

  .Input {
    width: 360px;
    height: 52px;
    border: 1px solid rgba(142, 142, 142, 0.26);
    border-radius: 16px;
    padding: 16px;
    padding-left: 56px;
    font-size: 14px;
    font-weight: 400;
    color: var(--color-text-primary);
    background-color: inherit;

    &:hover,
    &:focus {
      outline: none;
    }
  }

  .Icon {
    position: absolute;
    left: 19px;
    top: 50%;
    transform: translateY(-50%);
    fill: rgba(255, 255, 255, 1);
  }

  @media (max-width: 434px) {
    .Input {
      width: 100%;
      height: 34px;
      font-size: 12px;
      padding-left: 46px;
    }

    .Icon {
      width: 16px;
      height: 16px;
      left: 16px;
    }
  }
}

.Wrapper {
  display: flex;
  justify-content: flex-end;
  column-gap: 24px;
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  fill: var(--color-secondary-lightest);

  .Label {
    color: var(--color-secondary-lightest);
    opacity: 0.4;
  }

  .Menu{
    .btn{
      max-width: 100%;
      &[data-type='mobile']{
        text-align: left;
        border: 1px solid rgba(44, 44, 53, 1);
        padding: 16px;
        border-radius: 12px;
        grid-template-areas: "b b a" "b b c" "b b c";
        .IconDown{
          margin-left: 8px;
          stroke: rgba(92, 93, 97, 1);
        }
        .MobileLabel{
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: rgba(148, 148, 148, 1);
          text-align: left;
        }
      }
    }
    .IconDown {
      margin-left: 8px;
      stroke: var(--color-secondary-lightest);
    }
    @media (max-width: 877px) {
      width: 100%;
    }
  }
}

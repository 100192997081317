.Image {
  width: 292px;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 auto;
  aspect-ratio: 1.77;

  &[data-image-style="FILL"] {
    background-size: cover;
    width: 100%;
  }

  &[data-image-style="FIT"] {
    background-size: contain;
  }

  &[data-image-style="REPEAT"] {
    background-size: 10%;
    background-repeat: repeat;
  }
}

.FilterButtonWrapper{
  display: flex;
  column-gap: 8px;
  align-items: center;

  p{
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: rgba(208, 208, 218, 1);
  }
}

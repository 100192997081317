.AvatarWrapper {
  margin-top: 24px;
  margin-bottom: 16px;
  display: flex;
  align-self: flex-start;

  .Buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    column-gap: 8px;
    margin-left: 30px;

    .Delete {
      margin-top: 16px;
      margin-left: 16px;
      color: var(--color-red);
    }
  }

  @media (max-width: 876px) {
    .Buttons {
      .ChangePicBtn {
        margin-bottom: 16px;
        height: 32px;
      }
    }
  }
}

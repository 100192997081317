.SignIn {
  padding: 100px 0 165px 0;
}

.ForgotPasswdLink {
  font-size: 14px;
  line-height: 20px;
  height: 20px;
  color: #949494;
  margin-left: 16px;
  margin-top: -4px;
  width: 120px;

  &:hover,
  &:focus {
    text-decoration: none;
    color: #949494;
  }
}

@media (max-width: 600px) {
  .SignIn {
    padding: 30px 0 50px 0;
  }

  .First {
    display: none;
  }
  .Second {
    top: -60px;
    right: -60px;
  }
  .Third {
    top: 25%;
    left: -40%;
  }
  .Fouth {
    width: 164px;
    height: 163px;
    right: -25%;
    bottom: -5%;
    border: 40px solid #216fe0;
  }
}

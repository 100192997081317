@import "../../../../styles/_mixins.scss";


.Wrapper {
  & > * {
    margin-bottom: 10px;
  }


  .Switcher {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .FilesWrapper {
    margin-top: 10px;

    .Files {
      margin-top: 15px;
      padding: 8px;
      border-radius: 8px;
      border: 1px solid var(--color-m2);
      background: var(--color-m1);
      max-height: 11rem;
      overflow-y: scroll;
      @include scroll-bar;

      &::-webkit-scrollbar {
        width: 5px;
      }


      .File {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid var(--color-m2);

        &:last-child {
          border-bottom: none;
          margin-bottom: 0;
          padding-bottom: 0;
        }

        .FileName {
          margin: 0;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 19px;
          color: var(--color-m7);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 11rem;
        }

        .FileSize {
          margin: 0;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          color: var(--color-m5);
        }

        svg {
          fill: var(--color-m7);
          //height: 24px;
          //width: 24px;
          cursor: pointer;
        }
      }
    }
  }
}

:root {
  //media-queries

  --mobile-breakpoint: 600px;
  // fonts
  --font-primary: "Noto Sans", sans-serif;
  --font-secondary: "Open Sans", sans-serif;
  --font-h4: "Montserrat", sans-serif;

  // colors
  --color-secondary: #f4f4f4;
  --color-secondary-light: #f9f9f9;
  --color-secondary-lighter: #fcfcfc;
  --color-secondary-lightest: #fff;
  --color-secondary-lightest-opace: rgb(255, 255, 255, 0.08);
  --color-secondary-dark: #dbdbdb;
  --color-secondary-gray: #e9e9e9;
  --color-secondary-grey2: #474a4d;
  --color-secondary-darker: #949494;
  --color-secondary-darkest: #242424;
  --color-text-gray: #424242;
  --color-m0: #0f0f13;
  --color-m0-opace: rgba(15, 15, 19, 0.8);
  --color-m1: #1e1e24;
  --color-m4: #5b5b65;
  --color-m7: #a9a9b7;
  --color-m2: #2c2c35;

  --color-d7: #606162;
  --color-d9-input: #8f8f90;
  --color-d1: #19191b;
  --color-d3: #232426;
  --color-d4: #343537;
  --color-d6: #535455;
  --color-d7: #606162;
  --color-m9: #f5f5ff;
  --color-m5: #70707c;
  --color-d5: #444547;
  --color-m3: #40404a;
  --color-m6: #8a8a98;
  --color-d0: #101010;
  --color-a6-coral-err: #fd4438;
  --color-a6: #d93c32;
  --color-m8: #d0d0da;

  // payment
  --color-payment-selected: #e2e2e2;
  --color-payment-selected-bg: rgba(226, 226, 226, 0.22);

  // messages
  --color-message-border: #dee2e6;

  // cards
  --color-card-popular: #464646;
  --color-card-base: #4c4c4c;
  --color-card-standard: #929292;
  --color-card-premium: #c2c2c2;
  --color-grey-cards-bg: linear-gradient(238.16deg, #27303a 30.74%, #2d2c32 100%);

  // indicators
  --color-error: rgba(255, 0, 0, 0.62);
  --color-error-lighter: #d22828;
  --color-progress: #f9dd72;
  --color-success: #399478;

  // transition
  --animation-transition: 0.3s;
  --animation-label-font: 0.7s;

  /// Dark Theme ///
  --color-modal-bg: rgba(15, 15, 19, 1);

  --dark-background: #232426;
  --color-text-primary: #fff;
  --color-text-light: var(--color-m9);
  --light-secondary-darker: #949494;
  --color-text-gray: #a9a9b7;
  --color-gray: #d0d0d0;
  --color-gray-second: #d0d0da;
  --dark-navigation-background: #1c1c1c;

  --partnership-bg: #272a2d;

  --background-input: #1e1e24;
  --border-input: var(--color-m3);
  --image-input-border: #5b5b65;
  --image-input-hint: #889297;

  --color-red: #fd4438;

  // --gradient-grey-card: linear-gradient(238.16deg, #27303a 30.74%, #2d2c32 100%);
  --gradient-grey-card: #27303a;

  --color-gray-darker: #d9d9d9;
  --color-black: #000000;
  --color-blue: #1977ff;
  --color-blue-active: #0168fe;
  --color-blue-primary: #216fe0;
  --color-blue-primary-hover: #1e60c0;
  --color-blue-secondary: #6598ff;

  --color-magenta: #e80fe9;
  --color-persian: #4807ea;
  --color-a5-lime: #1ed731;
  --color-a5-h: #1cb12b;

  --color-purple-gradient: linear-gradient(138.46deg, #9136fa 1.27%, #b10edb 95.91%);
  --color-purple: #b10edb;

  //Tariff
  --card-ext-background: linear-gradient(238.16deg, #27303a 30.74%, #2d2c32 100%);

  //CardExtedTitleBacground
  --background-standart: linear-gradient(135.23deg, #1a77ff 0%, #4791dc 99.95%);
  --background-premium: linear-gradient(135.23deg, #9136fa 0%, #b10edb 99.95%);

  //linear-gradient
  --background-persian: linear-gradient(107.3deg, #216fe0 0%, #4807ea 100%);
  --background-magic: linear-gradient(90deg, #e80fe9 0%, #a40beb 100%);
  --background-magenta: linear-gradient(107.3deg, #fd4438 0%, #e80fe9 100%);
  --background-magic2: linear-gradient(#5C3085, #E81EAB);

  --background-auth: linear-gradient(107.3deg, #216fe0 0%, #4807ea 100%);

  // header
  --color-header: #131313;
  --bell-circle: #1e98dd;
  --color-header-bg-mobile: rgba(20, 20, 20, 0.95);
  --color-burger-line: #5c5d61;

  --bell-color: #70707c;

  // table
  --color-table-filters-bg: linear-gradient(
    204.74deg,
    rgba(39, 48, 58, 0.9) 15.77%,
    rgba(45, 44, 50, 0.9) 99.27%
  );

  // Transactions
  --color-transaction-input-bg: rgba(30, 215, 49, 0.1);
  --color-transaction-output-bg: rgba(33, 111, 224, 0.1);
  --color-transaction-purchase-bg: rgba(232, 15, 233, 0.1);

  --color-transaction-input: rgba(30, 215, 49, 1);
  --color-transaction-output: rgba(33, 111, 224, 1);
  --color-transaction-purchase: rgba(232, 15, 233, 1);

  // tooltip
  --color-tooltip-bg: rgba(65, 81, 228, 0.8);
  --color-tooltip-shadow: 0px 4px 16px 0px rgba(65, 81, 228, 0.32);

  // my pages
  --color-header-bg-my-pages: rgba(20, 20, 20, 0.9);
  --color-phone-box-shadow: rgba(0, 0, 0, 0.8);
  --color-phone-box-shadow-preview: rgba(0, 0, 0, 0.6);
  --color-phone-border: #262628;
  --color-phone-border-preview: #010101;
  --color-phone-bg: linear-gradient(166.82deg, #141414 -0.75%, #0c0c0c 111.37%);
  --color-element-bg: rgba(217, 217, 217, 0.1);
  --color-element-bg-active: rgba(49, 89, 234, 0.1);
  --color-element-icon: #d9d9d9;
  --color-element-icon-bg: rgba(217, 217, 217, 0.2);
  --color-element-border-active: #3159ea;
  --color-block-control-border: rgba(253, 253, 253, 0.3);
  --color-block-control-bg: #343434;
  --color-drag-pad-inactive: #fdfdfd;
  --color-component-border-active: #3e66fb;

  // divider
  --color-divider: rgba(255, 255, 255, 0.1);

  // color picker
  --color-picker-preview-border: #464749;

  //toasts

  --toast-background-success: rgba(79, 255, 107, 0.51);
  --toast-shadow-success: 0px 4px 16px rgba(9, 186, 59, 0.21);
  --toast-background-error: rgba(255, 37, 37, 0.74);
  --toast-shadow-error: 0px 4px 22px rgba(255, 0, 0, 0.21);

  --toast-size-pc: 744px;
  --toast-size-mob-big: 389px;
  --toast-size-mob-small: 250px;
}

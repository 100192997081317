.Block {
  width: 100%;
}

.Left {
  display: flex;
  align-items: center;

  .Icon {
    margin-left: 15px;
    fill: var(--color-text-primary);
  }

  .Content {
    font-size: 14px;
    font-weight: 400;
    color: var(--color-text-primary);
    margin: 0;
    margin-left: 18px;
  }
}

.Close {
  margin-right: 19px;
}

.Header {
  display: flex;
  margin-bottom: 40px;
  align-items: center;
  gap: 10px;

  .Title {
    margin: 0 30px 0 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-transform: uppercase;
    background: linear-gradient(
      90.43deg,
      rgba(255, 255, 255, 0.6) 0%,
      #ffffff 18.07%,
      rgba(255, 255, 255, 0.8) 83.79%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  .landingSelector{
    display: block;
  }
  .mobileFilterWrapper{
    display: none;
    margin-top: 5px;
  }

  @media (max-width: 877px) {
    justify-content: space-between;
    .landingSelector{
      display: none;
    }
    .mobileFilterWrapper{
      display: block;
    }
  }

  @media (max-width: 612px) {
    .Title {
      text-align: center;
      //margin-bottom: 20px;
      margin-right: 0;
    }
  }
}

.Title {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  color: var(--color-secondary-lightest);
}

.Text {
  font-size: 12px;
  line-height: 16px;
  color: var(--color-m8);
  margin: 24px 0;
}

.Instructions {
  margin-top: 12px;
  margin-bottom: 32px;
}

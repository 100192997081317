.IconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-style: none;
  padding: 0;

  &[data-clickable="true"] {
    cursor: pointer;
  }

  &[data-as-circle="true"] {
    border-radius: 50%;
  }

  &[data-with-border="true"] {
    border: 1px solid rgba(142, 142, 142, 0.26);
  }

  &:disabled {
    cursor: initial;

    .IconButtonIcon {
      opacity: 0.25;
    }
  }

  &:not(:disabled) {
    &:hover,
    &:focus {
      .IconButtonIcon {
        fill: var(--color-secondary-lightest);

        &.StyleStroke {
          stroke: var(--color-secondary-lightest);
        }
      }

      .IconButtonText {
        color: var(--color-secondary-lightest);
      }
    }
  }

  .IconButtonIcon {
    fill: var(--color-m5);

    &.StyleStroke {
      stroke: var(--color-m5);
    }
  }

  .IconButtonText {
    color: var(--color-m5);
    font-weight: 600;
    font-size: 16px;
    margin: 0 0 0 12px;
  }
}

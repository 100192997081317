.CardHeaderLeft {
  display: flex;
  align-items: center;

  .Img {
    width: 60px;
    height: 60px;
    border-radius: 60px;
    margin-right: 16px;
  }

  .CardText {
    display: flex;
    flex-direction: column;

    .CardTitle {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      color: var(--color-text-primary);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 14.4rem;
    }

    .SubTitle {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.357;
      color: var(--color-m7);
    }
  }
}

.Page {
  display: flex;
  //justify-content: space-between;
  column-gap: 30px;

  @media (max-width: 1245px) {
    flex-direction: column;
  }

  .Content {
    //flex: 1;
    display: flex;
    flex-direction: column;
    padding: 56px;

    @media (max-width: 500px) {
      padding: 16px;
    }
  }

  .Container {
    display: flex;
  }

  .Messages {
    display: flex;
    flex-direction: column;
    max-height: 160vh;
    flex-wrap: wrap;
    column-gap: 24px;
    margin-top: 32px;
  }

  .Body {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    .Header {
      display: flex;
      justify-content: flex-end;
    }
  }

  .Tags {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2px;
  }
}

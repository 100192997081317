.Root {
  border: 2px solid var(--color-m2);
  background: var(--background-input);
  border-radius: 16px;
  padding: 16px;
  min-width: 280px;
  width: 100%;

  h5 {
    color: var(--color-m9);
  }

  span {
    color: var(--color-m9);
  }

  .Status {
    display: flex;
    gap: 5px;
    align-items: center;

    .Text {
      color: var(--color-m5);
      font-size: 12px;
      font-weight: 400;
    }

    .Error {
      color: var(--color-error);
      font-size: 12px;
      font-weight: 400;
    }

    .Check {
      fill: transparent;
      stroke: var(--color-transaction-input);
    }
  }

  .Upper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .DateString {
      font-weight: 400;
      font-size: 12px;
      color: var(--color-m6);
    }
  }

  .Login {
    h5 {
      font-weight: 400;
      font-size: 12px;
      color: var(--color-m7);
    }
  }

  .Name {
    span {
      font-weight: 500;
      font-size: 16px;
      color: var(--color-m9);
    }
  }

  .Phone {
    h5 {
      font-weight: 400;
      font-size: 14px;
      color: var(--color-m9);
    }
  }
}

.Card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    204.74deg,
    rgba(39, 48, 58, 0.6) 15.77%,
    rgba(45, 44, 50, 0.6) 99.27%
  );
  border-radius: 16px;
}

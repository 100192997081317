.Wrapper {
  position: relative;

  .Text {
    margin: 0;

    &[contenteditable="true"] {
      border: 1px solid #d9d9d9;
      padding: 6px;
    }
  }
}

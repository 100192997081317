.Root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  border: 1px solid var(--color-m2);
  border-radius: 16px;
  background: var(--color-m2);
  height: 35rem;
  //min-width: 280px;
  width: 310px;

  .Checkbox {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .Logo {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .Buttons {
    .Phar {
      display: flex;
      font-size: 16px;
      font-weight: 500;
      color: var(--color-a6);
      justify-content: center;
    }
    .Button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }


}

.VideoLink {
  opacity: 0.7;
  text-decoration: none;
  color: var(--color-secondary-lightest);
  display: flex;
  align-items: center;
  cursor: pointer;

  .VideoLinkIcon {
    margin-left: 12px;
    fill: var(--color-secondary-lightest);
  }
}

.Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bg-color, #d9d9d9);

  &[data-orientation="vertical"] {
    height: 84px;
    width: 100%;
    height: 62px;
    border-radius: 8px;
    padding: 0 12px;

    .Name {
      margin-left: 30px;
      min-width: 74px;
      font-weight: 400;
      font-size: 14px;
    }
  }

  &[data-orientation="horizontal"] {
    width: 84px;
    height: 84px;

    // circle
    &[data-shape="0"] {
      border-radius: 50%;
    }

    // square
    &[data-shape="1"] {
      border-radius: 8px;
    }
  }
}

.ColorPicker {
  position: absolute;
  z-index: 2;
  width: 264px;
  top: 110px;
  right: 0;
}

.Buttons {
  margin-top: 15px;
  display: flex;
  column-gap: 24px;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  width: 100%;

  .Btn{
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

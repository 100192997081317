.Line {
  display: flex;
  align-items: flex-start;
  color: var(--color-secondary-lightest);

  &.NotActive {
    opacity: 0.5;
  }

  .Icon {
    position: relative;
    margin-right: 20px;
    fill: var(--color-secondary-lightest);

    &::before {
      content: " ";
      position: absolute;
      left: -40%;
      top: -5%;
      opacity: 0.1;
      background-color: #362e58;
      border-radius: 9999px;
      width: 20px;
      height: 20px;
    }
  }

  .Span{
    text-transform: capitalize;
  }
}

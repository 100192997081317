.Wrapper {
  .Input {
    display: flex;
    align-items: center;
    height: 64px;
    padding: 0 16px;
    background: var(--color-secondary);
    border-radius: 12px;

    .Prefix,
    .Postfix {
      font-size: 16px;
      line-height: 22px;
      color: #0e0606;
    }

    .Field {
      color: #0e060659;
      background: inherit;
      border-style: none;

      &[data-invalid="true"] {
        border-color: var(--color-error);
      }

      &::placeholder {
        color: #0e060659;
      }
    }
  }
}

.Wrapper {
  &.Horizontal {
    width: 580px;

    .Content {
      max-width: none;
    }
  }

  &.None {
    background: var(--color-m2);
  }

  .Content {
    max-width: 340px;
    position: relative;
    background: var(--color-modal-bg);
    border-radius: 16px;
    padding: 0 22px 32px;
    .ModalTitle{
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
      color: rgba(245, 245, 255, 1);
      width: 80%;
    }

    .ModalContent {
      display: flex;
      flex-direction: column;
      position: relative;
      row-gap: 16px;

      .Btns {
        margin-top: 15px;
        display: flex;
        column-gap: 24px;
        justify-content: center;

        &.NoBtn {
          display: none;
        }

        .Btn {
          flex: 1;
          white-space: nowrap;
        }

        .BtnCLose {
          flex: 1;
          background-color: inherit;
          color: var(--color-m7);
          text-align: center;
        }
      }
    }
  }
  @media (max-height: 410px) {
    .Content {
      padding: 0 16px 16px;
      .ModalTitle{
        font-size: 24px;
        font-weight: 600;
        line-height: 24px;
        width: 70%;
      }
      .ModalContent {
        row-gap: 10px;

        .Btns {
          margin-top: 10px;
        }
      }
    }
  }
}

.Wrapper {
  display: flex;
  margin: 32px 0;


  @media (max-width: 990px) {
    flex-direction: column-reverse;
    align-items: center;
  }

  .Bots {
    display: grid;
    grid-template-columns: repeat(4, 288px) !important;
    gap: 35px;

    @media (max-width: 1520px) {
      grid-template-columns: repeat(3, 288px) !important;
      padding-bottom: 24px;
    }

    @media (max-width: 1200px) {
      grid-template-columns: repeat(2, 288px) !important;
    }

    @media (max-width: 990px) {
      grid-template-columns: repeat(1, 1fr) !important;
      grid-auto-columns: 100%;
    }
  }

}

.Wrapper {
  display: none;

  @media (max-width: 670px) {
    display: flex;
    justify-content: center;
    margin-top: 16px;

    .Btn {
      font-size: 12px;
      line-height: 16px;
      width: 96px;
      height: 32px;
      border: 1px solid var(--color-error-lighter) !important;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

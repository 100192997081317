.Elements {
  .Title {
    font-size: 12px;
    color: var(--color-d7);
    margin-bottom: 18px;
  }

  .List {
    & > * + * {
      margin-top: 24px;
    }

    .Grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
    }
  }

  @media (max-width: 1330px) {
    .List {
      .Grid {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  @media (max-width: 1048px) {
    .List {
      .Grid {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }

  @media (max-width: 670px) {
    display: none;
  }
}

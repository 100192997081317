.Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  .Button {
    width: 248px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(147.55deg, #0168fe 0.08%, #0154d0 100%) !important;
    font-size: 16px;
    font-weight: 500;
    border-radius: 100px;
  }
}

.Page {
  display: flex;
  column-gap: 30px;

  @media (max-width: 1245px) {
    flex-direction: column;
  }

  .Content {
    display: flex;
    flex-direction: column;
    padding: 56px;

    @media (max-width: 500px) {
      padding: 16px;
    }
  }

  .Container {
    display: flex;

    .Body {
      display: flex;
      flex-direction: column;
      margin-top: 24px;

      @media (max-width: 876px) {
        align-items: center;
      }

      .Header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--color-secondary-lightest-opace);

        .Tabs {
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 16px;
          line-height: 36px;

          & > * + * {
            margin-left: 8px;
          }

          .Tab {
            position: relative;
            color: var(--color-secondary-lightest);
            opacity: 0.3;
            transition: opacity var(--animation-transition);
            cursor: pointer;

            &::after {
              position: absolute;
              content: " ";
              left: 0;
              bottom: -20px;
              height: 2px;
              width: 0;
              background: var(--color-secondary-lightest);
              transition: width var(--animation-transition);
            }

            &:hover {
              opacity: 1;
            }

            &.Active {
              opacity: 1;

              &::after {
                width: 40px;
              }
            }
          }
        }
      }
    }

    .ChatList {

    }

  }


  .Tags {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2px;
  }
}

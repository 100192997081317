.Wrapper {
  .Header {
    & > * + * {
      margin-top: 8px;
    }
    margin-bottom: 32px;
  }

  .Form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    & > * + * {
      margin-top: 32px;
    }

    .Button {
      width: 256px;
      margin-top: 8px;
    }
  }
}

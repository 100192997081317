.NoPayHistoryWrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 840px;
  width: 100%;
  position: absolute;
  padding: 64px 0;
  border-radius: 4px;
  background: rgba(30, 30, 36, 1);
  flex-direction: column;
  &[data-type='mobile']{
    padding: 0;
    background: transparent;
  }
  .NoPaymentTextBlock{
    width: 295px;
    h4{
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      color: rgba(208, 208, 218, 1);
      text-align: center;
    }
  }
}

.NoPayHistoryWrapperMobile{
  padding: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 840px;
  width: 100%;
  position: absolute;
  flex-direction: column;
  .NoPaymentTextBlock{
    width: 270px;
    h4{
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: rgba(208, 208, 218, 1);
      text-align: center;
    }
  }
}

.Card {
  padding: 12px 10px 30px;
  background: #e3e3e3;
  border-radius: 8px;
  width: 202px;

  .Title {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-secondary-darkest);
    margin: 0;
  }

  .Header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .Legend {
      margin-left: 6px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: var(--color-secondary-darker);
    }
  }

  .IconWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .Icon {
      fill: var(--color-secondary-darkest);
      margin-right: 12px;
    }

    .Value {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: var(--color-secondary-darkest);
      opacity: 0.85;
    }
  }

  .ListWrapper {
    .List {
      padding: 0;
      margin: 0;
      margin-top: 8px;
      list-style: none;

      .ListItem {
        &:not(:first-child) {
          margin-top: 12px;
        }

        .Order {
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: var(--color-secondary-darkest);
          margin-right: 12px;
        }

        .ItemValue {
          font-size: 14px;
          line-height: 19px;
          color: var(--color-secondary-darker);
        }
      }
    }
  }
}

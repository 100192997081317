.Background {
  background: rgba(0, 0, 0, 0.32);
  overflow-y: auto;
  position: fixed;
  inset: 0;
  z-index: 20;
}

.ModalWrapper {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background: #0f0f13;
  border: 3px solid #2c2c35;
  border-radius: 24px;
  font-family: var(--font-primary);
  padding: 24px;
  color: var(--color-secondary-lightest);

  .Header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 24px;

    span {
      margin: 0;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      text-transform: uppercase;
    }

    .Button {
      position: initial;

      @media (max-width: 900px) {
        position: absolute;
        top: -20px;
        right: 0;
      }
    }
  }

  .Body {
    display: flex;
    align-items: center;

    @media (max-width: 900px) {
      flex-direction: column;
    }
  }

  .Template {
    position: relative;
    margin-right: 20px;
    border-radius: 16px;
    overflow: hidden;

    @media (max-width: 900px) {
      margin-right: 0;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    .Image {
      height: 321.31px;
      width: 196.89px;
      background: var(--color-m0);
      object-fit: cover;
    }

    .Panel {
      position: absolute;
      display: flex;
      flex-direction: column;
      padding: 20px;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 160px;
      background: #0f0f13;
      opacity: 0.8;
      backdrop-filter: blur(16px);
      border-radius: 16px 16px 0 0;

      h3 {
        margin: 0;
        margin-bottom: 4px;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
      }

      p {
        flex: 1;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }

      .Button {
        display: flex;
        align-items: center;
        stroke: var(--color-secondary-lightest);

        .Icon {
          margin-left: 8px;
          transform: rotate(-90deg);
        }
      }
    }
  }
}

.Wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: none;

  &[data-open="false"] {
    z-index: -1;
    background: transparent;
    backdrop-filter: blur(0);
    transition: background var(--animation-transition), backdrop-filter var(--animation-transition),
      z-index var(--animation-transition) var(--animation-transition);

    .Nav {
      transform: translateX(calc(100% + 42px));
      transition: transform var(--animation-transition);

      .Margin{
        height: 25px;
      }

      .Adds{
        max-height: 135px;
      }

    }
  }

  &[data-open="true"] {
    z-index: 100;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(32px);
    transition: background var(--animation-transition), backdrop-filter var(--animation-transition);

    .Nav {
      transform: translateX(0);
      transition: transform var(--animation-transition);

      .Margin{
        height: 25px;
      }
    }
  }

  .Nav {
    position: relative;
    height: 100%;
    min-width: 256px;
    width: 256px;
    background: var(--color-d0);
    padding: 24px 16px;
    margin-right: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .Close {
      position: absolute;
      left: -28px;
      top: 16px;
    }
  }
}

.Header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;

  .Title {
    margin: 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;

    background: linear-gradient(
      90.43deg,
      rgba(255, 255, 255, 0.6) 0%,
      #ffffff 18.07%,
      rgba(255, 255, 255, 0.8) 83.79%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .CreateBtn {
    margin-left: 40px;
  }

  .Sorts {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .Label {
      margin-right: 20px;
      color: var(--color-secondary-lightest);
      opacity: 0.4;
    }

    .IconDown {
      margin-left: 8px;
      stroke: var(--color-secondary-lightest);
    }
  }

  @media (max-width: 876px) {
    gap: 15px;
    .CreateBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 200px;
      width: 100%;
      margin: 0;
    }
  }

  @media(max-width: 612px) {
    flex-direction: column;

    .Title {
      margin-bottom: 32px;
    }

    .Sorts {
      width: 100%;
      justify-content: space-between;
    }
  }
}

.Popover {
  position: relative;

  .Button {
    background: transparent;
    padding: 0;
    border-style: none;
  }

  .Panel {
    position: absolute;
    top: calc(100% + 16px);
    right: 0;
    z-index: 10;
  }
}

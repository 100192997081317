.Card {
  display: flex;
  flex-direction: column;
  padding: 16px 24px 24px 24px;
  justify-content: space-between;
  background: linear-gradient(204.74deg, #27303a 15.77%, #2d2c32 99.27%);
  border-radius: 16px;
  max-height: 355px;
  height: 100%;

  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ToggleText {
      font-size: 14px;
      color: var(--color-m7);
    }
  }

  .Name {
    font-size: 20px;
    line-height: 27px;
    height: 54px;
    color: var(--color-secondary-lightest);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Text {
    font-size: 12px;
    line-height: 16px;
    color: var(--color-m8);
    opacity: 0.7;
    height: 50px;
    overflow-y: hidden;
    text-overflow: ellipsis;
    margin: 24px 0;
  }

  .Btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .NoBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
  }
}

.Root {
  height: 51px;
  background: #1D1D22;

  .Inner {
    & > * {
      color: var(--color-d9-input);
      padding: 16px;
      font-weight: 400;
      font-size: 14px;
    }

    .Status {
      display: flex;
      gap: 5px;
      align-items: center;

      .Text {
        color: var(--color-transaction-input);
      }

      .Error {
        color: var(--color-error);
      }

      .Check {
        fill: transparent;
        stroke: var(--color-transaction-input);


      }
    }
  }
}

.Wrapper {
  background: var(--color-m0);
  padding: 32px 20px;
  height: calc(100vh - 64px);
  width: 344px;
  position: fixed;
  top: 64px;
  right: 0;

  .Top {
    display: flex;
    flex-direction: column;
    align-items: center;

    .Label {
      font-size: 14px;
      line-height: 19px;
      color: var(--color-m7);
      margin-bottom: 40px;
    }

    .Avatar {
      border-radius: 50%;
      margin-bottom: 24px;
    }

    .Username {
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      color: var(--color-secondary-lightest);
      margin-bottom: 8px;
    }

    .DateTime {
      color: var(--color-m8);
      opacity: 0.7;
    }

    .DeleteBtn {
      margin: 24px 0;
    }
  }

  @media (max-width: 876px) {
    top: 56px;
  }

  @media (max-width: 704px) {
    display: none;
  }
}

.Root {
  padding: 40px 56px;
  display: flex;
  flex-direction: column;
  min-height: 90vh;

  @media (max-width: 1024px) {
    padding-bottom: 10rem;
  }
}

.ErrorMsg {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 12px;
  color: var(--color-error);
  margin-top: 8px;

  .Text {
    width: 280px;
  }

  .ErrorIcon {
    fill: var(--color-error);
    margin-top: 2px;
  }
}

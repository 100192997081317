.Wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  min-height: 60vh;
  margin: 0 auto;
  text-align: center;

  .Img {
    margin-bottom: 40px;
  }

  .Title {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    font-family: var(--font-h4);
    color: var(--color-secondary-lightest);
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 24px;
  }

  .Text {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-m8);
    opacity: 0.8;
    margin-top: 0;
    margin-bottom: 32px;
  }

  .Footer {
    margin-top: 32px;
  }

  @media (max-width: 600px) {
    min-height: unset;
    margin-top: 30px;

    .Img {
      margin-bottom: 32px;

      img {
        width: 110px;
        height: 110px;
      }
    }

    .Title {
      font-size: 16px;
      line-height: 30px;
      margin-bottom: 12px;
    }
  }
}

.Header {
  position: sticky;
  top: 0;
  z-index: 10;
  background: rgba(20, 20, 20, 0.8);
  backdrop-filter: blur(40px);
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 35px;

  .Adds{
    width: 60%;
    padding: 5px;
  }


  .HeaderContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 56px;
    width: 100%;
    gap: 20px;

    .HeaderControls {
      display: flex;
      align-items: center;
      list-style: none;
      margin: 0;
    }
  }

  @media (max-width: 980px) {
    .HeaderContent {
      padding: 0 20px;
    }
  }

  @media (max-width: 876px) {
    display: none;
  }
}

.Page {
  .Header {
    display: flex;
    align-items: center;
    margin: 20px 0;

    .SearchField {
      width: 100%;

      input {
        width: 100%;
      }
    }

    .AddBtn {
      margin-left: 26px;

      .AddBtnIcon {
        fill: var(--color-secondary-lightest);
      }
    }
  }

  .List {
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: 12px;
    }
  }

  @media (max-width: 434px) {
    .Header {
      .AddBtn {
        height: 32px !important;
        min-width: 32px !important;

        .AddBtnIcon {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

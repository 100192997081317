.Wrapper {
  & > * + * {
    margin-top: 24px;
  }

  @media (max-width: 670px) {
    & > * + * {
      margin-top: 16px;
    }
  }
}

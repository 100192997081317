.Tariff {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 24px;
  gap: 24px;

  @media (max-width: 1046px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 924px) {
    display: flex;
    flex-direction: column;
    gap: unset;

    & > * + * {
      margin-top: 24px;
    }
  }

  @media (max-width: 876px) {
    display: grid;
    gap: 24px;
    margin-right: 20px;

    & > * + * {
      margin-top: 0;
    }
  }

  @media (max-width: 740px) {
    display: flex;
    flex-direction: column;
    gap: unset;

    & > * + * {
      margin-top: 16px;
    }
  }
}

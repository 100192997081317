.Page {
  padding: 24px 20px 80px;

  .Title {
    margin-bottom: 20px;
  }

  .Grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }

  @media (max-width: 826px) {
    .Grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 558px) {
    .Grid {
      display: flex;
      flex-direction: column;
      gap: unset;

      & > * + * {
        margin-top: 12px;
      }
    }
  }
}

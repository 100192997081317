.Wrapper {
  display: flex;

  // alignment

  &[data-alignment="left"] {
    justify-content: flex-start;
  }

  &[data-alignment="center"] {
    justify-content: center;
  }

  &[data-alignment="right"] {
    justify-content: flex-end;
  }

  .Button {
    width: 184px;
    height: 52px;
    border-style: none;

    // border

    &[data-has-border="true"] {
      padding: 1.6px;
    }

    &[data-has-border="false"] {
      padding: 0;
    }

    // block style

    &[data-block-style="rounded"] {
      border-radius: 100px;

      .Content {
        border-radius: 100px;
      }
    }

    &[data-block-style="square"] {
      border-radius: 8px;

      .Content {
        border-radius: 8px;
      }
    }

    .Content {
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      padding: 0 32px;
      font-size: 14px;
      height: 100%;

      svg {
        margin-right: 16px;
      }

      .IconFill {
        fill: var(--color-secondary-lightest);
      }

      // vk

      .IconVkStroke {
        fill: #0177ff;
      }

      // viber

      .IconViberStroke {
        fill: #7360f3;
      }

      // whatsapp

      .IconWhatsappStroke {
        fill: #1fc75e;
      }

      // instagram

      .IconInstaStroke {
        fill: url(#instagram-gradient);
      }

      // tg
      .IconTgStroke {
        fill: #59a5d5;
      }
    }
  }
}

@import "../../../../styles/_mixins.scss";

.Root {
  display: flex;
  flex-direction: row;
  gap: 24px;
  overflow-x: auto;
  margin-bottom: 20px;
  padding-bottom: 23px;
  @include scroll-bar;

  &::-webkit-scrollbar {
    height: 5px;
  }

}

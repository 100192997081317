@import "../Navigation/Navigation.module.scss";

.Wrapper {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 16px;
  padding-left: 21px;
  text-decoration: none;
  fill: var(--color-m4);
  color: var(--color-m4);
  cursor: pointer;
  transition: padding var(--animation-transition);

  &:hover,
  &:focus {
    text-decoration: none;
  }

  p {
    margin-bottom: 0;
  }

  .Text {
    opacity: 0;
    transition: opacity .1s;
  }

  .Banner {
    position: absolute;
    border: 1px solid var(--color-m2);
    border-radius: 16px;
    width: 160px;
    padding: 16px;
    padding-left: 50px;
    left: 50px;
    transform: translateX(-30px);
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    display: none;
  }

  .Background {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    border-radius: 8px;
    padding: 4px;
  }

  &.Active {
    fill: var(--color-secondary-lightest);
    color: var(--color-secondary-lightest);
  }
}

[data-active="false"] {
  .Wrapper {
    padding-left: ($closed - $icon) * 0.5 - 6px;

    &:hover {
      fill: var(--color-secondary-lightest);
      color: var(--color-secondary-lightest);

      .Background {
        background: var(--color-blue);
      }

      .Banner {
        display: block;
      }
    }
  }
}

[data-active="true"] {
  .Wrapper {
    display: flex;

    .Text {
      opacity: 1;
      transition: opacity var(--animation-label-font);
    }

    &.Active,
    &:hover,
    &:focus {
      &::after {
        content: " ";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        bottom: 5px;
        left: 0;
        width: 4px;
        height: 42px;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        background: var(--color-blue);
      }
    }

    &:not(.Active) {
      &:hover,
      &:focus {
        fill: var(--color-m7);
        color: var(--color-m7);

        &::after {
          opacity: 0.3;
        }
      }
    }
  }
}

.Wrapper {
  background: linear-gradient(
      244.97deg,
      rgba(249, 45, 253, 0.14) 0%,
      rgba(223, 58, 252, 0.849524) 13.02%,
      rgba(115, 112, 249, 0.21) 43.75%,
      rgba(13, 163, 247, 0.84) 82.81%,
      rgba(13, 163, 247, 0.19) 100%
    ),
    linear-gradient(0deg, #0f0f13, #0f0f13);
  border-radius: 16px;
  padding: 1px;
  align-self: flex-start;
  max-width: 288px;
  position: fixed;
  bottom: 24px;
  right: 24px;

  &[data-no-fancy-border="true"] {
    background: var(--color-m0);
    border: 3px solid var(--m-2-outline, #2c2c35);
  }

  .Card {
    position: relative;
    background: rgba(15, 15, 19, 0.8);
    border-radius: 16px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    column-gap: 16px;
    color: var(--color-secondary-lightest);

    .Header {
      display: flex;
    }

    .ButtonClose {
      display: flex;
      align-items: flex-start;
    }

    .Title {
      font-size: 16px;
      line-height: 22px;
      margin: 0;
    }

    .Text {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: var(--color-gray);
      opacity: 0.7;
      margin: 16px 0;
      max-height: 33px;
      overflow-y: hidden;
      text-overflow: ellipsis;
    }

    button {
      height: 40px !important;
    }

    .BtnMobile {
      display: none;
    }
  }
}

.Card {
  min-height: 214px;
  background: var(--color-m0);
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 16px;

  .Content {
    padding: 32px;
    display: flex;
    flex-direction: column;
    position: relative;
    .infoIconBlock {
      position: absolute;
      top: 32px;
      right: 32px;
      width: 24px;
      height: 24px;
      svg {
        cursor: pointer;
      }

      &:hover {
        .Border {
          display: block;
          position: absolute;
          top: -18px;
          right: -18px;
        }
      }

      .Border{
        background: linear-gradient(244.97deg, rgba(249, 45, 253, 0.14) 0%, rgba(223, 58, 252, 0.849524) 13.02%, rgba(115, 112, 249, 0.21) 43.75%, rgba(13, 163, 247, 0.84) 82.81%, rgba(13, 163, 247, 0.19) 100%),
        linear-gradient(0deg, #0F0F13, #0F0F13);
        display: none;
        border-radius: 16px;
        z-index: 999;
        .infoBlock {
          padding: 16px 52px 16px 16px;
          width: 318px;
          position: relative;
          box-shadow: 0 16px 40px 0 rgba(33, 111, 224, 0.12);
          background: linear-gradient(0deg, #0F0F13, #0F0F13);;
          margin: 2px;
          border-radius: 16px;
          svg {
            position: absolute;
            top: 16px;
            right: 16px;
          }
          h3 {
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            color: var(--color-text-primary);
            margin-top: 0;
          }
          p {
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            color: var(--color-text-primary);
          }
        }
      }
    }

    .Wrapper {
      &:not(:last-child) {
        margin-bottom: 24px;
      }

      .Text {
        font-weight: 400;
        font-size: 16px;
        color: var(--color-text-primary);
        opacity: 0.5;
        margin: 8px 0 0 0;
      }

      .Number {
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        font-size: 24px;
        margin: 0;
        white-space: nowrap;
        color: var(--color-text-primary);
      }
    }
  }
}

@media (max-width: 400px) {
  .Card {

    .Content {
      .infoIconBlock {

        .Border{
          border-radius: 16px;
          .infoBlock {
            padding: 8px 16px 8px 8px;
            width: 260px;
            border-radius: 16px;
            svg {
              position: absolute;
              top: 8px;
              right: 8px;
            }
            h3 {
              font-size: 12px;
              line-height: 14px;
              margin-top: 8px;
            }
            p {
              font-size: 12px;
              line-height: 12px;
            }
          }
        }
      }
    }
  }
}

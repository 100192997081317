.Card {
  position: relative;
  overflow: hidden;
  background: linear-gradient(238.16deg, rgba(39, 48, 58, 0.7) 30.74%, rgba(45, 44, 50, 0.7) 100%);
  border-radius: 16px;
  min-height: 185px;
  display: flex;
  flex-direction: column;
  padding: 26px 24px;
  padding-top: 40px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  .MobileIcon {
    display: none;
  }

  @media (max-width: 496px) {
    min-height: unset;
    padding: 24px;

    .MobileIcon {
      display: block;
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
      fill: var(--color-m7);
    }
  }

  @media (max-width: 420px) {
    min-width: unset;
  }
}

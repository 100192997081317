.Card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 228px;

  .Value {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: rgba(36, 36, 36, 0.85);
    margin-bottom: 8px;
  }

  .Name {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-secondary-darkest);
    margin-bottom: 6px;
  }

  .Legend {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-secondary-darker);
  }
}

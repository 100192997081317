.Modal {
  font-family: var(--font-primary);

  .Title {
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: var(--color-text-primary);
    text-transform: uppercase;
  }

  .Par {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: var(--color-text-primary);
  }

  .Phone {
    margin-bottom: 16px;
  }

  .Available {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    align-self: flex-start;
    margin-left: 15px;
    margin-bottom: 32px;

    &.Error {
      color: var(--color-error);

      .Main {
        color: var(--color-error);
      }
    }

    .Main {
      color: var(--color-text-gray);
    }
    span {
      color: var(--color-text-primary);
    }
  }

  .Btns{
    margin-top: 15px;
    display: flex;
    column-gap: 24px;
    justify-content: space-between;
  }

  .Info {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    .Pay {
      width: 100px;
      height: 75px;
    }
  }
}

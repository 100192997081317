.Root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
  border: 1px solid var(--color-m2);
  background: var(--color-m1);
  padding: 16px;
  width: 100%;
  min-width: 21rem;
  max-height: 24rem;
  height: 100%;

  .UpperCard{
    .CardHeader {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 16px;
      height: inherit;
    }

    .Subtitle {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      color: var(--color-m7);
    }

    .Description {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      color: var(--color-text-primary);
      margin-bottom: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      height: 44px;
    }
  }



  .Btn{
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    align-content: flex-end;
  }
}

.Modal {
  position: absolute;
  z-index: 100;
  top: 14px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--color-secondary-lightest);
  padding: 40px 62px;
  border: 1px solid var(--color-secondary-dark);
  border-radius: 24px;
  width: 400px;

  .CloseIcon {
    position: absolute;
    top: 22px;
    right: 22px;
    fill: rgba(61, 90, 128, 0.5);
  }
}

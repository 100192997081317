.Nav {
  display: none;
}

@media (max-width: 784px) {
  .Nav {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 68px;
    background: var(--color-header-bg-my-pages);
    backdrop-filter: blur(4px);
    padding: 14px;

    .Content {
      width: 280px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;

      .PublishBtn {
        height: 40px;
      }

      .Btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 36px;
        border-radius: 100px;
        border: 1px solid var(--color-m3);
        background: transparent;
        padding: 0;

        .ArrowIcon {
          fill: var(--color-secondary-lightest);
          transform: rotate(180deg);
        }

        .SaveIcon {
          stroke: var(--color-secondary-lightest);
        }
      }
    }
  }
}

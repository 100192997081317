.Modal {
  border: none;
  padding: 0 !important;

  .InnerContainer {
    background: var(--g-4-grey-card, linear-gradient(207deg, #27303A 0%, #2D2C32 100%));
    width: 100%;
    border-radius: 16px;
    padding: 16px;

    .Title {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px;
      color: var(--color-text-primary);
    }

    .Text {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      color: var(--color-m7);
    }
  }

  .Children{
    margin: 24px 0;
  }

  .Buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 8px;

    .Button{
      flex: 1;
      justify-content: center;
    }
  }

}

.Title {
  margin-top: 0;
  margin-left: 10px;
  margin-bottom: 48px;
  font-weight: 800;
  font-size: 32px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  background: linear-gradient(
    90.43deg,
    rgba(255, 255, 255, 0.6) 0%,
    #ffffff 18.07%,
    rgba(255, 255, 255, 0.8) 83.79%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  &[data-no-margin="true"] {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 600px) {
  .Title {
    font-size: 28px;
    font-weight: 700;
    margin-left: 0px;
  }
}

@media (max-width: 425px) {
  .Title {
    font-size: 24px;
    line-height: 30px;
  }
}

.title{
  margin-top: 0 !important;
  margin-bottom: 27px;
  font-size: 12px;
  line-height: 16px;
}
.reorderGroup{
  margin-left: -40px;
}
.block{
  padding: 10px;
  height: 52px;
  background: rgba(52, 52, 52, 1);
  border-radius: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  .backgroundBlock{
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
  .textBlock{
    .title{
      margin: 0;
      font-size: 12px;
      line-height: 16px;
    }
    .subtitle{
      margin: 0;
      font-size: 12px;
      line-height: 16px;
      color: rgba(91, 91, 101, 1);
    }
  }
  p{
    color: rgba(255, 255, 255, 1);
    margin: 0;
    font-size: 12px;
    line-height: 16px;
  }
  img{
    width: 32px;
    height: 32px;
    border-radius: 2px;
  }
  .rightBlock{
    margin-left: auto;
    display: flex;
    align-items: center;
    column-gap: 10px;
    .blocksCounter{
      width: 34px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(16, 16, 16, 1);
      border-radius: 8px;
      margin-left: auto;
      p{
        font-size: 12px;
        line-height: 16px;
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}

.PlusLine {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 1px;
  background: var(--color-element-icon);

  &:last-child {
    transform: translate(-50%, -50%) rotate(90deg);
  }
}

@import "../../../../../../styles/_mixins.scss";

.Wrapper {
  .Cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 26px;

    .GhostCard {
      display: none;
    }
  }

  @media (max-width: 1144px) {
    .Cards {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 876px) {
    //width: calc(100vw - 20px);
    overflow-x: auto;
    margin-right: 10px;
    @include scroll-bar;

    .Cards {
      display: flex;
      gap: 20px;
      margin-bottom: 12px;

      & > div {
        max-width: 300px;
      }

      .GhostCard {
        display: block;
      }
    }
  }
}

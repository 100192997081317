.Loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 10px;

  .Loader{
    animation: spin-animation 1.5s infinite;

    @keyframes spin-animation {

      100% {
        transform: rotate(0deg);
      }

      0% {
        transform: rotate(359deg);
      }
    }

  }
}

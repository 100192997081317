@import '../../../../styles/mixins';


.Root {
  display: flex;
  gap: 16px;
  overflow-x: auto;
  padding-bottom: 16px;
  margin-bottom: 16px;
  @include scroll-bar;

  &::-webkit-scrollbar {
    height: 5px;
  }
}

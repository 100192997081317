.Frame,
.NoVideo {
  margin: 0 auto;
}

.Frame {
  border-style: none;
  display: block;
}

.NoVideo {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.5);

  svg {
    fill: var(--color-secondary-lightest);
  }
}

@media (max-width: 380px) {
  .Frame,
  .NoVideo {
    height: 132px !important;
  }
}

.Card {
  width: 100%;
  height: 318px;
  background: #fcfcfc;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .Icon {
    margin-bottom: 24px;
    fill: #000;
  }

  .Text {
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: var(--color-secondary-darkest);
    max-width: 274px;
  }
}

.container{
  position: relative;
  padding-left: 38px;
  .checkbox{
    position: absolute;
    top: 24px;
    left: 0;
  }
}

.NoApplications {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--color-secondary-lightest);
  opacity: 0.5;
}

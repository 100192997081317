.Root {
  width: 100% !important;
  max-width: 749px !important;

  @media (max-width: 1024px) {
    max-width: 340px !important;
    max-height: 590px;
    height: 100%;
    overflow: auto;
  }

  .Title {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    color: var(--color-secondary-lightest);
  }

  .Par {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: var(--color-secondary-lightest);
  }

  .Btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 30px;
    margin-top: 30px;

    @media (max-width: 1024px) {
      flex-direction: column-reverse;

      & > * {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .CardsGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 30px;

    .Card {
      flex: 1;
      padding: 15px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #1E1E24;
      height: 101px;
      max-width: 211px;
      width: 100%;
      border-radius: 16px;
      cursor: pointer;

      &.ActiveCard {
        background: var(--background-magenta);
      }

      @media (max-width: 1024px) {
        max-width: 100%;
      }
    }

    .NumBack {
      height: inherit;
      width: inherit;
      max-width: inherit;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .Wrapper {
        display: flex;
        align-items: center;
        justify-content: center;


        .Top {
          font-size: 48px;
          font-weight: 700;
          font-family: "Montserrat", sans-serif;
          color: var(--color-text-primary);
          margin: 0;
        }

        span {
          font-size: 14px;
          font-weight: 400;
          font-family: "Montserrat", sans-serif;
          color: var(--color-text-primary);
          margin-top: 20px;
          margin-left: 9px;
        }
      }

      .Back {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 70px;
        font-weight: 700;
        color: var(--color-text-primary);
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.16) 20.51%,
            rgba(255, 255, 255, 0) 79.49%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;

        @media (max-width: 1024px) {
          left: 40px;
        }
      }
    }
  }

}



.Wrapper {
  padding: 10px;
  border: 1px solid transparent;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &[data-edit-mode="true"] {
    border-color: #d9d9d9;
  }

  .Menu {
    position: absolute;
    left: calc(100% + 8px);
    bottom: 0;
    padding: 12px 16px !important;

    .Delete {
      fill: var(--color-error);
    }

    & > * + * {
      margin-left: 20px;
    }
  }
}

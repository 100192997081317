.Card {
  position: relative;
  height: 149px;
  border-radius: 16px;
  border: 2px solid rgb(59, 65, 74);
  background: linear-gradient(204.74deg, #27303a 15.77%, #2d2c32 99.27%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .ActiveCard {
    display: flex;
    flex-direction: column;
    width: inherit;
    height: 100%;
    padding: 16px;
    justify-content: space-between;


    .ToolTipWrapper{
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h1{
        font-size: 20px;
        font-weight: 600;
        line-height: 27px;
        text-align: left;
      }

      p{
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
      }
    }

    .UpperCard {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: inherit;
      gap: 20px;

      .UpperCardImg {
        height: 21px;
      }

      .NumBack {
        height: inherit;
        width: inherit;
        max-width: inherit;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .Wrapper {
          display: flex;
          align-items: center;
          justify-content: center;


          .Top {
            font-size: 48px;
            font-weight: 700;
            font-family: "Montserrat", sans-serif;
            color: var(--color-text-primary);
            margin: 0;
          }

          span {
            font-size: 14px;
            font-weight: 400;
            font-family: "Montserrat", sans-serif;
            color: var(--color-text-primary);
            margin-top: 20px;
            margin-left: 9px;
          }
        }

        .Back {
          position: absolute;
          right: 0;
          font-size: 70px;
          font-weight: 700;
          color: var(--color-text-primary);
          background: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0.16) 20.51%,
              rgba(255, 255, 255, 0) 79.49%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }
      }
    }
  }

  .Label {
    font-weight: 500;
    font-size: 16px;
    height: 100%;
    margin-top: 5px;
    margin-right: 5px;
  }

  &.CardActive {
    border: 3px solid #9136FAFF;
    background: linear-gradient(to left, #27303A 18.11%, rgba(101, 132, 255, 0.03) 120%), linear-gradient(279.72deg, #6584FF 0%, #DB74FF 100%), linear-gradient(to right, #27303A 18.11%, rgba(101, 132, 255, 0.03) 70%);
    width: 100%;
    justify-content: space-between;
    cursor: auto;

    &:hover {
      background: linear-gradient(to left, #27303A 18.11%, rgba(101, 132, 255, 0.03) 120%), linear-gradient(279.72deg, #6584FF 0%, #DB74FF 100%), linear-gradient(to right, #27303A 18.11%, rgba(101, 132, 255, 0.03) 70%);
    }
  }


  .ActiveCheckbox {
    position: absolute;
    top: 13px;
    right: 23px;
  }

  &:hover {
    background: linear-gradient(147.55deg, #0168fe 0.08%, #0154d0 100%);
  }

  @media (max-width: 650px) {
    img {
      transform: scale(0.9);
    }
  }
}

.Root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
  border: 1px solid var(--color-m2);
  background: var(--color-m1);
  padding: 16px;
  height: 200px;


  .Subtitle {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375;
    color: var(--color-m7);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 14.4rem;
  }


  .CardHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .Buttons {
    display: flex;
    margin-bottom: 0;
    justify-content: space-between;
    gap: 8px;

    .SelectBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 2;
      max-width: 170px;
    }

    .DeleteButton {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      max-width: 170px;
      border-color: var(--color-error) !important;
    }

    .MoveBtn {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

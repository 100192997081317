.Wrapper {
  position: relative;
  height: 62px;
  width: 100%;

  .Button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 62px;
    background: #d9d9d9;
    border-radius: 8px;
    cursor: pointer;
    border-style: none;
    width: 100%;

    .Icon {
      fill: var(--color-secondary-darkest);
    }

    .Text {
      margin-left: 30px;
      color: var(--color-secondary-darkest);
      font-size: 14px;
      min-width: 68px;
    }
  }

  .SideIcon {
    position: absolute;
    right: -40px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.Wrapper {
  display: flex;
  align-items: center;

  .Link {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-text-primary);
    cursor: pointer;
    margin-right: 8px;
    text-decoration: none;
  }

  .Icon {
    fill: #ffffff
  }
}

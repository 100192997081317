.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .Left {
    display: flex;
    align-items: center;

    .Avatar {
      margin-right: 16px;
    }

    .Info {
      display: flex;
      flex-direction: column;

      .FullName {
        font-size: 12px;
        line-height: 16px;
        color: var(--color-secondary-lightest);
        margin-bottom: 4px;
      }

      .Email {
        font-size: 10px;
        line-height: 14px;
        color: var(--color-secondary-lightest);
        opacity: 0.2;
      }
    }
  }

  .Icon {
    fill: var(--color-gray-darker);
    opacity: 0.2;
    cursor: pointer;
  }
}

.Options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > * + * {
    margin-top: 8px;
  }

  .Option {
    font-size: 14px;
    line-height: 18px;

    &.Link {
      text-decoration: none;
      color: var(--color-m8);
    }

    &.LogOut {
      color: var(--color-a6-coral-err);
      background: transparent;
      padding: 0;
      border-style: none;
    }
  }
}

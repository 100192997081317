.Header {
  position: sticky;
  display: flex;
  top: 0;
  align-items: center;
  justify-content: space-between;
  padding: 16px 56px;
  background: var(--color-header-bg-my-pages);
  backdrop-filter: blur(40px);
  z-index: 2;
  height: 64px;

  .Links {
    & > * + * {
      margin-left: 24px;
    }

    .Text {
      position: relative;
      font-weight: 600;
      font-size: 16px;
      text-decoration: none;
      cursor: pointer;
      color: var(--color-m7);
      opacity: 0.6;
      transition: opacity var(--animation-transition);

      &::after {
        position: absolute;
        border-radius: 0px 0px 4px 4px;
        content: " ";
        z-index: 10;
        left: 50%;
        top: -20px;
        height: 4px;
        width: 0;
        background: var(--color-magenta);
        transition: width var(--animation-transition), left var(--animation-transition);
      }

      &:hover {
        opacity: 1;
      }

      &.Active {
        font-weight: 800;
        background: linear-gradient(
          90.43deg,
          rgba(255, 255, 255, 0.6) 0%,
          #ffffff 18.07%,
          rgba(255, 255, 255, 0.8) 83.79%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        opacity: 1;

        &::after {
          left: 0;
          width: 100%;
        }
      }

      &.Disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.2;
      }
    }
  }
}

.TabsMobile {
  display: none;
}

@media (max-width: 876px) {
  .TabsDesktop {
    display: none !important;
  }

  .TabsMobile {
    display: flex;
  }
}

.Section {
  padding: 74px 46px 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;

  .Content {
    margin-top: 32px;
    max-width: 840px;

    .Title {
      margin-bottom: 30px;
    }
  }

  @media (max-width: 776px) {
    padding: 24px 0;

    .Content {
      margin-top: 0;

      .Title {
        margin-bottom: 16px;
        padding-left: 20px;
      }
    }

    .GoBack {
      display: none;
    }
  }
}

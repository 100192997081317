.Wrapper {
  display: flex;
  align-items: center;

  &[data-label-left="true"] {
    flex-direction: row-reverse;

    .Label {
      margin-left: 0;
      margin-right: 16px;
    }
  }

  .Label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-secondary-lightest);
    opacity: 0.7;
    cursor: pointer;
    margin-left: 16px;
    margin-bottom: 0;
  }

  .Switch {
    position: relative;
    width: 44px;
    height: 24px;

    .Input {
      position: absolute;
      z-index: 1;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;

      &:checked {
        & + .Slider {
          background: #1ed731;
          box-shadow: 0px 0px 4px rgba(31, 213, 49, 0.08),
            0px 16px 10px -9px rgba(30, 215, 49, 0.16), 0px 4px 8px rgba(8, 233, 44, 0.16),
            0px 16px 8px rgba(30, 215, 49, 0.03);

          .SliderHead {
            transform: translateX(20px);
          }
        }
      }
    }

    .Slider {
      position: absolute;
      cursor: pointer;
      inset: 0;
      border-radius: 100px;
      background: #474a4d;
      box-shadow: none;
      transition: background var(--animation-transition);

      .SliderHead {
        position: absolute;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        left: 2px;
        top: 2px;
        background: linear-gradient(180deg, #ffffff -7.14%, #b9b9b9 100%);
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16), 0px 4px 4px rgba(0, 0, 0, 0.25);
        transition: transform var(--animation-transition);

        &.DisabledSlideHead {
          display: flex;
          align-items: center;
          justify-content: center;
          background: var(--color-secondary-grey2);
          border: 1px solid #9B9B9B;
        }
      }
    }
  }
}

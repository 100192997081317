.Color {
  text-transform: uppercase;
  cursor: pointer;
}

.Preview {
  width: 24px;
  height: 24px;
  border: 2px solid var(--color-picker-preview-border);
  border-radius: 4px;
}

.Preview {
  width: 24px;
  height: 24px;
  position: relative;
  border-radius: 4px;
  cursor: pointer;

  .CrossLine {
    content: "";
    position: absolute;
    width: 10px;
    height: 1px;
    background: #fff;
    top: 50%;
    left: 50%;

    &[data-left] {
      transform: translate(-50%, -50%) rotate(50deg);
    }

    &[data-right] {
      transform: translate(-50%, -50%) rotate(-50deg);
    }
  }
}

.Card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 20px;
  border-bottom: 1px solid var(--color-m2);

  .Left {
    display: flex;

    .Info {
      text-align: left;
    }

    .Type {
      margin-right: 10px;
    }
  }

  .Info {
    display: flex;
    flex-direction: column;
    text-align: right;

    .Title {
      color: var(--color-m9);
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 4px;
    }

    .Time {
      font-size: 12px;
      line-height: 16px;
      color: var(--color-m5);
    }

    .Status {
      font-size: 10px;
      line-height: 14px;

      &[data-status="success"] {
        color: var(--color-a5-lime);
      }

      &[data-status="in-process"] {
        color: var(--color-blue-primary);
      }

      &[data-status="error"] {
        color: var(--color-a6);
      }
    }
  }
}

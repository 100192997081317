@import "../FinPassModal/FinPassModal.module.scss";

.ModalNew {
  max-height: 479px;
  height: auto;
}

.CardHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 36px;

  .Card {
    padding: 10px;
    max-width: 130px;
    height: 70px;
    border-radius: 12px;

    &.Selected {
      background-color: var(--color-payment-selected-bg);
      border: 1px solid var(--color-payment-selected);
    }
  }
}

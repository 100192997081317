.Root {
  display: flex;
  justify-content: space-between;
  border-radius: 16px;
  align-items: center;
  border: 1px solid var(--color-m2);
  background: var(--color-m1);
  padding: 16px;
  width: 100%;
  min-width: 21rem;
  max-height: 86px;
  height: 100%;
  margin-bottom: 8px;

  .CardLeft {
    display: flex;
    align-items: center;

    .Img {
      width: 60px;
      height: 60px;
      border-radius: 60px;
      margin-right: 16px;
    }

    .CardText {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .CardTitle {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 1.375;
        color: var(--color-text-primary);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 14.4rem;
        margin: 0;
      }

      .SubTitle {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.357;
        opacity: .5;
        color: var(--color-m7);
        margin: 3px 0;
      }

      .Subscribers {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.357;
        color: var(--color-m7);
        margin: 0;
      }
    }
  }


  .CardRight {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    max-width: 15rem;
  }
}

.MobileCard {
  width: 100%;
  margin-bottom: 8px;
}

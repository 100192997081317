.Card {
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  padding: 24px 16px;
  background: linear-gradient(238.16deg, #27303a 30.74%, #2d2c32 100%);
  border-radius: 14px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-gray);

  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--color-m5);
  }

  .BodyWrapper {
    display: flex;
    column-gap: 14px;
  }

  .Body {
    margin: 0;
    margin-bottom: 30px - 20;
  }

  .Bottom {
    display: flex;
    align-items: center;
    column-gap: 16px;

    .Button {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }

  .Images {
    display: flex;
    align-items: center;
    column-gap: 4px;

    .Image {
      width: 24px;
      height: 24px;
      border-radius: 4px;
    }
  }
}

.Icon {
  align-items: flex-start;
}

.Clickable {
  cursor: pointer;
}

.Wrapper {
  &.Horizontal {
    width: 580px;

    .Content {
      max-width: none;
    }
  }

  &.None {
    background: var(--color-m2);
  }

  .Content {
    max-width: 340px;
    position: relative;
    background: var(--color-modal-bg);
    border-radius: 16px;
    padding: 24px;
    min-width: 30rem;

    .ModalContent {
      display: flex;
      flex-direction: column;
      position: relative;

      .Btns {
        margin-top: 15px;
        display: flex;
        column-gap: 24px;
        justify-content: center;

        &.NoBtn {
          display: none;
        }

        &.ColumnBtn {
          flex-direction: column-reverse;
          gap: 15px;
          align-items: center;
          justify-content: center;
          width: 100%;

          & > * {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
          }
        }

        .Btn {
          flex: 1;
          white-space: nowrap;
          justify-content: center;
        }

        .BtnCLose {
          flex: 1;
          background-color: inherit;
          color: var(--color-m7);
          text-align: center;
        }
      }
    }
  }
}

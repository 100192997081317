.Wrapper {
  position: relative;

  .Btn {
    background: transparent;
    padding: 0;
    border-style: none;
  }

  .Panel {
    position: fixed;
    z-index: 10;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 420px;
    overflow-x: hidden;
    overflow-y: auto;
    background: var(--color-d1);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 24px 20px;

    .Close {
      position: absolute;
      top: 20px;
      right: 20px;
      background: transparent;
      padding: 0;
      border-style: none;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      .Cross {
        fill: var(--color-secondary-lightest);
        opacity: 0.3;
      }
    }
  }

  .Enter {
    transition: transform var(--animation-transition);
  }

  .EnterFrom {
    transform: translateY(420px);
  }

  .EnterTo {
    transform: translateY(0);
  }

  .Leave {
    transition: transform var(--animation-transition);
  }
}

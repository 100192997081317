.Content {
  height: 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--color-m2);
  border: 1px dashed var(--image-input-border);
  border-radius: 12px;
  color: var(--color-secondary-lightest);

  .ImgError {
    margin-bottom: 11px;
  }

  .Blue {
    color: var(--color-blue);
  }

  .Icon {
    margin-bottom: 12px;
    fill: var(--color-blue);
  }

  .RecommendedSize {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: var(--image-input-hint);
    padding: 5px;
    text-align: center;
    margin: 0;
  }

  .Text {
    p {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: var(--color-m8);
      margin: 0;
    }
  }
}

.ActivityIndicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;

  &[data-active="true"] {
    background: var(--color-a5-lime);
  }

  &[data-active="false"] {
    background: var(--color-red);
  }
}

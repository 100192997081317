.Card {
  background: rgba(63, 63, 63, 0.5);
  backdrop-filter: blur(23px);
  border-radius: 16px;
  padding: 24px 16px 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .Content {
    .NameWrapper {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .Name {
        margin-left: 16px;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: var(--color-drag-pad-inactive);
      }
    }

    .Time {
      padding-left: 24px;
    }
  }

  .Btn {
    height: 32px;
    font-size: 14px;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;

    .Btn {
      margin-top: 24px;
      margin-left: 24px;
    }
  }

  @media (max-width: 704px) {
    flex-direction: row;
    align-items: center;

    .Btn {
      margin: 0;
    }
  }

  @media (max-width: 510px) {
    flex-direction: column;
    align-items: flex-start;

    .Btn {
      margin-top: 24px;
      margin-left: 24px;
    }
  }
}

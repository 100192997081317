.StatOn {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  .Title {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-m6);
  }

  .Blocks {
    margin-top: 32px;
    display: flex;
    column-gap: 32px;
    flex-wrap: wrap;

    .Border {
      position: relative;
      background: linear-gradient(90deg, #e80fe9 0%, #216fe0 100%),
        linear-gradient(0deg, #232426, #232426);
      border-radius: 8px;
      padding: 1px;
      margin-bottom: 20px;
      .Btn {
        &[datatype="links"] {
          background: rgba(35, 36, 38, 1);
          box-sizing: border-box;
        }
      }
      @supports (-ms-ime-align: auto) {
        .Border {
          background: -ms-linear-gradient(90deg, #e80fe9 0%, #216fe0 100%),
          -ms-linear-gradient(0deg, #232426, #232426);
        }
      }

      @supports (-moz-appearance: none) {
        .Border {
          background: -moz-linear-gradient(90deg, #e80fe9 0%, #216fe0 100%),
          -moz-linear-gradient(0deg, #232426, #232426);
        }
      }

      @supports (-webkit-marquee-left: right) {
        .Border {
          background: -webkit-linear-gradient(90deg, #e80fe9 0%, #216fe0 100%),
          -webkit-linear-gradient(0deg, #232426, #232426);
        }
      }
    }
    .Btn {
      width: 202px;
      height: 120px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: rgba(33, 111, 224, 1);
      backdrop-filter: blur(40px);
      color: rgba(255, 255, 255, 1);
      transition: box-shadow var(--animation-transition), color var(--animation-transition),
        background var(--animation-transition);
      cursor: pointer;

      &[datatype="mobileCard"] {
        background: linear-gradient(238.16deg, #27303a 30.74%, #2d2c32 100%);
        box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.4);
      }

      &:hover {
        background: rgba(30, 96, 192, 1);
        color: rgba(255, 255, 255, 1);
        box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.4);
      }

      .Text {
        margin: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
      }

      .Number {
        font-weight: 400;
        font-size: 32px;
        margin: 0;
      }
    }

    .mobileCarousel{
      display: none;
    }

    .StatNotification {
      color: var(--color-secondary-lightest);
      opacity: 0.3;
    }
  }
}

.carouselBtn {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 270px;
  top: 35%;
  border: none;
  border-radius: 50%;
  background: rgba(62, 62, 62, 0.97);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carouselPrevBtn {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  top: 35%;
  border: none;
  border-radius: 50%;
  background: rgba(62, 62, 62, 0.97);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  svg {
    transform: rotate(180deg);
  }
}

@media (max-width: 877px) {
  .StatOn{
    .Title{
      text-align: center;
    }
    .Blocks{
      .Btn{
        display: none;
      }
      .Border{
        display: none;
      }
      .mobileCarousel{
        display: block;
        margin-left: 30%;
        .Btn{
          display: flex;
          margin-left: 55px;
          &:hover {
            background: rgba(255, 255, 255, 0.05);
          }
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .StatOn{
    .Blocks{
      .mobileCarousel{
        margin-left: 26%;
      }
    }
  }
}

@media (max-width: 650px) {
  .StatOn{
    .Blocks{
      .mobileCarousel{
        margin-left: 22%;
      }
    }
  }
}

@media (max-width: 550px) {
  .StatOn{
    .Blocks{
      .mobileCarousel{
        margin-left: 18%;
      }
    }
  }
}

@media (max-width: 500px) {
  .StatOn{
    .Blocks{
      .mobileCarousel{
        margin-left: 16%;
      }
    }
  }
}

@media (max-width: 475px) {
  .StatOn{
    .Blocks{
      .mobileCarousel{
        margin-left: 10%;
      }
    }
  }
}

@media (max-width: 450px) {
  .StatOn{
    .Blocks{
      .mobileCarousel{
        margin-left: 9%;
      }
    }
  }
}

@media (max-width: 425px) {
  .StatOn{
    .Blocks{
      .mobileCarousel{
        margin-left: 8%;
      }
    }
  }
}

@media (max-width: 400px) {
  .StatOn{
    .Blocks{
      .mobileCarousel{
        margin-left: 5%;
      }
    }
  }
}

@media (max-width: 375px) {
  .StatOn{
    .Blocks{
      .mobileCarousel{
        margin-left: 2%;
      }
    }
  }
}

@media (max-width: 350px) {
  .StatOn{
    .Blocks{
      .mobileCarousel{
        margin-left: 0;
      }
    }
  }
}

.Btn {
  background: transparent;
  padding: 0;
  border-style: none;
  color: var(--color-blue);
  fill: var(--color-blue);
  display: flex;
  align-items: center;

  & > *:last-child {
    margin-left: 10px;
  }

  &:hover,
  &:active {
    color: var(--color-blue-active);
    fill: var(--color-blue-active);
    text-decoration: none;
  }

  @media (max-width: 496px) {
    display: none;
  }
}

.Card {
  max-width: 360px;
  height: 190px;
  border-radius: 16px;
  border: 2px solid rgba(43, 46, 54, 0.5);
  background: linear-gradient(225.76deg, rgba(43, 46, 54, 0.5) 3.43%, rgba(23, 25, 33, 0.5) 99.48%);
  backdrop-filter: blur(25px);
  font-family: var(--font-primary);
  display: flex;
  align-items: center;
  padding-left: 40px;

  .Balance {
    display: flex;
    flex-direction: column;

    .Text {
      width: 148px;
      font-weight: 500;
      font-size: 16px;
      color: #8a8a98;
      margin: 0;
    }

    .Wrapper {
      display: flex;
      margin-bottom: 12px;

      .Number {
        margin: 0;
        margin-right: 12px;
        font-weight: 800;
        font-size: 32px;
        color: #ffffff;
      }

      .Currency {
        font-weight: 700;
        font-size: 18px;
        color: var(--color-blue-primary) !important;
      }
    }
  }

  @media (max-width: 600px) {
    height: 122px;
    padding-left: 24px;

    .Balance {
      .Wrapper {
        .Number {
          font-size: 20px;
        }

        .Currency {
          font-size: 14px;
        }
      }
    }
  }
}

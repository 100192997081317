.Preview {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;

  @media (max-width: 784px) {
    padding-bottom: 88px;
    display: block;

    .Phone {
      flex: 1;
      max-width: unset !important;
      height: unset !important;
      max-height: unset !important;
      margin: unset;
      border-style: none !important;
      background: transparent;
      border-radius: 0 !important;
      padding: 0 !important;
      box-shadow: none !important;

      .PreviewComponent {
        border-radius: 0px;
        min-height: 100vh;
      }
    }
  }
}

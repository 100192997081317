@keyframes dropdown-animation {
  0% {
    opacity: 0;
    top: calc(100% - 20px);
  }

  100% {
    opacity: 1;
    top: calc(100% + 4px); // .DropdownBtn height + some space
  }
}

.Wrapper {
  position: relative;

  &.Btn {
    width: 180px;
    cursor: pointer;
  }

  .Menu {
    position: absolute;
    visibility: hidden;
    right: 0;
    z-index: 2;

    &[data-open="true"] {
      visibility: visible;
      animation: dropdown-animation var(--animation-transition) ease-in-out forwards;
    }
  }
}

.Page {
  padding: 40px 56px;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 876px) {
    .CardWrapper {
      display: none;
    }
  }

  @media (max-width: 500px) {
    padding: 16px;
  }
}

.BotsData {
  display: flex;
  flex-direction: column;
  column-gap: 16px;
}

.AdminBotCreatedBackground {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.AdminBotCreatedWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  width: 280px;
  height: 400px;
  background: linear-gradient(
    240deg,
    rgba(249, 45, 253, 0.14),
    rgba(223, 58, 252, 0.8495),
    rgba(115, 112, 249, 0.21),
    rgba(13, 163, 246, 0.84),
    rgba(13, 163, 246, 0.19)
  );
}

.AdminBotCreated {
  width: 273px;
  height: 393px;
  position: relative;
  background: var(--color-modal-bg);
  border: none;
  border-radius: 16px;

  p {
    width: initial !important;
  }
}

.MAddButton {
  border-radius: 16px !important;
  display: flex !important;
  justify-content: center !important;
  padding: 16px !important;

  svg {
    width: 24px !important;
    height: 24px !important;
    margin-right: 16px !important;
  }

  @media (min-width: 876px) {
    min-height: 122px;
  }
}

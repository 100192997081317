.componentWrapper{
  padding: 12px;
  background: rgba(52, 52, 52, 1);
  margin: 16px 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  .elementType{
    color: rgba(255, 255, 255, 1);
    margin: 0;
    font-size: 12px;
    line-height: 16px;
  }
  .elementContent{
    color: rgba(143, 143, 144, 1);
    margin: 0;
    font-size: 12px;
    line-height: 16px;
  }
}

.RootGroupCard {
  border-radius: 16px;
  background: rgba(30, 30, 36, 0.60);
  padding: 16px;
  margin-bottom: 9px;

  .Title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: var(--color-text-primary);
  }

  & > button > svg {
    background: black;
    border-radius: 50%;
    width: 24px;
    height: 24px;
  }


  .Par {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #D0D0DA;
    margin: 0;
  }

  .Switcher {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    width: 100%;
    max-width: 17rem;
  }

  @media (max-width: 1024px) {
    background: var(--color-m2);

    &:first-child {
      margin-top: 9px;
    }
  }
}




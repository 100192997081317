.Card {
  background: var(--color-m0);
  backdrop-filter: blur(4px);
  border-radius: 16px;
  padding: 20px 30px 32px;

  .Header {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .Avatars {
      display: flex;
      align-items: center;

      .AvatarWrapper {
        position: relative;

        .Avatar {
          border-radius: 50%;
        }

        .NotRenderedGroupsCount {
          position: absolute;
          inset: 0;
          border-radius: 50%;
          background: rgba(0, 0, 0, 0.8);
          font-size: 12px;
          color: var(--color-m9);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .Content {
    .Label {
      font-size: 12px;
      line-height: 16px;
      color: var(--color-m6);
      margin-bottom: 4px;
    }

    .Name {
      background: transparent;
      padding: 0;
      border-style: none;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: var(--color-secondary-lightest);

      .Icon {
        margin-left: 16px;
        fill: var(--color-secondary-lightest);
      }
    }
  }
}

.Wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .Options {
    display: flex;
    align-items: center;

    & > * + * {
      margin-left: 24px;
    }

    svg {
      fill: var(--color-secondary-lightest);
      opacity: 1;
    }

    .ColorPicker {
      right: unset;
      left: -8px;
      top: -8px;
    }
  }

  @media (max-width: 380px) {
    .Options {
      & > * + * {
        margin-left: 16px;
      }

      svg {
        fill: #cfcfcf;
      }
    }
  }
}

.DropdownMenu {
  width: 100%;
  visibility: hidden;
  background: var(--color-secondary-lightest);
  border: 1px solid var(--color-secondary-dark);
  box-shadow: 8px 16px 16px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding: 8px 0;

  .DropdownTitle {
    min-height: 42px;
    padding: 12px 24px;
    color: #293036;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .DropdownOption {
    height: 42px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    color: rgba(81, 81, 81, 0.61);
    font-weight: 600;
    font-size: 14px;
    background: transparent;
    width: 100%;
    border-style: none;
    text-align: left;
    cursor: pointer;
    transition: color var(--animation-transition);

    &:hover,
    &:focus,
    &[data-selected="true"] {
      color: #293036;
      transition: color var(--animation-transition);
    }
  }
}

.Root {
  display: flex;
  gap: 24px;

  .LeftGroups {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    @media (max-width: 1250px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 650px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

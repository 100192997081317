.Modal {
  width: 576px;
  padding: 32px;

  .Title {
    font-weight: 600;
    font-size: 24px;
    text-transform: uppercase;

    margin-top: 0;
    margin-bottom: 45px;
    font-family: "Montserrat", sans-serif;
    color: var(--color-text-primary);
  }

  .Links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 44px;
  }

  .AltTitle {
    margin-bottom: 16px;
    margin-top: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: var(--color-secondary-darker);
  }
}

.Display {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  border: 1px solid var(--color-m3);
  background: var(--color-m1);
  height: 5.8rem;
  padding: 8px;
  margin-bottom: 8px;

  .TimeWrap {
    display: flex;
    align-items: center;
  }

  .Text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    color: var(--color-m8);
    margin: 0;
  }

  .Date {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: var(--color-m8);
    background: var(--color-m2);
    border-radius: 8px;
    width: 100%;
    max-width: 12rem;
  }

  .Input {
    background: transparent;
    border: none;
    width: inherit;

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
  }
}

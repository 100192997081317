.Icon {
  cursor: pointer;
  opacity: 0.5;

  &.Active {
    opacity: 1;
  }

  @media (max-width: 380px) {
    width: 20px;
    height: 20px;
  }
}

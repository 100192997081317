.Wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  width: 100%;

  & > * + * {
    margin-top: 12px;
  }
}

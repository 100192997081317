@import '../../../../../../styles/mixins';


.Background {
  background: rgba(0, 0, 0, 0.90);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    background: black;
  }

  .Wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    max-width: 55rem;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 5rem 20px;

    &.Empty {
      justify-content: normal;
      height: auto;
    }


    .Search {
      width: 100%;
      display: flex;
      margin-bottom: 16px;

      & > input {
        width: 100%;
        border: 1px solid var(--color-text-primary);
      }
    }

    .Info {
      display: flex;
      width: 100%;
      margin-bottom: 16px;

      .InfoPar {
        margin: 0;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        color: var(--color-text-primary);
        opacity: 0.5;

        &.MoreThanDanger {
          color: var(--color-error);
          font-size: 15px;
          opacity: 1;
        }
      }
    }

    .LoadingScroll{
      display: flex;
      width: 100%;
      justify-content: center;
      overflow-y: hidden;
    }

    .SearchField {
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      overflow-y: scroll;
      margin-bottom: 24px;

      & > * {
        width: 98%;
      }

      @include scroll-bar;

      &::-webkit-scrollbar {
        width: 5px;
      }

      .LoadingState {
        margin-top: 10px;
      }

    }

    .Buttons {
      display: flex;
      width: 100%;
      justify-content: space-evenly;

      .Btn {
        width: 100%;
        align-items: center;
        justify-content: center;
        max-width: 182px;

        .ButtonSpan {
          white-space: nowrap;
        }
      }

      .SelectBtn {
        width: 100%;
        align-items: center;
        justify-content: center;
        max-width: 22rem;

        .ButtonSpan {
          white-space: nowrap;
        }
      }

      @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;

        .Btn {
          max-width: 90%;
          margin-bottom: 16px;
        }

        .SelectBtn {
          max-width: 90%;
        }
      }
    }
  }
}

.Copier {
  height: 54px;
  background: #1e1e24;
  border: 1px solid #2c2c35;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 28px;
  overflow: hidden;

  .Link {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 90%;
    font-size: 14px;
    line-height: 18px;
    color: var(--color-secondary-darker);
  }

  .Icon {
    stroke: #0168fe;
  }
}

.Card {
  background: #eaeaea;
  border-radius: 24px;
  padding: 16px 14px;

  .Header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 34px;

    .Name {
      margin-top: 14px;
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: var(--color-secondary-darkest);
    }

    .Username {
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: var(--color-secondary-darkest);
      opacity: 0.78;
      margin: 0;
    }
  }

  .Stats {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    .VerticalDivider {
      height: 64px;
      width: 1px;
      background: var(--color-secondary-darker);
    }
  }

  .VideoLink {
    margin-left: 60px;
  }
}

.Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 4px;
  cursor: pointer;

  .Item {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid var(--color-m3);
    background: var(--color-m1);
    width: 4rem;
    height: 4rem;



    .Par {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      color: var(--color-text-primary);
      margin: 0;
    }
  }

  .SelectedItem {
    background: var(--color-blue-primary);
  }
}

.TextLink {
  margin: 12px 0;
}

.FormFields {
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  & > * + * {
    margin-top: 12px;
  }
}

@import "../../../../../../styles/_mixins.scss";

.Wrapper {
  .Cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 26px;

    .GhostCard {
      display: none;
    }

    @media (max-width: 1120px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 876px) {
    width: calc(95vw - 20px);
    overflow-x: auto;
    @include scroll-bar;

    .Cards {
      display: flex;
      gap: unset;
      padding-bottom: 16px;

      .GhostCard {
        opacity: 0;
        display: none;
      }

      & > * + * {
        margin-left: 16px;
      }
    }
  }
}

.CustomWrapper {
  display: flex;
  flex-direction: column;
  column-gap: 16px;
  max-width: 350px;
  background: linear-gradient(
    204.74deg,
    rgba(39, 48, 58, 0.9) 15.77%,
    rgba(45, 44, 50, 0.9) 99.27%
  );
  padding: 8px;
  padding-top: 0;
  border-radius: 8px;

  .react-colorful {
    width: 100%;
    border-radius: 8px;
    background: linear-gradient(
      204.74deg,
      rgba(39, 48, 58, 0.9) 15.77%,
      rgba(45, 44, 50, 0.9) 99.27%
    );

    &__saturation-pointer {
      opacity: 0;
      transition: opacity 0.3s;
      max-width: 28px;
      max-height: 28px;
    }

    &__interactive:hover > .react-colorful__saturation-pointer {
      opacity: 1;
    }

    &__saturation {
      border-radius: 4px;
      border: none;
      margin: 8px 0;
    }

    &__alpha {
      display: none;
    }

    &__hue,
    &__alpha {
      margin: 8px 0;
      height: 8px;
      border-radius: 100px;
    }

    &__hue-pointer,
    &__alpha-pointer {
      width: 8px;
      max-width: 8px;
      height: 8px;
      max-height: 8px;
    }
  }
}

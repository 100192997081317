.Page {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 36px;
  color: var(--color-secondary-lightest);

  .Title {
    margin: 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    font-family: var(--font-h4);
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  .Subtitle {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-m8);
    opacity: 0.8;
    margin-bottom: 16px;
  }
}

.Editor {
  padding: 44px 56px;
  display: flex;

  & > * + * {
    margin-left: 36px;
  }

  .Settings {
    flex: 1;
    grid-area: settings;
  }

  .PhoneWrapper {
    flex: 1;

    .Phone {
      margin: 0 auto;
    }
  }

  @media (max-width: 1100px) {
    padding: 24px;
  }

  @media (max-width: 1048px) {
    display: grid;
    grid-template-areas: "phone settings" "elements elements";
    gap: 36px;

    & > * + * {
      margin-left: 0;
    }
  }

  @media (max-width: 670px) {
    padding: 0 0 460px 0;
    display: block;

    .Settings,
    .Elements {
      display: none;
    }

    .Phone {
      max-width: unset !important;
      height: unset !important;
      max-height: unset !important;
      margin: unset;
      border-style: none !important;
      background: transparent;
      border-radius: 0 !important;
      padding: 0 !important;
      box-shadow: none !important;

      .Preview {
        border-radius: 0;
        min-height: 100vh;
      }
    }
  }
}

.Modal {
  width: 400px;
  padding: 46px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .Title,
  .Text {
    align-self: flex-start;
  }

  .Title {
    margin: 0;
    font-weight: 700;
    font-size: 24px;
    color: var(--color-secondary-lightest);
  }

  .Text {
    font-size: 14px;
    color: var(--color-secondary-lightest);
    margin: 14px 0;
  }

  .Form {
    width: 100%;

    .Footer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;

      .Button {
        width: 255px;
        height: 48px;
      }
    }
  }
}

.Nav {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 50px;
  background: #141415;
  display: flex;
  align-items: center;
  justify-content: center;

  & > * + * {
    margin-left: 20px;
  }

  .Link {
    font-size: 14px;
    line-height: 19px;
    color: var(--color-secondary-lightest);
    opacity: 0.3;
    position: relative;

    &.Active {
      opacity: 1;
      text-decoration: none;

      &::before {
        content: "";
        position: absolute;
        top: -16px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 4px;
        background: #ad13df;
        border-radius: 0px 0px 4px 4px;
      }
    }
  }
}

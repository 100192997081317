.Wrapper {
  background: rgba(0, 0, 0, 0.32);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  overflow-y: auto;
  padding: 40px 0;

  .Modal {
    padding: 40px;
    padding-bottom: 0;
    max-width: 710px;
    max-height: 660px;
    background: var(--color-m0);
    border: 3px solid var(--color-m2);
    border-radius: 24px;

    .Header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .Info {
        display: flex;
        align-items: center;

        .Title {
          margin: 0;
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
          text-transform: uppercase;
          color: var(--color-secondary-lightest);
          margin-right: 24px;
        }

        .Label {
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: var(--color-m6);
          margin: 0;
        }
      }

      .CloseIcon {
        fill: var(--color-secondary-lightest);
      }
    }

    .DeleteBtn {
      width: 106px;
      height: 40px;
      background: var(--color-m1);
      text-align: center;
    }

    .Grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
      overflow-y: auto;
      max-height: 484px;
      padding-bottom: 20px;
    }
  }

  @media (max-width: 626px) {
    .Modal {
      max-width: 280px;
      width: 280px;
      max-height: unset;
      padding: 40px 42px 36px;
      position: relative;

      .Header {
        margin-bottom: 24px;
        justify-content: center;

        .Info {
          flex-direction: column;
          align-items: center;

          .Title {
            font-size: 16px;
            line-height: 30px;
            margin-bottom: 8px;
            text-align: center;
            margin-right: 0;
          }

          .Label {
            font-size: 16px;
            line-height: 22px;
            text-align: center;
          }
        }

        .CloseIcon {
          position: absolute;
          top: 16px;
          right: 16px;
          width: 20px;
          height: 20px;
        }
      }

      .Grid {
        display: flex;
        flex-direction: column;
        gap: unset;
        max-height: unset;
        padding-bottom: 0;

        & > * + * {
          margin-top: 12px;
        }

        .GroupCardWrapper {
          height: unset;

          .GroupCard {
            height: unset;
            padding: 58px 12px 24px;

            img {
              width: 50px;
              height: 50px;
              margin-right: 14px;
            }

            button {
              height: 32px;
            }
          }
        }
      }
    }
  }
}

.Page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 60px 0;

  .Content {
    width: 904px;

    .Title {
      margin-bottom: 32px;
    }
  }
}

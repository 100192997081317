.Root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .GroupNum {
    margin: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    color: var(--color-text-primary);
  }

  .UsersNum {
    margin: 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: var(--light-secondary-darker);
  }

  @media (max-width: 1024px) {
    background: var(--color-m2);
    padding: 16px;
    justify-content: center;
    height: 75px;
    border-radius: 16px;
    width: 100%;

    .GroupNum {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    .UsersNum {
      display: flex;
      justify-content: space-between;
    }
  }
}

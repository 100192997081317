.Layout {
  padding-top: 64px;

  .Header {
    position: fixed;
    top: 0;
    height: 64px;
    width: 100%;
    padding-left: 56px;
    display: flex;
    z-index: 5;
    align-items: center;
    background: rgba(20, 20, 20, 0.8);
    backdrop-filter: blur(20px);
  }

  @media (max-width: 876px) {
    padding-top: 0;

    .Header {
      display: none;
    }
  }
}

.Wrapper {
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  background: linear-gradient(
    240deg,
    rgba(249, 45, 253, 0.14),
    rgba(223, 58, 252, 0.8495),
    rgba(115, 112, 249, 0.21),
    rgba(13, 163, 246, 0.84),
    rgba(13, 163, 246, 0.19)
  );

  .Timer {
    border-radius: 16px;
    background: var(--dark-background);

    .Content {
      padding: 32px 16px;
      width: 337px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      background: var(--dark-background);
      border-radius: 16px;

      .Title {
        font-size: 16px;
        font-weight: 600;
        color: var(--color-text-primary);
        margin: 0;
        margin-bottom: 12px;
      }

      .Email {
        font-size: 14px;
        width: 305px;
        font-weight: 400;
        color: var(--color-gray);
        margin: 0;
        margin-bottom: 22px;
      }

      .Repeat {
        margin-bottom: 0 !important;
      }

      .Loader {
        width: 24px;
        height: 24px;
        margin-bottom: 22px;
        display: grid;
        mask: conic-gradient(from 22deg, #0003, #000);
        animation: load 1s steps(8) infinite;

        &,
        &:before {
          --_g: linear-gradient(#f5f5ff 0 0) 50%;
          background: var(--_g) / 34% 8% space no-repeat, var(--_g) / 8% 34% no-repeat space;
        }
        &:before {
          content: "";
          transform: rotate(45deg);
        }
      }

      @keyframes load {
        from {
          transform: rotate(0turn);
        }
        to {
          transform: rotate(1turn);
        }
      }

      .Btns {
        display: flex;
        align-items: center;
        justify-content: center;

        .Exit {
          color: rgba(255, 255, 255, 0.8);
        }

        .OneMore {
          width: 154px;
          height: 40px;
          margin-left: 16px;
          border: none;
        }
      }

      .Repeat {
        font-size: 12px;
        font-weight: 400;
        color: var(--color-gray);
        margin: 0;
        margin-bottom: 22px;
      }
    }
  }
}

.Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 2px solid rgba(#fff, 0.2);
  transition: opacity 0.3s ease-in-out;
  background-color: transparent;
  color: rgba(#fff, 0.7);
  border-radius: 12px;
  padding: 10px;
  gap: 8px;

  &:hover {
    opacity: 1;
  }
}

.Label {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 130px;
  overflow: hidden;
  font-weight: 600;
  font-size: 12px;
  padding: 0 2px;
}

.Icon {
  fill: var(--color-secondary-lightest);
}

.Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  color: #fff;

  h2 {
    font-size: 24px;
    margin: 0;
  }
}

.Close {
  fill: rgba(#fff, 0.2);

  &:hover {
    fill: rgba(#fff, 0.7);
  }
}

.List {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ListItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  border: 2px solid rgba(#fff, 0.2);
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  color: rgba(#fff, 0.7);
  border-radius: 12px;
  padding: 10px 14px;
  gap: 12px;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    border-color: #fff;
    color: #fff;
  }
}

.ListItemLabel {
  max-width: calc(100% - 36px);
  font-weight: 600;
  font-size: 12px;
  padding: 3px 0;
}

.Socials {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  &[data-orientation="vertical"] {
    flex-direction: column;

    div {
      width: 100%;
    }
  }
}

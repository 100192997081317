.Block {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-element-icon-bg);
  border: 1px solid var(--color-element-icon);
  border-radius: 8px;
  position: relative;

  &[data-inactive="true"] {
    border-color: var(--color-element-icon-bg);
  }
}

.Wrapper {
  display: none;

  .Label {
    font-size: 12px !important;
    font-weight: 400;
  }

  .Elements {
    margin-top: 16px;

    .Title {
      font-size: 16px !important;
    }

    .Grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }
  }

  @media (max-width: 670px) {
    display: block;
  }
}

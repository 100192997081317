.Header {
  height: 56px;
  background: var(--color-header-bg-mobile);
  backdrop-filter: blur(20px);
  padding: 22px 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 15px;

  .Center {
    width: 80%;
  }
}

.Breadcrumbs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;

  .Left {
    display: flex;

    .Navigation {
      display: flex;
      align-items: center;
      margin-right: 32px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: rgba(217, 217, 217, 0.1);

        .Left {
          transform: rotate(180deg);
        }

        .Clickable {
          fill: var(--color-text-primary);
        }

        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
  }

  .Link {
    font-size: 24px;
    font-weight: 700;
    color: var(--color-secondary-darker);
    text-decoration: none;
    transition: color var(--animation-transition);

    .Title {
      width: 392px;
      font-size: 24px;
      font-weight: 700;
      margin: 0;
    }

    &:hover,
    &:focus,
    &.LinkActive {
      color: var(--color-secondary-darkest);
      transition: color var(--animation-transition);
    }
  }

  @media (max-width: 1080px) {
    margin-bottom: 0;

    .TitleLink {
      display: none;
    }
  }

  @media (max-width: 876px) {
    .Left {
      .Navigation {
        a {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}

.Card {
  width: 354px;

  .Title {
    margin-bottom: 48px;
  }

  .Text {
    font-size: 14px;
    color: #d0d0da;
    font-weight: 400;
    margin: 16px 0 48px 10px;
    width: 274px;
  }

  .Form {
    .FormFields {
      display: flex;
      flex-direction: column;

      & > * + * {
        margin-top: 12px;
      }
    }
  }

  .Alternative {
    margin-top: 32px;
  }

  .Logo {
    margin-bottom: 108px;
  }
}

@media (max-width: 800px) {
  .Card {
    .Text {
      margin-bottom: 20px;
      text-align: center;
    }

    .Logo {
      margin-bottom: 40px;
    }

    .Form {
      width: 100%;
    }

    .Title {
      margin-bottom: 37px;
    }
  }
}

@media (max-width: 425px) {
  .Card {
    width: 320px;
  }
}

.RemoveIcon {
  fill: var(--color-error) !important;
  width: 16px !important;
}

.Fields {
  & > * + * {
    margin-top: 12px;
  }
}

.Block {
  height: 112px;
  border-radius: 16px;
  background: var(--color-m0);
  border: 1px solid rgba(255, 255, 255, 0.08);
  padding: 26px 16px;

  .Title {
    font-weight: 700;
    font-size: 16px;
    font-family: var(--font-primary);
    margin: 0;
    color: var(--color-text-primary);
  }

  .Wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .Link {
      width: 250px;
      font-size: 14px;
      font-weight: 400;
      color: var(--color-secondary-darker);
      margin: 0 16 15px 29px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .Icon {
      margin: 23px 15px 22px;
      fill: var(--partnership-bg);
      stroke: var(--color-blue);
    }
  }
}

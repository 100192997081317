.Section {
  margin-top: 32px;

  .Subtitle {
    margin-bottom: 32px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-secondary-darkest);
  }

  .Grid {
    display: flex;
    justify-content: space-between;
  }
}

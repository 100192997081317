.Loader {
  border: 5px solid var(--color-m3);
  border-radius: 50%;
  border-top: 5px solid var(--color-m6);
  width: 50px;
  height: 50px;
  -webkit-animation: spin .5s linear infinite; /* Safari */
  animation: spin .5s linear infinite;

  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}


.Root {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .Btns{
    margin-top: 15px;
    display: flex;
    column-gap: 24px;
    justify-content: center;

    .Btn {
      flex: 1;
      white-space: nowrap;
      justify-content: center;
    }

    .BtnCLose {
      flex: 1;
      background-color: inherit;
      color: var(--color-m7);
      text-align: center;
    }
  }
}

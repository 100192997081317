.Nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  .Left {
    display: flex;
    align-items: center;

    & > * + * {
      margin-left: 24px;
    }
  }
}

.IconDown {
  stroke: var(--color-secondary-lightest);
}

.Context {
  top: -12px !important;
  right: -24px !important;
}

.deleteBtn{
  cursor: pointer;
  color: rgba(210, 40, 40, 1);
  font-size: 12px;
  text-align: right;
}

@mixin label {
  font-size: 14px;
  color: var(--color-d9-input);
  line-height: 22px;
}

.Wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  .Label {
    @include label;
  }

  .Right {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    @include label;

    & > * + * {
      margin-left: 16px;
    }
  }

  @media (max-width: 670px) {
    font-weight: 400;
  }
}

.AddedButtons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .Button {
    width: 256px;
  }

  & > * + * {
    margin-top: 16px;
  }
}

.Page {
  display: flex;
  justify-content: space-between;
  column-gap: 30px;

  .Content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 56px;
  }

  .Body {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    .Header {
      display: flex;
      margin-bottom: 16px;
    }

    .Inner {
      display: flex;
      flex-direction: column;
      column-gap: 32px;
    }

    .Title {
      margin: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      background: linear-gradient(90.43deg, rgba(255, 255, 255, 0.6) 0%, #FFFFFF 18.07%, rgba(255, 255, 255, 0.8) 83.79%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      opacity: 0.8;
    }
  }

  .Link {
    display: flex;
    align-items: center;
    column-gap: 12px;

    .Text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 90%;
    }
  }
}

.Section {
  width: 588px;
  display: flex;
  flex-direction: column;
  position: relative;

  .ContentWrapper {
    padding: 24px;
    display: flex;
    justify-content: center;
    background: #eaeaea;
    flex: 1;
    position: relative;

    .Content {
      min-width: 320px;
      max-width: 320px;
    }
  }
}

.Entry {
  display: flex;
  align-items: center;
  column-gap: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--color-m9);

  .Value {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    background: linear-gradient(90.43deg, rgba(255, 255, 255, 0.6) 0%, #FFFFFF 18.07%, rgba(255, 255, 255, 0.8) 83.79%);
    background-clip: text;
    opacity: 0.3;
  }
}

.Content {
  padding: 32px 36px;

  @media (max-width: 600px) {
    padding: 24px 20px;

    .Title {
      margin-bottom: 16px;
    }
  }
}

.CardHolder {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 70px;
  gap: 32px;

  @media (max-width: 1162px) {
    margin: 0 auto;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    gap: unset;
    max-width: 360px;

    & > * + * {
      margin-top: 24px;
    }
  }

  @media (max-width: 876px) {
    margin-top: 0;
  }
}

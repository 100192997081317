.Card {
  padding: 32px 24px;
  padding-top: 24px;
}

@media (max-width: 600px) {
  .Card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 56px 20px 60px;
  }
}

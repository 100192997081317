.Wrapper {
  border-bottom: 1px solid var(--color-divider);

  &[data-no-border="true"] {
    border-style: none;

    .InputWrapper {
      padding-bottom: 0;
    }
  }

  .description{
    font-size: 14px;
    color: var(--color-secondary-grey2);
    margin-top: 8px;
  }

  .InputWrapper {
    display: flex;
    align-items: center;
    padding-bottom: 8px;

    & > * + * {
      margin-left: 16px;
    }

    .Input {
      font-size: 16px;
      line-height: 22px;
      color: var(--color-secondary-lightest);
      width: 100%;
      background: transparent;
      border-style: none;
      outline: none;
      resize: none;
    }

    .Prefix,
    .Postfix {
      font-size: 16px;
      line-height: 22px;
      color: var(--color-d5);
    }

    .Icon {
      stroke: var(--color-d7);
      fill: var(--color-d7);

      &.IconWithClickHandler {
        cursor: pointer;
      }
    }
  }

  @media (max-width: 670px) {
    .InputWrapper {
      .Input {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
.errorMsg{
  font-size: 12px;
  color: var(--color-error);
  margin-top: 8px;
}

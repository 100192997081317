.MainContainer {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: relative;

  .DateDisplay {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
    border: 1px solid var(--color-m3);
    background: var(--color-m1);
    height: 5.8rem;
    padding: 8px;
    margin-bottom: 8px;
    cursor: pointer;

    .TimeWrap {
      display: flex;
      align-items: center;
    }

    .Text {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      color: var(--color-m8);
      margin: 0;
    }

    .Date {
      padding: 10px;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      color: var(--color-m8);
      background: var(--color-m2);
      border-radius: 8px;

    }
  }

  .Dropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    background: linear-gradient(204.74deg, rgba(39, 48, 58, 0.9) 15.77%, rgba(45, 44, 50, 0.9) 99.27%);
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.16);
    backdrop-filter: blur(8px);
    z-index: 2;
    color: var(--color-secondary-lightest);
    width: 160px;
    padding: 12px 16px;
    top: 62px;
    right: 0;
    border-radius: 8px;


    .DropPar {
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      color: var(--color-m9);
      margin: 0;
      margin-bottom: 10px;
    }

    .Form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 8rem;

      .TimeInput {
        max-width: 40px;
        margin: 0 8px;
        text-align: center;
        color: var(--color-secondary-lightest);
        border: 2px solid var(--color-m4);
        background: transparent;
        border-radius: 4.51429px;
        padding: 4px;

        &:focus,
        &:active {
          border: 2px solid var(--color-persian);
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &[type="number"] {
          -moz-appearance: textfield;
        }
      }
    }


  }

}



.NoMessages {
  width: 674px;
  height: 277px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: var(--color-secondary-lighter);

  .Text {
    max-width: 275px;
    text-align: center;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-top: 24px;
    color: var(--color-secondary-darkest);
  }
}

.Wrapper {
  .Btn {
    color: var(--color-secondary-lightest);
    opacity: 0.3;
    cursor: pointer;
    background: transparent;
    padding: 0;
    border-style: none;
    font-size: 12px;

    .ToAdd {
      text-transform: lowercase;
    }
  }

  @media (max-width: 380px) {
    display: flex;
    justify-content: center;

    .Btn {
      line-height: 30px;
    }
  }
}
